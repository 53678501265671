import  React, {useState, useEffect} from 'react';
import TopbarComponent from '../components/Topbar';
import GuidelineComponent from '../components/Guideline4';
import Nextbutton from '../components/Nextbutton';
import DropdownComponent from '../components/Dropdown';
import kakaoaa from '../image/kakaoaa.png'
import AlertHeaderPc1 from '../components/AlertHeaderPc1';
import '../text.css';
import nextbtn_new from '../image/nextbtn_new.png'
import icon1 from '../image/b1.jpg'
import icon2 from '../image/b2.jpg'
import icon3 from '../image/b3.jpg'
import icon4 from '../image/b4.jpg'
import icon5 from '../image/b5.jpg'
import icon6 from '../image/b6.jpg'
import icon7 from '../image/b7.jpg'
import icon8 from '../image/b8.jpg'
import icon9 from '../image/b9.jpg'
import icon10 from '../image/b10.jpg'
import icon11 from '../image/b11.jpg'
import icon12 from '../image/b12.jpg'
import icon13 from '../image/b13.jpg'
import icon14 from '../image/b14.jpg'
import icon15 from '../image/b15.jpg'
import icon16 from '../image/b16.jpg'
import icon17 from '../image/b17.jpg'
import icon18 from '../image/b18.jpg'
import blu1 from '../image/c1.jpg'
import blu2 from '../image/c2.jpg'
import blu3 from '../image/c3.jpg'
import blu4 from '../image/c4.jpg'
import blu5 from '../image/c5.jpg'
import blu6 from '../image/c6.jpg'
import blu7 from '../image/c7.jpg'
import blu8 from '../image/c8.jpg'
import blu9 from '../image/c9.jpg'
import blu10 from '../image/c10.jpg'
import blu11 from '../image/c11.jpg'
import blu12 from '../image/c12.jpg'
import blu13 from '../image/c13.jpg'
import blu14 from '../image/c14.jpg'
import blu15 from '../image/c15.jpg'
import blu16 from '../image/c16.jpg'
import blu17 from '../image/c17.jpg'
import blu18 from '../image/c18.jpg'
import ask3 from '../image/select.png'
import $ from "jquery";
import new_blue from '../image/new_blue.png'
import uploadBtn from '../image/uploadBtn.png'
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams} from 'react-router-dom';
import{
BrowserView,
MobileView,
isBrowser,
IsMobile,
TabletView
} from "react-device-detect";
import BrowserHeader from '../components/BrowserHeader';
var classList = [];
var classList2 = [];
var classList3 = [];
function Select(){
const [count, setCount] = useState(0);
const [eventcss, setEventcss] = useState('disabled-link');
const [btntext, setBtntext] = useState('다음');
const [nextbtn, setNextbtn] = useState(nextbtn_new);
const [btntext2, setBtntext2] = useState(0);
const [img1, setText1] = useState([icon1,'nonselect']);
const [img2, setText2] = useState([icon2,'nonselect']);
const [img3, setText3] = useState([icon3,'nonselect']);
const [img4, setText4] = useState([icon4,'nonselect']);
const [img5, setText5] = useState([icon5,'nonselect']);
const [img6, setText6] = useState([icon6,'nonselect']);
const [img7, setText7] = useState([icon7,'nonselect']);
const [img8, setText8] = useState([icon8,'nonselect']);
const [img9, setText9] = useState([icon9,'nonselect']);
const [img10, setText10] = useState([icon10,'nonselect']);
const [img11, setText11] = useState([icon11,'nonselect']);
const [img12, setText12] = useState([icon12,'nonselect']);
const [img13, setText13] = useState([icon13,'nonselect']);
const [img14, setText14] = useState([icon14,'nonselect']);
const [img15, setText15] = useState([icon15,'nonselect']);
const [img16, setText16] = useState([icon16,'nonselect']);
const [img17, setText17] = useState([icon17,'nonselect']);
const [img18, setText18] = useState([icon18,'nonselect']);
const select2 = (k) => {
    if (classList3.length ==0){

    }else{
        select3();
    }
    
}
const select3 = (k) => {

        let num = classList3[0];
        f_obj['f_run_'+num]();
        classList3.splice(0);
        $('#next_button').css('background-color', '#4a64f5');
                           $('#dam').css('color', '#ffffff');
                           setEventcss('')
                           let textas = count + '개 선택 완료'
                           setBtntext(textas)
                           setBtntext2(count)
                           setNextbtn(new_blue)
    
}
 useEffect(()=> {
 onKeyUP()
     })
     var f_obj = {}
     f_obj.f_run_1 = function(){ onClick1()};
     f_obj.f_run_2 = function(){ onClick2() };
     f_obj.f_run_3 = function(){  onClick3()};
     f_obj.f_run_4 = function(){  onClick4()};
     f_obj.f_run_5 = function(){  onClick5()};
     f_obj.f_run_6 = function(){  onClick6()};
     f_obj.f_run_7 = function(){  onClick7()};
     f_obj.f_run_8 = function(){  onClick8()};
     f_obj.f_run_9 = function(){  onClick9()};
     f_obj.f_run_10 = function(){ onClick10() };
     f_obj.f_run_11 = function(){  onClick11()};
     f_obj.f_run_12 = function(){  onClick12()};
     f_obj.f_run_13 = function(){  onClick13()};
     f_obj.f_run_14 = function(){ onClick14() };
     f_obj.f_run_15 = function(){  onClick15()};
     f_obj.f_run_16 = function(){ onClick16() };
     f_obj.f_run_17 = function(){  onClick17()};
     f_obj.f_run_18 = function(){ onClick18() };
const onClick1 = (k) => {
if (img1[0]==icon1){
    
setText1([blu1,'select'])
setCount(count+1)
classList.push(35);
classList2.push("김현호 1");
let skarb = "skarb" + '1'
select2();
classList3.push(1);
}else{
    classList3.splice(0);
setText1([icon1,'nonselect'])
setCount(count-1)
for(var i = 0; i < classList.length; i++){
  if (classList[i] === 35) {
    classList.splice(i, 1);
    i--;
  }
}
for(var i = 0; i < classList2.length; i++){
  if (classList2[i] === "김현호 1") {
    classList2.splice(i, 1);
    i--;
  }
}
}
      };
const onClick2 = (i) => {
if (img2[0]==icon2){

setText2([blu2,'select'])
setCount(count+1)
classList.push(43);
classList2.push("정의석 1");
select2();
classList3.push(2);
}else{
    classList3.splice(0);
setText2([icon2,'nonselect'])
setCount(count-1)
for(var i = 0; i < classList.length; i++){
  if (classList[i] === 43) {
    classList.splice(i, 1);
    i--;
  }
}
for(var i = 0; i < classList2.length; i++){
  if (classList2[i] === "정의석 1") {
    classList2.splice(i, 1);
    i--;
  }
}
}
      };
const onClick3 = (i) => {
if (img3[0]==icon3){
setText3([blu3,'select'])
setCount(count+1)
classList.push(44);
classList2.push("석혜선 2");
select2();
classList3.push(3);
}else{
    classList3.splice(0);
setText3([icon3,'nonselect'])
setCount(count-1)
for(var i = 0; i < classList.length; i++){
  if (classList[i] === 44) {
    classList.splice(i, 1);
    i--;
  }
}
for(var i = 0; i < classList2.length; i++){
  if (classList2[i] === "석혜선 2") {
    classList2.splice(i, 1);
    i--;
  }
}
}
      };
const onClick4 = (i) => {
if (img4[0]==icon4){
setText4([blu4,'select'])
setCount(count+1)
classList.push(444);
classList2.push("정승철 2");
select2();
classList3.push(4);
}else{
    classList3.splice(0);
setText4([icon4,'nonselect'])
setCount(count-1)
for(var i = 0; i < classList.length; i++){
  if (classList[i] === 444) {
    classList.splice(i, 1);
    i--;
  }
}
for(var i = 0; i < classList2.length; i++){
  if (classList2[i] === "정승철 2") {
    classList2.splice(i, 1);
    i--;
  }
}
}
      };
const onClick5 = (i) => {
if (img5[0]==icon5){
setText5([blu5,'select'])
setCount(count+1)
classList.push(30);
classList2.push("최유민 2");
select2();
classList3.push(5);
}else{
    classList3.splice(0);
setText5([icon5,'nonselect'])
setCount(count-1)
for(var i = 0; i < classList.length; i++){
  if (classList[i] === 30) {
    classList.splice(i, 1);
    i--;
  }
}
for(var i = 0; i < classList2.length; i++){
  if (classList2[i] === "최유민 2") {
    classList2.splice(i, 1);
    i--;
  }
}
}
      };
const onClick6 = (i) => {
if (img6[0]==icon6){
setText6([blu6,'select'])
setCount(count+1)
classList.push(31);
classList2.push("권혁중 2");
select2();
classList3.push(6);
}else{
    classList3.splice(0);
setText6([icon6,'nonselect'])
setCount(count-1)
for(var i = 0; i < classList.length; i++){
  if (classList[i] === 31) {
    classList.splice(i, 1);
    i--;
  }
}
for(var i = 0; i < classList2.length; i++){
  if (classList2[i] === "권혁중 2") {
    classList2.splice(i, 1);
    i--;
  }
}
}
      };
const onClick7 = (i) => {
if (img7[0]==icon7){
setText7([blu7,'select'])
setCount(count+1)
classList.push(3);
classList2.push("송슬기 3");
select2();
classList3.push(7);
}else{
    classList3.splice(0);
setText7([icon7,'nonselect'])
setCount(count-1)
for(var i = 0; i < classList.length; i++){
  if (classList[i] === 3) {
    classList.splice(i, 1);
    i--;
  }
}
for(var i = 0; i < classList2.length; i++){
  if (classList2[i] === "송슬기 3") {
    classList2.splice(i, 1);
    i--;
  }
}
}
      };
const onClick8 = (i) => {
if (img8[0]==icon8){
setText8([blu8,'select'])
setCount(count+1)
classList.push(5);
classList2.push("임형철 3");
select2();
classList3.push(8);
}else{
    classList3.splice(0);
setText8([icon8,'nonselect'])
setCount(count-1)
for(var i = 0; i < classList.length; i++){
  if (classList[i] === 5) {
    classList.splice(i, 1);
    i--;
  }
}
for(var i = 0; i < classList2.length; i++){
  if (classList2[i] === "임형철 3") {
    classList2.splice(i, 1);
    i--;
  }
}
}
      };
const onClick9 = (i) => {
if (img9[0]==icon9){
setText9([blu9,'select'])
setCount(count+1)
classList.push(29);
classList2.push("한상욱 3");
select2();
classList3.push(9);
}else{
    classList3.splice(0);
setText9([icon9,'nonselect'])
setCount(count-1)
for(var i = 0; i < classList.length; i++){
  if (classList[i] === 29) {
    classList.splice(i, 1);
    i--;
  }
}
for(var i = 0; i < classList2.length; i++){
  if (classList2[i] === "한상욱 3") {
    classList2.splice(i, 1);
    i--;
  }
}
}
      };
const onClick10 = (i) => {
if (img10[0]==icon10){
setText10([blu10,'select'])
setCount(count+1)
classList.push(25);
classList2.push("김서현 4");
select2();
classList3.push(10);
}else{
    classList3.splice(0);
setText10([icon10,'nonselect'])
setCount(count-1)
for(var i = 0; i < classList.length; i++){
  if (classList[i] === 25) {
    classList.splice(i, 1);
    i--;
  }
}
for(var i = 0; i < classList2.length; i++){
  if (classList2[i] === "김서현 4") {
    classList2.splice(i, 1);
    i--;
  }
}
}
      };
const onClick11 = (i) => {
  if (img11[0]==icon11){
  setText11([blu11,'select'])
  setCount(count+1)
  classList.push(18);
  classList2.push("최승진 4");
  select2();
classList3.push(11);
  }else{
    classList3.splice(0);
  setText11([icon11,'nonselect'])
  setCount(count-1)
  for(var i = 0; i < classList.length; i++){
    if (classList[i] === 18) {
      classList.splice(i, 1);
      i--;
    }
  }
  for(var i = 0; i < classList2.length; i++){
    if (classList2[i] === "최승진 4") {
      classList2.splice(i, 1);
      i--;
    }
  }
  }
        };
const onClick12 = (i) => {
  if (img12[0]==icon12){
  setText12([blu12,'select'])
  setCount(count+1)
  classList.push(14);
  classList2.push("김병주 4");
  select2();
classList3.push(12);
  }else{
    classList3.splice(0);
  setText12([icon12,'nonselect'])
  setCount(count-1)
  for(var i = 0; i < classList.length; i++){
    if (classList[i] === 14) {
      classList.splice(i, 1);
      i--;
    }
  }
  for(var i = 0; i < classList2.length; i++){
    if (classList2[i] === "김병주 4") {
      classList2.splice(i, 1);
      i--;
    }
  }
  }
        };
const onClick13 = (i) => {
  if (img13[0]==icon13){
  setText13([blu13,'select'])
  setCount(count+1)
  classList.push(28);
  classList2.push("김범준 5");
  select2();
classList3.push(13);
  }else{
    classList3.splice(0);
  setText13([icon13,'nonselect'])
  setCount(count-1)
  for(var i = 0; i < classList.length; i++){
    if (classList[i] === 28) {
      classList.splice(i, 1);
      i--;
    }
  }
  for(var i = 0; i < classList2.length; i++){
    if (classList2[i] === "김범준 5") {
      classList2.splice(i, 1);
      i--;
    }
  }
  }
        };
const onClick14 = (i) => {
if (img14[0]==icon14){
setText14([blu14,'select'])
setCount(count+1)
classList.push(41);
classList2.push("송민정 5");
select2();
classList3.push(14);
}else{
    classList3.splice(0);
setText14([icon14,'nonselect'])
setCount(count-1)
for(var i = 0; i < classList.length; i++){
  if (classList[i] === 41) {
    classList.splice(i, 1);
    i--;
  }
}
for(var i = 0; i < classList2.length; i++){
  if (classList2[i] === "송민정 5") {
    classList2.splice(i, 1);
    i--;
  }
}
}
      };
const onClick15 = (i) => {
if (img15[0]==icon15){
setText15([blu15,'select'])
setCount(count+1)
classList.push(37);
classList2.push("김영애 5");
select2();
classList3.push(15);
}else{
    classList3.splice(0);
setText15([icon15,'nonselect'])
setCount(count-1)
for(var i = 0; i < classList.length; i++){
if (classList[i] === 37) {
  classList.splice(i, 1);
  i--;
}
}
for(var i = 0; i < classList2.length; i++){
if (classList2[i] === "김영애 5") {
  classList2.splice(i, 1);
  i--;
}
}
}
    };   
    
const onClick16 = (i) => {
  if (img16[0]==icon16){
  setText16([blu16,'select'])
  setCount(count+1)
  classList.push(38);
  classList2.push("김갑수 6");
  select2();
classList3.push(16);
  }else{
    classList3.splice(0);
  setText16([icon16,'nonselect'])
  setCount(count-1)
  for(var i = 0; i < classList.length; i++){
    if (classList[i] === 38) {
      classList.splice(i, 1);
      i--;
    }
  }
  for(var i = 0; i < classList2.length; i++){
    if (classList2[i] === "김갑수 6") {
      classList2.splice(i, 1);
      i--;
    }
  }
}
};

const onClick17 = (i) => {
  if (img17[0]==icon17){
  setText17([blu17,'select'])
  setCount(count+1)
  classList.push(9);
  classList2.push("임종승 6");
  select2();
classList3.push(17);
  }else{
    classList3.splice(0);
  setText17([icon17,'nonselect'])
  setCount(count-1)
  for(var i = 0; i < classList.length; i++){
    if (classList[i] === 9) {
      classList.splice(i, 1);
      i--;
    }
  }
  for(var i = 0; i < classList2.length; i++){
    if (classList2[i] === "임종승 6") {
      classList2.splice(i, 1);
      i--;
    }
  }
  }
        };
const onClick18 = (i) => {
if (img18[0]==icon18){
setText18([blu18,'select'])
setCount(count+1)
classList.push(111);
classList2.push("박길환 6");
select2();
classList3.push(18);
}else{
    classList3.splice(0);
setText18([icon18,'nonselect'])
setCount(count-1)
for(var i = 0; i < classList.length; i++){
  if (classList[i] === 111) {
    classList.splice(i, 1);
    i--;
  }
}
for(var i = 0; i < classList2.length; i++){
  if (classList2[i] === "박길환 6") {
    classList2.splice(i, 1);
    i--;
  }
}
}
      };
const search991 = () => {
let selectclasses = [];
for (let i = 0; i < classList.length; i++) {
selectclasses.push(classList[i]+'류이이');
}
sessionStorage.setItem('person', classList2);
sessionStorage.setItem('goodsSearchWord', ".");
sessionStorage.setItem('selectclasses', selectclasses);
classList = [];
classList2 = [];
      };

const onKeyUP = () => {
            if(count>0){
                          $('#next_button').css('background-color', '#4a64f5');
                           $('#dam').css('color', '#ffffff');
                           setEventcss('')
                           let textas = count + '개 선택 완료'
                           setBtntext(textas)
                           setBtntext2(count)
                           setNextbtn(new_blue)
            }
            else {
                    // $('#next_button').css('background-color', '#efefef');
                    //     $('#dam').css('color', '#b7b7b7');
                    //     setEventcss('disabled-link')
                    //     setBtntext('다음')
                    //     setBtntext2(count)
                    //     setNextbtn(nextbtn_new)
                    $('#next_button').css('background-color', '#4a64f5');
                           $('#dam').css('color', '#ffffff');
                           setEventcss('')
                           let textas = count + '개 선택 완료'
                           setBtntext(textas)
                           setBtntext2(count)
                           setNextbtn(new_blue)
            }
    }
        return (
        <div>
            <div style={{margin : "0 auto"}}>
                            <BrowserHeader/>
                            <AlertHeaderPc1/>
                            <div style={{width:'1400px',paddingLeft:"calc(50% - 700px)"}}>
                       <img src={ask3} style={{marginTop : '123px',marginLeft : 'calc(50% - 602px)',width : '601px'}}/>
                       <div style={{width:'945px',marginLeft : 'calc(50% - 467px)',marginTop:'36px',marginBottom:'200px'}}>
                       <div className='Regular' id="1" onClick={() =>  onClick1()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img1[0]} style={{display: "inline-block",
                                       width: "285px",height:'252px'}}/>
                                    
                       <div className={img1[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>
                       <div className='Regular' id="2" onClick={() => onClick2()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img2[0]} style={{display: "inline-block",
                                      width: "285px",height:'252px'}}/>
                                       <div className={img2[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>
                       <div className='Regular' id="3" onClick={() => onClick3()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img3[0]} style={{display: "inline-block",
                                       width: "285px",height:'252px'}}/>
                                       <div className={img3[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>




                       <div className='Regular' id="4" onClick={() =>  onClick4()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img4[0]} style={{display: "inline-block",
                                       width: "285px",height:'252px'}}/>
                                    
                       <div className={img4[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>
                       <div className='Regular' id="5" onClick={() => onClick5()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img5[0]} style={{display: "inline-block",
                                      width: "285px",height:'252px'}}/>
                                       <div className={img5[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>
                       <div className='Regular' id="6" onClick={() => onClick6()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img6[0]} style={{display: "inline-block",
                                       width: "285px",height:'252px'}}/>
                                       <div className={img6[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>


                       <div className='Regular' id="7" onClick={() =>  onClick7()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img7[0]} style={{display: "inline-block",
                                       width: "285px",height:'252px'}}/>
                                    
                       <div className={img7[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>
                       <div className='Regular' id="8" onClick={() => onClick8()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img8[0]} style={{display: "inline-block",
                                      width: "285px",height:'252px'}}/>
                                       <div className={img8[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>
                       <div className='Regular' id="9" onClick={() => onClick9()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img9[0]} style={{display: "inline-block",
                                       width: "285px",height:'252px'}}/>
                                       <div className={img9[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>


                       <div className='Regular' id="10" onClick={() =>  onClick10()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img10[0]} style={{display: "inline-block",
                                       width: "285px",height:'252px'}}/>
                                    
                       <div className={img10[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>
                       <div className='Regular' id="11" onClick={() => onClick11()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img11[0]} style={{display: "inline-block",
                                      width: "285px",height:'252px'}}/>
                                       <div className={img11[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>
                       <div className='Regular' id="12" onClick={() => onClick12()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img12[0]} style={{display: "inline-block",
                                       width: "285px",height:'252px'}}/>
                                       <div className={img12[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>



                       <div className='Regular' id="13" onClick={() =>  onClick13()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img13[0]} style={{display: "inline-block",
                                       width: "285px",height:'252px'}}/>
                                    
                       <div className={img13[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>
                       <div className='Regular' id="14" onClick={() => onClick14()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img14[0]} style={{display: "inline-block",
                                      width: "285px",height:'252px'}}/>
                                       <div className={img14[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>
                       <div className='Regular' id="15" onClick={() => onClick15()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img15[0]} style={{display: "inline-block",
                                       width: "285px",height:'252px'}}/>
                                       <div className={img15[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>



                       <div className='Regular' id="16" onClick={() =>  onClick16()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img16[0]} style={{display: "inline-block",
                                       width: "285px",height:'252px'}}/>
                                    
                       <div className={img16[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>
                       <div className='Regular' id="17" onClick={() => onClick17()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img17[0]} style={{display: "inline-block",
                                      width: "285px",height:'252px'}}/>
                                       <div className={img17[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>
                       <div className='Regular' id="18" onClick={() => onClick18()} style={{fontSize : '14px',marginTop :'20px' ,width:'300px',display:'inline-block',paddingBottom:'18px'}}>
                       <img src={img18[0]} style={{display: "inline-block",
                                       width: "285px",height:'252px'}}/>
                                       <div className={img18[1]} style={{width:'117.5px',marginRight:'6px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#fff',marginLeft:'4px'}}>선택하기</div>
                                       <div style={{width:'117.5px',textAlign:'center',display:'inline-block',padding:'8px 8px',color:'#707070',border :'solid 1px #707070'}}>상담 신청하기</div>
                       </div>


                       </div>
                       </div>
                       <div id="introFooter" style={{float:'right',backgroundColor: '#f5f6ff',height:'116px',lineHeight:'110px'}}>
                                         <Link to="/test6" className={eventcss} onClick={() => search991()}>
                                         <img src={nextbtn}  style={{cursor:'pointer',width : '200px',marginLeft:'20px',marginTop:'27px',float:'right',marginRight:'calc(50% - 488px)'}}/>
                                         </Link>
                                         <a href='http://pf.kakao.com/_lWuhs/chat'>
                                                  <img src={kakaoaa}  style={{cursor:'pointer',width : '283px ',marginTop:'27px',float:'right'}}/>
                                                  </a>
                                                  <div className="Bold1" style={{fontSize:"30px",marginLeft:'calc(50% - 485px)'}}>
                                                  

                                                  </div>
                                                  </div>

                       </div>
            </div>
            
    )
}

export default Select;