import React , {useState, useEffect}from 'react';
import TopbarComponentWithoutPage from '../components/Topbarwithoutpage';
import Nextbutton from '../components/Nextbutton';
import '../style.css';
import x_round from '../image/x_round.png'
import nextbtn_new from '../image/nextbtn_new.png'
import arrow from '../image/arrow@3x.png'
import icon_card from '../image/icon_card@2x.png'
import dot123 from '../image/dot123.png'
import icon_card_blue from '../image/icon_cardblue@2x.png'
import icon_won from '../image/icon_won@2x.png'
import icon_won_blue from '../image/icon_wonblue@2x.png'
import GuidelineComponent3 from '../components/Guideline3';
import AlertHeader from '../components/AlertHeader';
import AlertHeader2 from '../components/AlertHeader2';
import $ from 'jquery';
import askalsal from '../image/z1d4.png'
import new_blue from '../image/zx2.png'
import new_blue2 from '../image/new_blue.png'
import kakaoaa from '../image/kakaoaa.png'
import card123 from '../image/card123.png'
import card1234 from '../image/card1234.png'
import pay123 from '../image/pay123.png'
import pay1234 from '../image/pay1234.png'
import caaaa from '../image/caaaa.png'
import BrowserHeader from '../components/BrowserHeader';
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams ,useHistory } from 'react-router-dom';
import{
BrowserView,
MobileView,
isBrowser,
IsMobile,
TabletView
} from "react-device-detect";
import ReactPixel from 'react-facebook-pixel';

const PIXEL_ID = '266266868791844';
ReactPixel.init(PIXEL_ID);

function Test8(){
    const history = useHistory();
useEffect(()=> {
btnCheck()
    })
const [nextbtn, setNextbtn] = useState(nextbtn_new);
const [cardCount, setCardCount] = useState(0);
const [cashCount, setCashCount] = useState(0);
const [cardImg, setCardImg] = useState([icon_card]);
const [cashImg, setCashImg] = useState([icon_won]);
const [cardImg1, setCardImg1] = useState([card123]);
const [cashImg1, setCashImg1] = useState([pay123]);
const [eventcss, setEventcss] = useState('disabled-link');
const [paymethod, setPaymethod] = useState(['']);
const [paymethod2, setPaymethod2] = useState(['']);
const cardCheck = () => {
            if(cardCount===0){
            setCardCount(1)
            setPaymethod('card')
            setPaymethod2('F')
            if(cashCount===1){
            cashCheck()
            }
                          $('#card_text').css('color', 'rgb(74, 100, 245)');
                           $('#card').css('border', 'solid 1px #4f63ec');
                           $('#card').css('background-color', '#edefff');
                           setCardImg([icon_card_blue])
            }
            else {
           setCardCount(0)
                    $('#card_text').css('color', 'rgb(81, 81, 81)');
                    $('#card').css('border', '');
                $('#card').css('background-color', '#ffffff');
                setCardImg([icon_card])
            }
    }

const cardCheck1 = () => {
            if(cardCount===0){
            setCardCount(1)
            setPaymethod('card')
            setPaymethod2('F')
            if(cashCount===1){
            cashCheck1()

            }

                           setCardImg1([card1234])
            }
            else {
           setCardCount(0)
                    setCardImg1([card123])
            }
    }
    const cashCheck1 = () => {
                if(cashCount===0){
                setCashCount(1)
                setPaymethod('vbank')
                setPaymethod2('T')
                if(cardCount===1){
                cardCheck1()

                            }
                              setCashImg1([pay1234])
                }
                else {
               setCashCount(0)
                        setCashImg1([pay123])
                }
        }
const btnCheck = () => {
            if(cardCount===1 || cashCount===1){
                          $('#next_button').css('background-color', '#4a64f5');
                          setEventcss('')
                           $('#dam').css('color', '#ffffff');
                           setNextbtn(new_blue2)
            }
            else {
                    $('#next_button').css('background-color', '#efefef');
                        $('#dam').css('color', '#b7b7b7');
                        setEventcss('disabled-link')
                        setNextbtn(nextbtn_new)
            }
    }
const cashCheck = () => {
            if(cashCount===0){
            setCashCount(1)
            setPaymethod('vbank')
            setPaymethod2('T')
            if(cardCount===1){
                        cardCheck()

                        }
                          $('#cashtext').css('color', 'rgb(74, 100, 245)');
                           $('#cash').css('border', 'solid 1px #4f63ec');
                           $('#cash').css('background-color', '#edefff');
                           setCashImg([icon_won_blue])
            }
            else {
           setCashCount(0)
                    $('#cashtext').css('color', 'rgb(81, 81, 81)');
                    $('#cash').css('border', '');
                $('#cash').css('background-color', '#ffffff');
                setCashImg([icon_won])
            }
    }
    let price = sessionStorage.getItem('price');
    const payment2 = () => {
        ReactPixel.track('InitiateCheckout');
        var IMP = window.IMP;
        IMP.init('imp79162459');
                let merchant_uid1 = 'merchant_' + new Date().getTime();
                let coupon = sessionStorage.getItem("coupon");
                var customerclass = localStorage.getItem("customerClass");
                let brandname = sessionStorage.getItem("brandname");
                let firstgoods = sessionStorage.getItem("firstgoods");
                let realprice = price.replaceAll(",","");
                let realprice2 = realprice.replace("원","");
                var customeremail = localStorage.getItem("customeremail");
                var customername = localStorage.getItem("customername");
                var customerPhone = localStorage.getItem("customerPhone");
                let token3 = sessionStorage.getItem("token");
                let ajaxData = sessionStorage.getItem("valuelist3");
                let utmRoute = sessionStorage.getItem("utmRoute");
                let goodsSearchWord = '.';
                let searchcategory = sessionStorage.getItem("btn_value");
                $.ajax({
                    type: "POST",
                    url: "https://nametecadmin.kr/paymentdata",
                    data: JSON.stringify({
                            "coupon" : coupon,
                            "customeremail": customeremail,
                            "customerclass"  : customerclass,
                            "searchcategory": searchcategory,
                            "goodsSearchWord": goodsSearchWord,
                            "ajaxData": ajaxData,
                            "brandname": brandname,
                            "merchant_uid" : merchant_uid1,
                            "price" : realprice2,
                            "token" : token3,
                            "utmRoute" : utmRoute,
                            "firstgoods" : firstgoods,
                            "paymethod" : paymethod
                        }),
                    processData: false,
                    contentType: "application/json",
                    async: false,
                    success: function (data) {
                        if (data.status == 200) {
                        IMP.request_pay({
                pg : 'html5_inicis', // version 1.1.0부터 지원.
                pay_method : paymethod,
                merchant_uid : merchant_uid1,
                name : '상표 출원 신청 :' + brandname,
                amount : realprice2,
                buyer_email : customeremail,
                buyer_name : customername,
                buyer_tel : customerPhone,

                m_redirect_url : 'nametec.kr/payment2',
                redirect_url : 'nametec.kr/payment2'
            }, function(rsp) {
                if ( rsp.success ) {
                    var msg = '결제가 완료되었습니다.';
                    msg += '고유ID : ' + rsp.imp_uid;
                    msg += '상점 거래ID : ' + rsp.merchant_uid;
                    msg += '결제 금액 : ' + rsp.paid_amount;
                    msg += '카드 승인번호 : ' + rsp.apply_num;
                    history.push({
                        pathname:'/payment2',
                        search:'?vbank='+paymethod2+'&imp_success=true&imp_uid='+rsp.imp_uid
                    });
                } 
                else {
                    var msg = '결제에 실패하였습니다.';
                    msg += '에러내용 : ' + rsp.error_msg;
                }
            
            });
        } else {
            
            alert('로그인을 다시 해주세요.')
            history.push("/")
        }
    }
    ,
    err: function (err) {
        alert(err.status);
    }
});
                  };
    return (
    <>
    <TabletView>
    <div style={{margin : 'auto',maxWidth : '520px'}}>

<TopbarComponentWithoutPage path={'/Success3'}/>




<div style={{paddingLeft : '20px'}}>


<div style={{width : '342px',margin : 'auto'}}>
<GuidelineComponent3 title1={"결제하기"} help={'결제 수단을 선택해주세요.'}/>
</div>


<div id="card" onClick={cardCheck} className="midbox3" style={{cursor : 'pointer',paddingLeft :' 16.3px', borderColor : '#4a64f5'}}>
    <img id="cardimg" src={cardImg[0]} style={{width : '28.9px', marginTop : '13.4px', float : 'left'}}/>
    <div>
    <a id="card_text" className="Regular" style={{fontSize : '14px', color : '#515151', position : 'absolute', marginTop : '15.5px', marginLeft : '12.7px'}}>신용 / 체크카드</a>
        </div>
</div>

<div id="cash" onClick={cashCheck} className="midbox23" style={{cursor : 'pointer',paddingLeft : '20.8px'}}>
    <img id="cashimg" src={cashImg[0]} style={{width : '20px', marginTop : '13.4px', float : 'left'}}/>
    <div>
    <a id="cashtext" className="Regular" style={{fontSize : '14px', color : '#515151', position : 'absolute', marginTop : '15.5px', marginLeft : '17.2px'}}>계좌이체</a>
        </div>
</div>

</div>




{/*             <button type="button" id="payment_btn" disabled="disabled" style={{cursor : 'pointer',position:'fixed', bottom:'0px',marginTop: '4%',height: '60px', maxWidth : '520px'}}>106,000원 결제하기</button> */}

<div id="next_button"  className={eventcss} onClick={() => payment2()}
                             style={{cursor:'pointer', position: 'fixed',bottom:'0px',display: 'flex', alignItems: 'center',justifyContent: 'center', backgroundColor: '#efefef'}}>
                            <div id="dam" style={{color:'#b7b7b7'}}> {price} 결제하기</div>
                        </div>
</div>
    </TabletView>
    <MobileView>
        <div style={{margin : 'auto',maxWidth : '520px'}}>

                <TopbarComponentWithoutPage path={'/Success3'}/>




            <div style={{paddingLeft : '20px'}}>


            <div style={{width : '342px',margin : 'auto'}}>
            <GuidelineComponent3 title1={"결제하기"} help={'결제 수단을 선택해주세요.'}/>
            </div>


                <div id="card" onClick={cardCheck} className="midbox3" style={{cursor : 'pointer',paddingLeft :' 16.3px', borderColor : '#4a64f5'}}>
                    <img id="cardimg" src={cardImg[0]} style={{width : '28.9px', marginTop : '13.4px', float : 'left'}}/>
                    <div>
                    <a id="card_text" className="Regular" style={{fontSize : '14px', color : '#515151', position : 'absolute', marginTop : '15.5px', marginLeft : '12.7px'}}>신용 / 체크카드</a>
                        </div>
                </div>

                <div id="cash" onClick={cashCheck} className="midbox23" style={{cursor : 'pointer',paddingLeft : '20.8px'}}>
                    <img id="cashimg" src={cashImg[0]} style={{width : '20px', marginTop : '13.4px', float : 'left'}}/>
                    <div>
                    <a id="cashtext" className="Regular" style={{fontSize : '14px', color : '#515151', position : 'absolute', marginTop : '15.5px', marginLeft : '17.2px'}}>계좌이체</a>
                        </div>
                </div>

            </div>




{/*             <button type="button" id="payment_btn" disabled="disabled" style={{cursor : 'pointer',position:'fixed', bottom:'0px',marginTop: '4%',height: '60px', maxWidth : '520px'}}>106,000원 결제하기</button> */}

            <div id="next_button"  className={eventcss} onClick={() => payment2()}
                                             style={{cursor:'pointer', position: 'fixed',bottom:'0px',display: 'flex', alignItems: 'center',justifyContent: 'center', backgroundColor: '#efefef'}}>
                                            <div id="dam" style={{color:'#b7b7b7'}}> {price} 결제하기</div>
                                        </div>
        </div>
        </MobileView>
        <BrowserView>
        <BrowserHeader/>
        
        <div style={{margin : "0 auto"}}>
        <div style={{width:'1400px',paddingLeft:"calc(50% - 700px)"}}>
                       <img src={askalsal} style={{marginTop : '123px',marginLeft : 'calc(50% - 602px)',width:'515px',marginBottom:'50px'}}/>
                       <div>
                       <div style={{marginLeft:'454px'}}>
                       <img src={cardImg1[0]} onClick={cardCheck1} style={{marginTop : '23px',marginLeft : 'calc(50% - 582px)',width:'372px',marginBottom:'50px',cursor:'pointer'}}/>
                       <img src={cashImg1[0]} onClick={cashCheck1} style={{marginTop : '23px',width:'372px',marginBottom:'50px',cursor:'pointer'}}/>
                       <div className="Regular1" style={{fontSize:'20px',color:'#515151',marginLeft : 'calc(50% - 532px)',lineHeight:'1.5'}}> <a style={{position:'absolute',fontSize:'6px',marginTop:'5px'}}>●</a><a style={{marginLeft:'20px'}}>결제 수단을 선택하시면 KG 이니시스 및 은행 사이트로 이동합니다.</a><br/> <a style={{marginLeft:'20px',marginTop:'5px'}}>브라우저에 팝업 차단이 되어있다면 반드시 팝업 차단을 해제해주세요.</a></div>
                       <div className="Regular1" style={{fontSize:'20px',color:'#515151',marginTop:'20px',marginLeft : 'calc(50% - 532px)'}}><a style={{position:'absolute',fontSize:'6px',marginTop:'5px'}}>●</a> <a style={{marginLeft:'20px'}}>결제 후, 변리사의 상세 검토를 거쳐 등록이 어렵다고 판단되면 전액 환불처리 해드립니다.</a></div>
                       </div>
                       </div>
                       
                       </div>
                       <div id="introFooter" style={{float:'right',backgroundColor: '#f5f6ff',height:'116px'}}>
                              <img  className={eventcss} onClick={() => payment2()} src={nextbtn} style={{cursor:'pointer',width : '200px',marginLeft:'20px',marginTop:'27px',float:'right',marginRight:'calc(50% - 485px)'}}/>
                              <a href='http://pf.kakao.com/_lWuhs/chat'>
                                       <img src={kakaoaa} onClick="" style={{cursor:'pointer',width : '283px ',marginTop:'27px',float:'right'}}/>
                                       </a>
                                       </div>
                       </div>
        </BrowserView>
        </>
    )

}

export default Test8;