import React, { useState, useEffect } from 'react';
import skarn from '../image/icon_kakao@2x.png'
const ScrollBanner = () => {
  const [offsetY, setOffsetY] = useState(753); // Initial top offset set to 753px

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const maxOffset = document.body.scrollHeight - window.innerHeight - 100; // Adjust the -100 as needed
      const minOffset = 743; // Minimum offset to keep the banner from going above initial position

      // Adjust the offset based on the scroll position
      const newOffsetY = Math.min(Math.max(minOffset, minOffset), maxOffset);

      setOffsetY(newOffsetY);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const bannerStyle = {
    position: 'fixed',
    // top: `${offsetY}px`,
    right: '15px',
    width: '72px',
    cursor: 'pointer',
    zIndex: 10000,
    bottom:'15px'
  };

  return (
    <img
      id="channel-chat-button123"
      src={skarn} // Replace with your actual image path
      alt="Chat Button"
      onClick={() => {
        // Add your onClick handling here
        window.location = "http://pf.kakao.com/_lWuhs/chat";
      }}
      style={bannerStyle}
    />
  );
};

export default ScrollBanner;