import React ,{Component} from "react";
import { useState } from "react";
import Modal from './Modal';
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams} from 'react-router-dom';
import '../text.css'







export default function AlertHeaderPc2(){
    const [display, setDisplay] = useState('none');

    const modalHandler = () => {
        if(display === 'none'){
            setDisplay('inline-block');
        }else{
            setDisplay('none');
        }
        return;
    }

    return(
        <>
        <div className="Light" style={{ top : "0px",
            Width: "100%",
            backgroundColor : "#fafafa",
            zIndex :"10010",
            

            margin: "auto",marginTop:'10px',paddingTop:'40px',paddingBottom:'40px',fontSize:'18px',textAlign:'center',minWidth:'1400px',width:'100%'}}>
            <a className="Medium">로고가 있는 경우 함께 등록하는 것이 좋고, 로고가 없는 경우에도 권리범위 등에서 <a style={{color:'#4a64f5'}}>손해보는 부분은 전혀 없습니다.</a></a>
            
        </div>
        </>

    );
}