
import React, { useState, useEffect } from "react";
import KakaoLogin from "react-kakao-login";
import kakaotalk1 from '../image/kakaotalk@3x.png'

const SocialKakao2 = ()=>
{
    const Rest_api_key='128ac2386cfdbf1d2caa4979003fbdf7' //REST API KEY
    const redirect_uri = 'http://nametec.kr/brandregister' //Redirect URI
    // oauth 요청 URL
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`
    const handleLogin = ()=>{
        window.location.href = kakaoURL
    }
    return(
    <>
    <img src={kakaotalk1} onClick={handleLogin} style={{cursor:'pointer',width:'353px',height:'70px'}} />
    </>
    )
}
export default SocialKakao2
