import React, { useState, useRef } from 'react';
import TopbarComponent  from '../components/Topbar';
import Nextbutton from '../components/Nextbutton';
import GuidelineComponent from '../components/Guideline';
import '../style.css';
import '../text.css';
import AlertHeader5 from '../components/AlertHeader5';
import plusbox from '../data/plus_box@3x.png';
import ask2 from '../image/aksjdlas.png';
import Plus_button from '../image/Plus_button@3x.png'
import kakaoaa from '../image/kakaoaa.png'
import nobtn from '../image/nextbtn_new.png'
import whiteboxpng from '../image/1-01@3x.png'
import new_blue from '../image/new_blue.png'
import uploadBtn from '../image/uploadBtn.png'
import AlertHeaderPc3 from '../components/AlertHeaderPc3';
import $ from 'jquery';
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams} from 'react-router-dom';
import{
BrowserView,
MobileView,
isBrowser,
IsMobile,
TabletView
} from "react-device-detect";
import BrowserHeader from '../components/BrowserHeader';

function BrandLogoRegister(){
    const [logoTF, setLogoTF] = useState(false);
    const [eventcss, setEventcss] = useState('disabled-link');
    // const [eventcss, setEventcss] = useState('');
    const input = useRef(null); 
    const img = useRef(null);
    const [src, setSrc] = useState('')
    const [img1, setText1] = useState(['categorybox11']);
    const [img2, setText2] = useState(['categorybox11']);
    const [nextbtn, setNextbtn] = useState(nobtn);
    const [img11, setText11] = useState(['categorybox111']);
    const [img22, setText22] = useState(['categorybox111']);
    const [filename, setFilename] = useState([''])
    function fileUpload(){
        let file = input.current.files[0];

            var maxSize = 5 * 1024 * 1024;
            var fileSize = file.size;
            
            if(fileSize > maxSize){
                alert("첨부파일 사이즈는 5MB 이내로 등록 가능합니다.");
                return false;
            
        }else{
            const reader = new FileReader()
        reader.onload = e => { 
            setSrc(e.target.result)
        }

        reader.readAsDataURL(file)
        setEventcss('')
        setFilename([file.name])
        setNextbtn(new_blue)
        $('#next_button').css('background-color', '#4a64f5');
                           $('#dam').css('color', '#ffffff');
        }
        

        
    }
    const onClick1 = (i) => {
        if (i == 1 ){
            if(img1[0] == 'categorybox11'){
                setText1(['categorybox22'])
                setText2(['categorybox11'])
                $('#next_button').css('background-color', '#4a64f5');
                           $('#dam').css('color', '#ffffff');
                           setEventcss('')
                           setLogoTF(false)
            }else{
                setText1(['categorybox11'])
                $('#next_button').css('background-color', '#efefef');
                        $('#dam').css('color', '#b7b7b7');
                        setEventcss('disabled-link')
                        setLogoTF(false)
            }
        }else{
            if(img2[0] == 'categorybox11'){
                setText2(['categorybox22'])
                setText1(['categorybox11'])
                $('#next_button').css('background-color', '#efefef');
                $('#dam').css('color', '#b7b7b7');
                           setLogoTF(true)
                           setEventcss('disabled-link')
            }else{
                setText2(['categorybox11'])
                $('#next_button').css('background-color', '#efefef');
                        $('#dam').css('color', '#b7b7b7');
                        setEventcss('disabled-link')
                        setLogoTF(false)
            }
        }
        
              };
              const onClick11 = (i) => {
                if (i == 1 ){
                    if(img11[0] == 'categorybox111'){
                        setText11(['categorybox222'])
                        setText22(['categorybox111'])
                        $('#next_button').css('background-color', '#4a64f5');
                                   $('#dam').css('color', '#ffffff');
                                   setEventcss('')
                                   setLogoTF(false)
                                   setNextbtn(new_blue)
                    }else{
                        setText11(['categorybox111'])
                        $('#next_button').css('background-color', '#efefef');
                                $('#dam').css('color', '#b7b7b7');
                                setEventcss('disabled-link')
                                setLogoTF(false)
                                setNextbtn(nobtn)
                    }
                }else{
                    if(img22[0] == 'categorybox111'){
                        setText22(['categorybox222'])
                        setText11(['categorybox111'])
                        $('#next_button').css('background-color', '#efefef');
                        $('#dam').css('color', '#b7b7b7');
                                   setLogoTF(true)
                                   setEventcss('disabled-link')
                                   setNextbtn(nobtn)
                    }else{
                        setText22(['categorybox111'])
                        $('#next_button').css('background-color', '#efefef');
                                $('#dam').css('color', '#b7b7b7');
                                setEventcss('disabled-link')
                                setNextbtn(nobtn)
                                setLogoTF(false)
                    }
                }
                
                      };
    const saveLogo = () =>{

            let formData = new FormData();
        let inputimage = document.getElementById('file-1');

        
        if (inputimage == null){
            window.location.replace("/brandcategoryregister")
        }else{
            let file = inputimage.files[0];
        let token2 = sessionStorage.getItem("token");
       formData.append('filedata'  ,file ,  file.name);
       formData.append('filename'  , file.name);
       formData.append('token'  ,token2 );
        $.ajax({
            type: "POST",
            url: 'https://nametecadmin.kr/savelogo',
            enctype: 'multipart/form-data',
            data:formData,
            processData: false,
            contentType: false,
            cache:false,
            timeout:6000000,
            
           
            success: function (data) {
                if (data.status == 200) {
                    window.location.replace("/brandcategoryregister")
                } else {


                }
            }
            ,
            err: function (err) {
                alert(err.status);
            }
        });
        }}



    return(
    <div>
    <MobileView>
    <div style={{margin : 'auto',maxWidth : '520px'}}>
        <TopbarComponent page={'02'} path={'/brandregister'}/>
        <AlertHeader5/>
        <div style={{width : '342px',margin : 'auto'}}>
        <GuidelineComponent title1="상표 로고도 함께" title2={"등록하시나요"} help2={'?'} help={'로고가 있는 경우, 파일을 준비해주세요.'}>
        </GuidelineComponent>
        </div>
        <div style={{marginTop : '-45px'}}>
         <div id="1" onClick={() => onClick1(1)} style={{fontSize : '16px',lineHeight : '1',marginLeft:'20px',marginTop:'20px'}} className={img1[0]}>
                
                <div style={{display :'inline-block',marginLeft : '10px' ,lineHeight:'1.2'}}>
<a className="Bold1">로고가 없습니다 (문자로 상표출원 진행)</a>
</div>
</div>
<div id="2" onClick={() =>  onClick1(2)} style={{fontSize : '16px',lineHeight : '1',marginTop :'10px',marginLeft:'20px' }} className={img2[0]}>

                <div style={{display :'inline-block',marginLeft : '10px' ,lineHeight:'1.2'}}>
<a className="Bold1">로고가 있습니다 (로고로 상표출원 진행)</a>
</div>
</div> 
{logoTF ?
<>
<input type="file" accept="image/*,.pdf,.png" name="file-1[]" id="file-1"
                            onChange={fileUpload}
                            ref={input}
                            className="inputfile inputfile-1" 
                            style={{ display: 'none', textAlign: 'center' }} />

    
                            <label htmlFor='file-1'>
                            <div className="Light" style={{display:'inline-block',backgroundColor:'#898989',color:'#ffffff',marginTop:'15px',padding:'10px 7px 10px 11px',fontSize:'14px',marginLeft:'20px',width:'103px'}}>
    로고 첨부(Click!)   </div> <div  className='Medium' style={{display:'inline-block',marginLeft:'6px',fontSize:'16px'}}>{filename}</div>
                            </label>
                            
    <div style={{fontSize : '14px',marginTop:'15px',color :'#4a64f5',marginLeft:'20px'}}>
    *로고가 있는 경우, 로고 파일을 첨부해주셔야 진행됩니다 :)
    </div>
    <div style={{marginTop:'15px',color:'#898989',fontSize:'14px',marginLeft:'20px',lineHeight:'1.45'}}>
    권장사이즈 : 최소 100 * 100px 이상.<br/>
파일형식 : jpg, jpeg, gif, png<br/>
파일 용량 : 최대 5MB 까지.
    </div>

</> :
<>

</>

}
</div>
                                <div id="next_button" className={eventcss}  onClick={saveLogo}
                                     style={{cursor:'pointer', position: 'fixed',bottom:'0px',display: 'flex', alignItems: 'center',justifyContent: 'center', backgroundColor: '#efefef'}}>
                                    <div id="dam" className='noto' style={{color:'#b7b7b7'}}> 다음</div>
                                </div>
    </div>
    </MobileView>
    <BrowserView>
        <div style={{margin : "0 auto"}}>
                <BrowserHeader/>
                <AlertHeaderPc3/>
                <div style={{width:'1400px',paddingLeft:"calc(50% - 700px)"}}>
           <img src={ask2} style={{marginTop : '123px',marginLeft : 'calc(50% - 602px)',width:'730px'}}/>
           
           
           <div style={{width:'800px',height:'397px',marginLeft : 'calc(50% - 400px)',marginTop:'50px',borderRadius:'10px',textAlign:'center',fontSize:'20px'}}>
           <div id="1" onClick={() => onClick11(1)} className={img11[0]} style={{display:'inline-block',boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.16)',width :'370px',height:'118px',marginRight:'40px',position:'absolute',paddingTop:'68px'}}>
            {/* <div style={{position : 'absolute',marginLeft:'125px',marginTop:'80px'}}> */}
            로고가 없습니다
            <br/>
            (문자로 상표출원 진행)
            {/* </div> */}
                      </div>
           <div id="2" onClick={() => onClick11(2)} className={img22[0]} style={{display:'inline-block',boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.16)',width :'370px',height:'118px',marginLeft:'388px',paddingTop:'68px'}}>
           {/* <div style={{position : 'absolute',marginLeft:'115px',marginTop:'65px'}}> */}
            로고가 있습니다
            <br/>
            (로고로 상표출원 진행)
            {/* </div> */}
           </div>
           {logoTF ?
<>
<div style={{textAlign:'left',marginLeft:'39px',marginTop:'30px'}}>
<input type="file" accept="image/*,.pdf,.png" name="file-1[]" id="file-1"
                            onChange={fileUpload}
                            ref={input}
                            className="inputfile inputfile-1" 
                            style={{ display: 'none', textAlign: 'center' }} />

    
                            <label htmlFor='file-1'>
                            <div className="Medium" style={{textAlign:'left',display:'inline-block',backgroundColor:'#898989',color:'#ffffff',marginTop:'15px',padding:'10px 7px 10px 11px',fontSize:'20px',width:'143px'}}>
    로고 첨부(Click!)   </div> <div  className='Medium' style={{textAlign:'left',display:'inline-block',marginLeft:'6px',fontSize:'20px'}}>{filename}</div>
                            </label>
                        
    <div className='Regular1' style={{textAlign:'left',fontSize : '20px',marginTop:'15px',color :'#4a64f5'}}>
    • 로고가 있는 경우, 로고 파일을 첨부해주셔야 진행됩니다 :)
    </div>
    <div className='Regular1' style={{whiteSpace:'pre-wrap',textAlign:'left',marginTop:'15px',color:'#898989',fontSize:'20px',lineHeight:'1.45'}}>
    • 권장사이즈 : 최소 100 * 100px 이상.<br/>
    　파일형식 : jpg, jpeg, gif, png<br/>
    　파일 용량 : 최대 5MB 까지.
    </div>
</div>
</> :
<>

</>}
           {/* <div style={{border :'2px dashed lightgrey',width:'280px',height:'280px',marginTop:'56px',marginLeft:'94px',marginBottom:'56px',display:'inline-block'}}>

           { src ?
           <>
           <input type="file" accept="image/*,.pdf,.png" name="file-1[]" id="file-1"
                          onChange={fileUpload}
                          ref={input}
                          className="inputfile inputfile-1"
                          style={{ display: 'none', textAlign: 'center' }} />
                           <img className="thumb"
                              src={src} id="top"
                                style={{cursor:'pointer',width : '217px',paddingTop:'30px',paddingLeft :'30px' }} />

                          </>
                           :
                               <>
                               <input type="file" accept="image/*,.pdf,.png" name="file-1[]" id="file-1"
                                       onChange={fileUpload}
                                       ref={input}
                                       className="inputfile inputfile-1"
                                       style={{ display: 'none', textAlign: 'center' }} />
                                        <img className="thumb"
                                           src={whiteboxpng} id="top"
                                             style={{cursor:'pointer',width : '217px',paddingTop:'30px',paddingLeft :'30px' }} />
                               </> }
           </div>
           <div style={{display:'inline-block',marginLeft:'56px',position:'absolute',marginTop:'119px',lineHeight:'1.4'}}>
           <a className="Regular1" style={{fontSize:"22px",color:'#898989'}}>
           권장사이즈 : 최소 100 * 100px 이상.<br/>파일 형식 : jpg, jpeg, gif, png<br/>파일 용량 : 최대 5MB 까지.
           </a>

           </div>
                                     <label htmlFor='file-1'>
           <img src={uploadBtn} onClick="" style={{cursor:'pointer',width : '80px',marginLeft:'56px',marginTop:'239px',position:'absolute'}}/>
           </label>
           </div> */}

           
           </div>
           

        
           
           </div>
           <div style={{height:'200px'}}>

           </div>
           <div id="introFooter" onClick={saveLogo} style={{float:'right',backgroundColor: '#f5f6ff',height:'116px',lineHeight:'110px'}}>
           <div style={{width:'982px',marginLeft:'calc(50% - 491px)'}}>
                  <img src={nextbtn} className={eventcss}  style={{cursor:'pointer',width : '200px',marginLeft:'20px',marginTop:'27px',float:'right'}}/>
                  <a href='http://pf.kakao.com/_lWuhs/chat'>
                           <img src={kakaoaa}  style={{cursor:'pointer',width : '283px ',marginTop:'27px',float:'right'}}/>
                           </a>
                           </div>
                           </div>
                </div>
               </BrowserView>
                   </div>
    )
}

export default BrandLogoRegister;