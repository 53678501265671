import React ,{Component} from "react";
import { useState } from "react";
import Modal from './Modal';
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams} from 'react-router-dom';
import '../text.css'







export default function AlertHeaderPc1(){
    const [display, setDisplay] = useState('none');

    const modalHandler = () => {
        if(display === 'none'){
            setDisplay('inline-block');
        }else{
            setDisplay('none');
        }
        return;
    }

    return(
        <>
        <div className="Light" style={{ top : "0px",
            Width: "100%",
            backgroundColor : "#fafafa",
            zIndex :"10010",
            

            margin: "auto",marginTop:'10px',paddingTop:'40px',paddingBottom:'40px',fontSize:'18px',minWidth:'1400px',width:'100%'}}>
                <div style={{width: '740px',margin : '0px auto'}}>
            <a className="Medium">지금 입력하시는 내용은 진단을 위한 참고 입력 값이며, <a style={{color:'#4a64f5'}}>출원 접수 후 변리사 상담을 통해 변경 가능</a>합니다 :)</a>
            </div>
        </div>
        </>

    );
}