import React, { useState, useEffect } from 'react';
import '../FadeBanner2.css'

const FadeBanner2 = ({ banners }) => {
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, 3000); // Change banner every 5 seconds (5000 milliseconds)

    return () => clearInterval(interval);
  }, [banners.length]);

  return (
    <div className="fade-banner-container2">
      {banners.map((banner, index) => (
        <div
          key={index}
          className={`fade-banner ${index === currentBannerIndex ? 'active' : ''}`}
        >
          {banner}
        </div>
      ))}
    </div>
  );
};

export default FadeBanner2;
