import  React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import nametecLogo from "../image/nametecLogo.png";
import '../text.css'
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams} from 'react-router-dom';
import{
BrowserView,
MobileView,
isBrowser,
IsMobile,
TabletView
} from "react-device-detect";

export default function BrowserHeader(){
const [loginstatus, setLoginstatus] = useState('로그아웃');
const [login, setLogin] = useState(false);
const [loginOut, setloginOut] = useState('로그아웃');
const loginStatusChange = () =>{
if (localStorage.getItem("customername") !=null) {
setLoginstatus('로그아웃');
setloginOut(logoutPage);
setLogin(true);

    }else{
    setLoginstatus('로그인');
    setloginOut(loginPage);
    setLogin(false);
    }
}
const loginPage = () =>{
}
const logoutPage = () =>{

// localStorage.clear();
}
const logoutPage2 = () =>{

localStorage.clear();
loginStatusChange()
window.location.replace("/")
}

useEffect(() => {
		loginStatusChange()
	}, [])
return (
        <div style={{paddingLeft : 'calc(50% - 717px)',width:'1367px',marginTop:'10px',marginLeft:'20px'}}>
        <Link to='/'>
        <img src={nametecLogo} style={{display: "inline-block",
                        width: "150px"}}/>
                        </Link>
        <div className="Bold" style={{display:'inline-block',float:'right',marginTop:'15px',fontSize:'16px',marginRight:'-15px'}}>
        {login ?
        <Link to='/mypage'>
        <div  style={{display:'inline-block',marginRight : '50px',cursor:'pointer',color : '#000000'}}>
        마이페이지
        </div>
        </Link>:<Link to='/login'>
        <div  style={{display:'inline-block',marginRight : '50px',cursor:'pointer',color : '#000000'}}>
        마이페이지
        </div>
        </Link>

}
        <Link to='/costprocess'>
        <div style={{display:'inline-block',marginRight : '50px',cursor:'pointer',color : '#000000'}}>
        비용 및 절차 안내
        </div>
        </Link>
        <Link to='/center'>
        <div style={{display:'inline-block',marginRight : '50px',cursor:'pointer',color : '#000000'}}>
        고객센터
        </div>
        </Link>
        <a href='https://blog.naver.com/name-tec' target="_blank"
         rel="noopener noreferrer">
        <div style={{display:'inline-block',marginRight : '50px',cursor:'pointer',color : '#000000'}}>
        칼럼
        </div>
        </a>
        {login ?
        <div onClick={logoutPage2} style={{display:'inline-block',cursor:'pointer',color : '#000000',marginRight : '50px'}}>
        {loginstatus}
        </div>
        :
        <Link to='/login'>
            <div  style={{display:'inline-block',cursor:'pointer',color : '#000000',marginRight : '50px'}}>
            {loginstatus}
            </div>
            </Link>
        }
        <a href='https://www.nametec.co.kr/'target="_blank"
         rel="noopener noreferrer">
        <div className="Bold" style={{display:'inline-block',cursor:'pointer',color : '#4a64f5'}}>
        네임텍 특허법률사무소
        </div>
        </a>
        </div>

        </div>
)

}

