import React from "react";
import menu from "../image/menu@3x.png";
import '../text.css'
import {useState} from 'react';
import x_btn from '../image/x_menuicon@3x.png';
import backarrow from '../data/backarrow@3x.png';
import nametecLogo from "../image/nametecLogo.png";
import new_blue from '../image/zx2.png'
import kakaoaa from '../image/kakaoaa.png'
import BrowserHeader from '../components/BrowserHeader';
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams} from 'react-router-dom';
import{
BrowserView,
MobileView,
isBrowser,
IsMobile,
TabletView
} from "react-device-detect";

const CostProcessContent = () => {
    return (

    <div style={{textAlign: 'center', paddingTop : '49.5px'}}>
        <div className="midbox"
        style = {{width: '88%',
        margin: '24px 0 21.3px',
        marginLeft: '6.3%', 
        borderRadius: '10px',
        boxShadow: '1px 1px 4px 0 rgb(0 0 0 / 10%)',
        backgroundColor: '#fafafa'
        }}>
            <div style={{paddingTop : '13px', paddingBottom : '11px', borderBottom : '1px solid #e0e0e0', marginRight : '20px', marginLeft : '20px', marginBottom : '20px'}}>
            <a className="Medium" style={{fontSize : '14px'}}>1단계 : 상표 등록</a>
            <br/>
            <br/>
            <a className="Light" style={{fontSize : '14px'}}>네임텍 수수료</a>
            <a className="Medium" style={{fontSize : '24px', color : '#4a64f5'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;110,000</a>
            <a className="Light" style={{fontSize : '14px'}}>원</a>
            <br/>
            <a className="Light" style={{fontSize : '14px'}}>특허청 관납료</a>
            <a className="Medium" style={{fontSize : '24px', color : '#b7b7b7'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;52,000</a>
            <a className="Light" style={{fontSize : '14px'}}>원</a>
            </div>
            <a className="Medium" style={{fontSize : '14px'}}>“특허청에 상표 등록심사를 요청합니다”</a>
        <div style={{paddingTop : '16px', paddingBottom : '15px'}}>
        <a className="Light" style={{fontSize : '14px', color : '#898989'}}>
            · 충분한 사전 검토 없는 상표 출원은
        <br/>
        특허청 허가가 나지 않을 수 있으므로,
        </a>
        <a className="Medium" style={{fontSize : '14px', color : '#515151'}}>
        <br/>
네임텍 사이트에서 사전 법률 진단을 진행 후,<br/>
변리사의 검토를 한번 더 거쳐 출원 신청을 진행합니다.</a>
        </div>
    <div style={{paddingBottom : '16px'}}>
        <a className="Light" style={{fontSize : '14px', color : '#898989'}}>· 서비스 수수료에는</a>
        <a className="Medium" style={{fontSize : '14px', color : '#515151'}}> 사전 법률 진단과
        <br/>
        특허청 출원 신청 대행료가 모두 포함</a>
        <a className="Light" style={{fontSize : '14px', color : '#898989'}}>되어 있습니다.</a>
    </div>
    <div style={{paddingBottom : '16px'}}>
        <a className="Light" style={{fontSize : '14px', color : '#898989'}}>· 출원은 
        <a className="Medium" style={{fontSize : '14px', color : '#515151'}}> 결제일 기준으로 일주일 이내에 완료
        </a>됩니다.</a>
    </div>
        </div>
    </div>
    



    )
    }


    const CostProcessContent11 = () => {
        return (
    
            <div className="midbox2" style={{textAlign: 'center', width: '88%',
            margin: '10px 0 21.3px',
            marginTop : '10px',
            marginLeft: '6.3%',
            borderRadius: '10px',
            boxShadow: '1px 1px 4px 0 rgb(0 0 0 0.1)',
            backgroundColor: '#edeffe'}}>
          <div style={{paddingTop : '16px', paddingBottom : '9px'}}>
              <a className ="Medium" style={{fontSize : '14px'}}>&lt;선택사항 :<a style={{color : '#4a64f5'}}>심사기간 단축
</a> 옵션 선택 시&gt;</a>
          </div>
          <a className="Light" style={{fontSize : '14px'}}>서비스 수수료</a>
          <a className="Medium" style={{fontSize : '24px', color : '#4a64f5'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;200,000</a>
          <a className="Light" style={{fontSize : '14px'}}>원</a>
          <br/>
              <a className="Light" style={{fontSize : '14px'}}>특허청 관납료</a>
              <a className="Medium" style={{fontSize : '24px', color : '#b7b7b7'}}>&nbsp;&nbsp;&nbsp;&nbsp;160,000</a>
              <a className="Light" style={{fontSize : '14px'}}>원</a>
              <div style={{paddingBottom : '16px', paddingTop : '16px', marginTop : '16px', marginRight : '20px', marginLeft : '20px', borderTop : '1px solid #e0e0e0'}}>
                  <a className ="Medium" style={{fontSize : '14px'}}>“특허청 심사 기간을 단축합니다”</a>
              </div>
              <div style={{paddingBottom : '16px'}}>
                  <a className="Light" style={{fontSize : '14px', color : '#898989'}}>· 기존의 15개월 이상 걸리는 심사 기간을</a>
                  <br/>
                      <a className="Medium" style={{fontSize : '14px', color : '#515151'}}>약 2~3개월 정도 수준으로 단축</a>
                      <a className="Light" style={{fontSize : '14px', color : '#898989'}}>할 수 있는 옵션입니다.</a>
                  </div>
                  
            <div style={{paddingBottom : '16px'}}>
                  <a className="Light" style={{fontSize : '14px', color : '#898989',lineHeight:'1.4'}}>· 해당 옵션을 선택하시면 </a>
                  
                      <a className="Medium" style={{fontSize : '14px', color : '#515151'}}>기본 수수료 및 관납료에 <br/> 위의 비용이 추가</a><a className="Light" style={{fontSize : '14px', color:'#898989' }}>됩니다.</a><br/>
                      <a className="Light" style={{fontSize : '14px', color : '#898989'}}>(심사기간 단축
 시 총 522,000원 납부)</a>
                  </div>
      </div>
        
    
    
    
        )
        }

const Dots = () => {

return (
    <div style={{lineHeight: '6px', color : '#b7b7b7', position: 'absolute', left : '50%', marginTop : '-22px'}}>
    <a>.<br/>.<br/>.<br/>.<br/>.<br/>.<br/>.<br/></a>
</div>
)
}
const Dots2 = () => {

return (
<div style={{width:'800px',margin: '0px  auto'}}>
    <div style={{lineHeight: '6px', color : '#b7b7b7', marginLeft:'400px'}}>
    <a>.<br/>.<br/>.<br/>.<br/>.<br/>.<br/>.<br/></a>
</div>
</div>
)
}

const CostProcessContent2 = () => {

return (
    <>
    <div style={{textAlign: 'center', marginBottom: '110px'}}>
        <div className="midbox"
        style = {{width: '88%',
        margin: '-24px 0 21.3px',
        marginLeft: '6.3%',
        borderRadius: '10px',
        boxShadow: '1px 1px 4px 0 rgb(0 0 0 / 10%)',
        backgroundColor: '#fafafa'
        }}>

        <div className="" style={{ marginTop: '72px' }}>
            <div style={{ paddingTop: '16px' }}>
                <a className="Medium" style={{ fontSize: '14px' }}>2단계 : 특허청 심사</a>
            </div>
            <div style={{ paddingTop: '11px', paddingBottom: '10px', borderBottom: '1px solid #e0e0e0', marginRight: '20px', marginLeft: '20px', marginBottom: '11px' }}>
                <a className="Medium" style={{ fontSize: '24px', color: '#4a64f5'}}>15~18</a>
                <a className="Medium" style={{ fontSize: '14px', color: '#4a64f5' }}>개월 소요</a>
            </div>
        <a className="Medium" style={{ fontSize: '14px', color: '#000000' }}>“상표 등록 가능 여부를 결정합니다”</a>

        <div style={{paddingTop : '16px', paddingBottom : '15px'}}>
                <a className="Light" style={{fontSize : '14px', color : '#898989'}}>· 특허청에서 법률 검토를 진행 후,</a>
                <br/>
                <a className="Medium" style={{fontSize : '14px', color : '#515151'}}>상표 등록 가능 여부를 결정하는 단계</a>
                <a className="Light" style={{fontSize : '14px', color : '#898989'}}>입니다.</a>
        </div>
        </div>
        </div>

        <div style={{lineHeight: '6px', color : '#b7b7b7', position: 'absolute', left : '50%', marginTop : '-22px'}}>
            <a>.<br/>.<br/>.<br/>.<br/>.<br/>.<br/>.<br/></a>
            </div>

            <div className="midbox"
        style = {{width: '88%',
        margin: '52px 0 21.3px',
        marginLeft: '6.3%',
        borderRadius: '10px',
        boxShadow: '1px 1px 4px 0 rgb(0 0 0 / 10%)',
        backgroundColor: '#fafafa'
        }}>
                <div className="" style={{marginTop : '52px'}}>
                    <div style= {{paddingTop : '16px', paddingBottom : '10px'}}>
                        <a className="Medium" style={{fontSize : '14px'}}>3단계 : 상표 등록</a>
                    </div>
                        <a className="Light" style={{fontSize : '14px'}}>특허청 관납료</a>
                        <a className="Medium" style={{fontSize : '24px', color : '#b7b7b7'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;210,120</a>
                        <a className="Light" style={{fontSize : '14px'}}>원</a>
                        <div style={{paddingBottom : '15px'}}>
                            <a className="Light" style={{fontSize : '14px', color :  '#898989', marginLeft : '144px', marginTop : '-5px'}}>(10년 기준)</a>
                        </div>
                </div>
                </div>

            </div>

            </>
                )
            }

const CostProcessContent3 = () => {
    return (

    <div style={{textAlign: 'center', paddingTop : '49.5px'}}>
        <div
        style = {{width: '800px',
        marginLeft: '6.3%',
        borderRadius: '10px',
        boxShadow: '1px 1px 4px 0 rgb(0 0 0 / 10%)',
        backgroundColor: '#fafafa',
        margin : '0 auto'
        }}>
            <div style={{paddingTop : '20px', paddingBottom : '20px', borderBottom : '1px solid #e0e0e0', marginRight : '20px', marginLeft : '20px', marginBottom : '20px'}}>
            <a className="Medium" style={{fontSize : '18px'}}>1단계 : 상표 출원</a>
            <br/>
            <br/>
            <a className="Light" style={{fontSize : '18px'}}>네임텍 수수료</a>
            <a className="Medium" style={{fontSize : '30px', color : '#4a64f5'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;110,000</a>
            <a className="Light" style={{fontSize : '18px'}}>원</a>
            <br/>
            <a className="Light" style={{fontSize : '18px'}}>특허청 관납료</a>
            <a className="Medium" style={{fontSize : '30px', color : '#b7b7b7'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;52,000</a>
            <a className="Light" style={{fontSize : '18px'}}>원</a>
            </div>
            <a className="Medium" style={{fontSize : '20px'}}>“특허청에 상표 등록심사를 요청합니다”</a>
        <div style={{paddingTop : '16px', paddingBottom : '15px',lineHeight:'1.4'}}>
        <a className="Light" style={{fontSize : '18px', color : '#898989'}}>
            · 충분한 사전 검토 없는 상표 출원은 특허청 허가가
        <br/>
            나지 않을 수 있으므로,
        </a>
        <a className="Medium" style={{fontSize : '18px', color : '#515151'}}>네임텍 사이트에서
        <br/>
        사전 법률 진단을 진행한 후 출원 신청을 진행합니다.</a>
        </div>
    <div style={{paddingBottom : '20px',lineHeight:'1.4'}}>
        <a className="Light" style={{fontSize : '18px', color : '#898989'}}>· 서비스 수수료에는</a>
        <a className="Medium" style={{fontSize : '18px', color : '#515151'}}>사전 법률 진단과
        <br/>
        특허청 출원 신청 대행료가 모두 포함</a>
        <a className="Light" style={{fontSize : '18px', color : '#898989'}}>되어 있습니다.</a>
    </div>

    <div style={{paddingBottom : '20px',lineHeight:'1.4'}}>
    <a className="Light" style={{fontSize : '18px', color : '#898989'}}>· 출원은
        <a className="Medium" style={{fontSize : '18px', color : '#515151'}}> 결제일 기준으로 일주일 이내에 완료
        </a>됩니다.</a>
    </div>
    
        </div>
    </div>



    )
    }


    const CostProcessContent23 = () => {
        return (
    
            <div  style={{ width: '800px',textAlign: 'center',
            margin: '40px 0 21.3px',
            marginTop : '50px',
            borderRadius: '10px',
            boxShadow: '1px 1px 4px 0 rgb(0 0 0 0.1)',
            backgroundColor: '#f5f6ff',
            margin : '0 auto',
            boxShadow: '1px 1px 4px 0 rgb(0 0 0 / 10%)',
            paddingBottom:'20px;',
            marginTop:'16px'}}>
          <div style={{paddingTop : '20px', paddingBottom : '9px'}}>
              <a className ="Medium" style={{fontSize : '18px'}}>&lt;선택사항 :<a style={{color : '#4a64f5'}}>심사기간 단축
</a> 옵션 선택 시&gt;</a>
          </div>
          <a className="Light" style={{fontSize : '18px'}}>서비스 수수료</a>
          <a className="Medium" style={{fontSize : '30px', color : '#4a64f5'}}>&nbsp;&nbsp;&nbsp;&nbsp;200,000</a>
          <a className="Light" style={{fontSize : '18px'}}>원</a>
          <br/>
              <a className="Light" style={{fontSize : '18px'}}>특허청 관납료</a>
              <a className="Medium" style={{fontSize : '30px', color : '#b7b7b7'}}>&nbsp;&nbsp;&nbsp;&nbsp;160,000</a>
              <a className="Light" style={{fontSize : '18px'}}>원</a>
              <div style={{paddingBottom : '16px', paddingTop : '16px', marginTop : '16px', marginRight : '20px', marginLeft : '20px', borderTop : '1px solid #e0e0e0'}}>
                  <a className ="Medium" style={{fontSize : '20px'}}>“특허청 심사 기간을 단축합니다”</a>
              </div>
              <div style={{paddingBottom : '20px'}}>
                  <a className="Light" style={{fontSize : '18px', color : '#898989',lineHeight:'1.4'}}>· 기존의 15개월 이상 걸리는 심사 기간을</a>
                  <br/>
                      <a className="Medium" style={{fontSize : '18px', color : '#515151'}}>약 2~3개월 정도 수준으로 단축</a>
                      <a className="Light" style={{fontSize : '18px', color : '#898989'}}>할 수 있는 옵션입니다.</a>
                  </div>
                  
                <div style={{paddingBottom : '20px'}}>
                  <a className="Light" style={{fontSize : '18px', color : '#898989',lineHeight:'1.4'}}>· 해당 옵션을 선택하시면 </a>
                  
                      <a className="Medium" style={{fontSize : '18px', color : '#515151'}}>기본 수수료 및 관납료에 위의 비용이 추가</a>
                      <a className="Light" style={{fontSize : '18px', color : '#898989'}}>됩니다.<br/>
(심사기간 단축
시 총 522,000원 납부)</a>
                  </div>
      </div>
    
    
    
        )
        }
    
const CostProcessContent4 = () => {

return (
    <>
    <div style={{textAlign: 'center', marginBottom: '210px'}}>
        <div
        style = {{width: '800px',
        margin: '52px 0 21.3px',
        borderRadius: '10px',
        boxShadow: '1px 1px 4px 0 rgb(0 0 0 / 10%)',
        backgroundColor: '#fafafa',
         margin : '0 auto'
        }}>

        <div className="" style={{ marginTop: '10px' }}>
            <div style={{ paddingTop: '16px' }}>
                <a className="Medium" style={{ fontSize: '18px' }}>2단계 : 특허청 심사</a>
            </div>
            <div style={{ paddingTop: '11px', paddingBottom: '14px', borderBottom: '1px solid #e0e0e0', marginRight: '20px', marginLeft: '20px', marginBottom: '14px' }}>
                <a className="Medium" style={{ fontSize: '30px', color: '#4a64f5'}}>15~18</a>
                <a className="Medium" style={{ fontSize: '18px', color: '#4a64f5' }}>개월 소요</a>
            </div>
        <a className="Medium" style={{ fontSize: '20px', color: '#000000' }}>“상표 등록 가능 여부를 결정합니다”</a>

        <div style={{paddingTop : '16px', paddingBottom : '15px',lineHeight:'1.4'}}>
                <a className="Light" style={{fontSize : '18px', color : '#898989'}}>· 특허청에서 법률 검토를 진행 후,</a>
                <br/>
                <a className="Medium" style={{fontSize : '18px', color : '#515151'}}>상표 등록 가능 여부를 결정하는 단계</a>
                <a className="Light" style={{fontSize : '18px', color : '#898989'}}>입니다.</a>
        </div>

        </div>
        </div>

        <div style={{width:'800px',margin: '0px  auto'}}>
            <div style={{lineHeight: '6px', color : '#b7b7b7'}}>
            <a>.<br/>.<br/>.<br/>.<br/>.<br/>.<br/>.<br/></a>
        </div>
        </div>

            <div
        style = {{width: '800px',
        margin: '52px 0 21.3px',
        borderRadius: '10px',
        boxShadow: '1px 1px 4px 0 rgb(0 0 0 / 10%)',
        backgroundColor: '#fafafa',
        margin : '0 auto'
        }}>
                <div className="" style={{marginTop : '10px'}}>
                    <div style= {{paddingTop : '20px', paddingBottom : '20px'}}>
                        <a className="Medium" style={{fontSize : '18px'}}>3단계 : 상표 등록</a>
                    </div>
                        <a className="Light" style={{fontSize : '18px'}}>특허청 관납료</a>
                        <a className="Medium" style={{fontSize : '30px', color : '#b7b7b7'}}>&nbsp;&nbsp;&nbsp;&nbsp;210,120</a>
                        <a className="Light" style={{fontSize : '18px'}}>원</a>
                        <div style={{paddingBottom : '20px'}}>
                            <a className="Light" style={{fontSize : '14px', color :  '#898989', marginLeft : '191px', marginTop : '-5px'}}>(10년 기준)</a>
                        </div>
                </div>
                </div>


                
            </div>

            </>
                )
            }

                const CostProcessComponent = () => {
                const [display, setDisplay] = useState('none');


                const modalHandler = () => {
                if(display === 'none'){
                    setDisplay('inline-block')
                }else{
                setDisplay('none')
                }

                }


                return (
                // 마이페이지 헤더
                <>
                <TabletView>
                <div style={{ margin: 'auto', maxWidth: '520px' }}>

{/* topbar*/}
<div style={{
    top: "0px",
    Width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: "100",
    maxWidth: "520px",
    margin: "auto",
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: "1px solid #e0e0e0",
    height: "55px"
}}>

    <Link to='/'>
        <img className="topbar-backbutton" src={backarrow}
            style={{
                objectFit: 'contain',
                width: '14px',
                height: '23px',
                margin: '16px 0 0 20px'
            }}
            alt='<' />
    </Link>

    <Link to='/costprocess'>
    <div className='Bold' style={{ display: 'inline-block', marginTop: '16px', fontSize: '20px', color: 'black' }}>비용 및 절차 안내</div>
    </Link>

    <img src={menu} style={{
        cursor: "pointer",
        display: 'inline-block',
        float: "right",
        width: '22px',
        height: '17px',
        marginTop: "20px",
        marginRight: "20px"
    }}
        onClick={modalHandler} />
</div>

{/* modal */}
<div id="menuContainer"
    style={{
        display: display,
        position: 'fixed',
        top: '0px',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        fontFamily: 'Bold',
        fontSize: '24px',
        maxWidth: '520px',
        margin: 'auto',
        zIndex: '20000'
    }}>


    <div style={{ backgroundColor: '#ffffff', width: '100%' }}>

        <div className="menuicon1">
        <img src={nametecLogo} class="" style={{display: "inline-block",
                                                                                    width: "105px",
                                                                                    transform: "translate(28px)",
                                                                                    marginTop: "12px"}}/>
            <img src={x_btn} id="xbtn" onClick={modalHandler} style={{ cursor: 'pointer', width: '18px', float: 'right', marginRight: '20px', marginTop: '20px' }} />
        </div>
        <div style={{backgroundColor:'#4a64f5', float:"right",width:"60%",height :"20000px"}}>
        <div className="menuicon1" style={{height : '50px'}}>
                    <Link to='/'>
                    <span style={{cursor: 'pointer', marginRight: '20px', marginTop: '40px',float : "right", display:'inline-block', color: "#fff"}}>메인화면</span>
                    </Link>
                    </div>


                        <Link style={{display:'block',marginTop :"40px",marginRight: '20px'}} to='/costprocess'>
                        <span style={{cursor: 'pointer', color: '#fff', display: 'inline-block',float : "right"}}>비용 및 절차 안내</span>
                        </Link>
                            <Link style={{display:'block',marginTop :"90px",marginRight: '20px'}} to='/center'>
                         <span style={{cursor: 'pointer',color :"#fff",display: 'inline-block',float : "right"}}> 고객센터</span>
                         </Link>


                        <Link style={{display:'block',marginTop :"140px",marginRight: '20px'}} to='/'>
                         <span style={{cursor: 'pointer', color: '#fff', display: 'inline-block',float:"right"}}>로그아웃</span>
                         </Link>

        <div className="Medium" style={{marginLeft :"30px",fontSize:"15px",marginTop: "100px",marginRight: '20px',float:"right",color :"#ffffff"}}>Copyright©2021 티타티티</div>


                    <div style={{height: '33px'}}></div>
    </div>
    </div>
</div>

{/* 비용 및 절차 내용 */}
<CostProcessContent />
<CostProcessContent11 />
<Dots/>
<CostProcessContent2/>


</div>
                </TabletView>
                <MobileView>
                    <div style={{ margin: 'auto', maxWidth: '520px' }}>

                        {/* topbar*/}
                        <div style={{
                            top: "0px",
                            Width: "100%",
                            backgroundColor: "#FFFFFF",
                            zIndex: "100",
                            maxWidth: "520px",
                            margin: "auto",
                            display: 'flex',
                            justifyContent: 'space-between',
                            borderBottom: "1px solid #e0e0e0",
                            height: "55px"
                        }}>

                            <Link to='/'>
                                <img className="topbar-backbutton" src={backarrow}
                                    style={{
                                        objectFit: 'contain',
                                        width: '14px',
                                        height: '23px',
                                        margin: '16px 0 0 20px'
                                    }}
                                    alt='<' />
                            </Link>

                            <Link to='/costprocess'>
                            <div className='Bold' style={{ display: 'inline-block', marginTop: '16px', fontSize: '20px', color: 'black' }}>비용 및 절차 안내</div>
                            </Link>

                            <img src={menu} style={{
                                cursor: "pointer",
                                display: 'inline-block',
                                float: "right",
                                width: '22px',
                                height: '17px',
                                marginTop: "20px",
                                marginRight: "20px"
                            }}
                                onClick={modalHandler} />
                        </div>

                        {/* modal */}
                        <div id="menuContainer"
                            style={{
                                display: display,
                                position: 'fixed',
                                top: '0px',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                fontFamily: 'Bold',
                                fontSize: '24px',
                                maxWidth: '520px',
                                margin: 'auto',
                                zIndex: '20000'
                            }}>


                            <div style={{ backgroundColor: '#ffffff', width: '100%' }}>

                                <div className="menuicon1">
                                <img src={nametecLogo} class="" style={{display: "inline-block",
                                                                                                            width: "105px",
                                                                                                            transform: "translate(28px)",
                                                                                                            marginTop: "12px"}}/>
                                    <img src={x_btn} id="xbtn" onClick={modalHandler} style={{ cursor: 'pointer', width: '18px', float: 'right', marginRight: '20px', marginTop: '20px' }} />
                                </div>
                                <div style={{backgroundColor:'#4a64f5', float:"right",width:"60%",height :"20000px"}}>
                                <div className="menuicon1" style={{height : '50px'}}>
                                            <Link to='/'>
                                            <span style={{cursor: 'pointer', marginRight: '20px', marginTop: '40px',float : "right", display:'inline-block', color: "#fff"}}>메인화면</span>
                                            </Link>
                                            </div>


                                                <Link style={{display:'block',marginTop :"40px",marginRight: '20px'}} to='/costprocess'>
                                                <span style={{cursor: 'pointer', color: '#fff', display: 'inline-block',float : "right"}}>비용 및 절차 안내</span>
                                                </Link>
                                                    <Link style={{display:'block',marginTop :"90px",marginRight: '20px'}} to='/center'>
                                                 <span style={{cursor: 'pointer',color :"#fff",display: 'inline-block',float : "right"}}> 고객센터</span>
                                                 </Link>


                                                <Link style={{display:'block',marginTop :"140px",marginRight: '20px'}} to='/'>
                                                 <span style={{cursor: 'pointer', color: '#fff', display: 'inline-block',float:"right"}}>로그아웃</span>
                                                 </Link>

                                <div className="Medium" style={{marginLeft :"30px",fontSize:"15px",marginTop: "100px",marginRight: '20px',float:"right",color :"#ffffff"}}>Copyright©2021 티타티티</div>


                                            <div style={{height: '33px'}}></div>
                            </div>
                            </div>
                        </div>

                        {/* 비용 및 절차 내용 */}
                        <CostProcessContent />
                        <CostProcessContent11 />
                        <Dots/>
                        <CostProcessContent2/>


                    </div>
                    </MobileView>
                    <BrowserView>
                    <BrowserHeader/>
                            <div style={{minWidth:'1000px'}}>
                            <div className="Bold" style={{fontSize:'40px',textAlign:'center',marginTop:'146px'}}>
                            비용 및 절차 안내<a style={{color:'#4a64f5'}}>.</a>
                            </div>
                            <CostProcessContent3 />
                            <CostProcessContent23/>
                            <Dots2 />
                            <CostProcessContent4/>
                            </div>
                            <div id="introFooter" style={{float:'right',backgroundColor: '#f5f6ff',height:'116px',lineHeight:'110px'}}>
                            <Link to="/brandregister">
                            <img src={new_blue}  style={{cursor:'pointer',width : '200px',marginLeft:'20px',marginTop:'27px',float:'right',marginRight:'calc(50% - 685px)'}}/>
                            </Link>
                            <a href='http://pf.kakao.com/_lWuhs/chat'>
                                     <img src={kakaoaa}  style={{cursor:'pointer',width : '283px ',marginTop:'27px',float:'right'}}/>
                                     </a>

                                     </div>
            
                                     <div id="introFooter" style={{float:'right',backgroundColor: '#f5f6ff',height:'116px'}}>


<div style={{marginTop:'34px'}}><a className="Bold1" style={{fontSize:'20px',marginLeft:'calc(50% - 470px)'}}>상표출원은 <a style={{color:'#4a64f5'}}>365일, 24시간</a> 아무때나! 카톡 상담은 평일 09:00~18:00</a></div>
<div style={{marginTop:'5px'}}><a className="Medium1" style={{color:'#898989',fontSize:'16px',marginLeft:'calc(50% - 470px)'}}>*상담 시간 안내 : 점심 시간 12:00~13:00, 공휴일 휴무이니 참고해 주세요 :)</a></div>

<div style={{float:'right',marginTop:'-81px',marginRight:'calc(50% - 468px)'}}>
    <Link to="/brandregister">
    <img src={new_blue}  style={{cursor:'pointer',height:'61px',width : '223px',marginLeft:'320px',marginTop:'28px',marginRight:'calc(50% - 685px)'}}/>
    </Link>
    <a href='http://pf.kakao.com/_lWuhs/chat'>
             <img src={kakaoaa}  style={{cursor:'pointer',height:'61px',width : '283px ',marginTop:'27px',float:'right'}}/>
             </a>
             </div>
             
             </div>
                    </BrowserView>
                </>
                )
                }

                export default CostProcessComponent;