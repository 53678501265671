import React,{Component} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams} from 'react-router-dom';
import BrandRegister from './pages/BrandRegister';
import BrandSearch from './pages/BrandSearch';
import BrandLogoRegister from './pages/BrandLogoRegister';
import BrandCategoryRegister from './pages/BrandCategoryRegister';
import Intro from "./pages/Intro";
import Mypage from './pages/Mypage';
import Member from './pages/Member';
import CostProcessGuide from './pages/CostProcessGuide';
import Center from './pages/Center';
import Infomcheck2 from './pages/Infomcheck2';
import Infomcheck from './pages/Infomcheck';
import Success3 from './pages/Success3';
import Success from './pages/Success';
import Payment from './pages/Payment';
import Payment2 from './pages/Payment2';
import All from './pages/All';
import First from './pages/First';
import Login from './pages/Login';
import Personalpayment from './pages/Personalpayment';
import Test from './pages/Test';
import GoogleTagManager from './components/Gtm';
import Test2 from './pages/Test2';
import Test4 from './pages/Test4';
import Test3 from './pages/Test3';
import Test5 from './pages/Test5';
import Test6 from './pages/Test6';
import Test7 from './pages/Test7';
import Test8 from './pages/Test8';
import TestLogin from './pages/TestLogin';
import Select from './pages/Select';


function App(){
    return(
        <div className='app'>
            <GoogleTagManager gtmId={"GTM-W7CRKH2"}/>
            <Routes>
                <Route exact path="/"><First/></Route>
                <Route exact path="/test"><Test/></Route>
                <Route path="/test2"><Test2></Test2></Route>
                <Route path="/test3"><Test3></Test3></Route>
                <Route path="/test4"><Test4></Test4></Route>
                <Route path="/test5"><Test5></Test5></Route>
                <Route path="/test6"><Test6></Test6></Route>
                <Route path="/test7"><Test7></Test7></Route>
                <Route path="/test8"><Test8></Test8></Route>
                <Route path="/testlogin"><TestLogin></TestLogin></Route>
                <Route path="/brandregister"><BrandRegister></BrandRegister></Route>
                <Route path="/brandSearch"><BrandSearch></BrandSearch></Route>
                <Route path="/brandlogoregister"><BrandLogoRegister></BrandLogoRegister></Route>
                <Route path="/brandcategoryregister"><BrandCategoryRegister></BrandCategoryRegister></Route>
                <Route path="/mypage"><Mypage/></Route>
                <Route path="/member"><Member/></Route>
                <Route path='/costprocess'><CostProcessGuide/></Route>
                <Route path='/center'><Center/></Route>
                <Route path='/success'><Success/></Route>
                <Route path='/infomcheck'><Infomcheck/></Route>
                <Route path='/success3'><Success3/></Route>
                <Route path='/payment'><Payment/></Route>
                <Route path='/payment2'><Payment2/></Route>
                <Route path='/login'><Login/></Route>
                <Route path='/all'><All/></Route>
                <Route path='/Personalpayment'><Personalpayment/></Route>
                <Route path='/Select'><Select/></Route>

                <Route path="/">Not found</Route>
            </Routes>
        </div>
    )
}

ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'))

reportWebVitals();
