
import menu from "../image/menu@3x.png";
import '../text.css'
import React, {useState, useEffect, useRef} from 'react';
import x_btn from '../image/x_menuicon@3x.png';
import backarrow from '../data/backarrow@3x.png'; 
import nametecLogo from "../image/nametecLogo.png";
import BrowserHeader from '../components/BrowserHeader';
import fix from "../image/fix.png";
import dele from "../image/dele.png";
import new_blue from '../image/zx2.png'
import kakaoaa from '../image/kakaoaa.png'
import $ from 'jquery';
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams,useHistory} from 'react-router-dom';
import{
BrowserView,
MobileView,
isBrowser,
IsMobile,
TabletView
} from "react-device-detect";
const Changeinfo = (e) => {
    if (e.target.textContent == '수정'){
        e.target.textContent = '완료'
    }else{
        e.target.textContent = '수정'
    }
    console.log(e.target)
}
const MemberInfoItem = ({title, content, button,id}) => { 
  
    return(
        <div style={{textAlign: 'left', marginBottom: '24px'}} >
                <div className="noto" style={{display: 'inline-block', width: '35%', float: 'left', fontSize:'16px', color: '#898989'}}>{title}</div>
                <div className="noto" id="cusname" style={{display: 'inline-block', fontSize: '16px', color: '#515151'}}>{content}</div>
                <div className="noto" onClick={Changeinfo} style={{display: typeof button === null ?  null : "inline-block" , float: 'right', fontSize:'16px', color: '#4a64f5', cursor : 'pointer'}} >수정</div>
            </div>

    )
}

const MemberInfoItemWithOutCorrection = ({title, content, button}) => {

    return(
        <div style={{textAlign: 'left', marginBottom: '24px'}} >
                <div className="noto" style={{display: 'inline-block', width: '35%', float: 'left', fontSize:'16px', color: '#898989'}}>{title}</div>
                <div className="noto" id="cusname" style={{display: 'inline-block', fontSize: '16px', color: '#515151'}}>{content}</div>
            </div>

    )
}
const MemberTopbar = () => { 
    const [display, setDisplay] = useState('none');
    const [namechange , setNamechange] = useState(true);
    const [emailchange , setEmailchange] = useState(true);
    const [numberchange , setNumberchange] = useState(true);
    const modalHandler = () => { 
        if(display === 'none'){
            setDisplay('inline-block');
        }else{ 
            setDisplay('none');
        }
        return;
    }
    const [nicknametext,setNicknametext] = useState(localStorage.getItem('customername'))
    const [emailtext,setEmailtext] = useState(localStorage.getItem('real_email'))
    const [numbertext,setNumbertext] = useState(localStorage.getItem('customerPhone'))
    const [customername,setCustomername] = useState(localStorage.getItem('customername'))
    const [realemail,setRealemail] = useState(localStorage.getItem('real_email'))
    const [customerPhone,setCustomerPhone] = useState(localStorage.getItem('customerPhone'))
    const Changenickname = () => {
        setNamechange(!namechange)
        $.ajax({
        type: "POST",
        url: 'https://nametecadmin.kr/updatemypagename',
        data: JSON.stringify({
                "email": localStorage.getItem("customeremail"),
                "nameval" : nicknametext
            }),
        processData: false,
        contentType: "application/json",
        async: false,
        success: function (data) {
            if (data.status == 200) {
            localStorage.setItem("customername",nicknametext);
            setCustomername(nicknametext)
            } else {

            }
        }
        ,
        err: function (err) {
            alert(err.status);
        }
    });
    }
    const Changeemail = () => {
        setEmailchange(!emailchange)
        $.ajax({
            type: "POST",
            url: 'https://nametecadmin.kr/updatemypagename1',
            data: JSON.stringify({
                    "email": localStorage.getItem("customeremail"),
                    "nameval" : emailtext
                }),
            processData: false,
            contentType: "application/json",
            async: false,
            success: function (data) {
                if (data.status == 200) {
                localStorage.setItem("real_email",emailtext);
                setRealemail(emailtext)
                } else {
    
                }
            }
            ,
            err: function (err) {
                alert(err.status);
            }
        });
    }
    const Changenumber = () => {
        setNumberchange(!numberchange)
        $.ajax({
            type: "POST",
            url: 'https://nametecadmin.kr/updatemypagenumber',
            data: JSON.stringify({
                    "email": localStorage.getItem("customeremail"),
                    "nameval" : numbertext
                }),
            processData: false,
            contentType: "application/json",
            async: false,
            success: function (data) {
                if (data.status == 200) {
                    localStorage.setItem("customerPhone",numbertext);
                    setCustomerPhone(numbertext)
                } else {
                alert();
    
                }
            }
            ,
            err: function (err) {
                alert(err.status);
            }
        });
    }
    const onChange = (e) => {
        setNicknametext(e.target.value);
      };
      const onChange2 = (e) => {
        setEmailtext(e.target.value);
      };
      const onChange3 = (e) => {
        setNumbertext(e.target.value);
      };

    const history = useHistory();

    const deletecustomer = () => {
        if (window.confirm("정말 탈퇴하시겠습니까?")) {

            let email = localStorage.getItem("customeremail");
            $.ajax({
            type: "POST",
            url: 'https://nametecadmin.kr/deletecustomer',
            data: JSON.stringify({
                    "email": email
                }),
            processData: false,
            contentType: "application/json",
            async: false,
            success: function (data) {
                if (data.status == 200) {
                localStorage.removeItem('customeremail');
                localStorage.removeItem('customername');
                localStorage.removeItem('customerPhone');
                localStorage.removeItem('customerToken');
                localStorage.removeItem('real_email');
                
                history.push("/")
                    alert('탈퇴되었습니다')
                } else {
    
    
                }
            }
            ,
            err: function (err) {
                alert(err.status);
            }
        });}else{
            alert('취소하였습니다')
        }
      
       
            }
    return (
        // 마이페이지 헤더
    <>
    <TabletView>
    <div style={{margin:'auto', maxWidth:'520px'}}>

{/* topbar*/}
<div style={{ top : "0px",
Width: "100%",
backgroundColor : "#FFFFFF",
zIndex :"100",
maxWidth: "520px",
margin: "auto",
display: 'flex', 
justifyContent: 'space-between',
height : "55px" }}>

<Link to='/'>
<img className="topbar-backbutton" src={backarrow} 
style={{objectFit: 'contain', 
width: '14px', 
height: '23px', 
margin: '16px 0 0 20px'}} 
alt='<'/>
</Link>

<div className='noto' style={{display: 'inline-block', marginTop : '18px', fontSize: '20px' ,fontWeight : '500'}}>회원정보</div>

<img src={menu} style={{cursor:"pointer",
   display: 'inline-block',
   float : "right",
   width: '22px',
   height: '17px',
   marginTop : "20px",
   marginRight : "20px"}}
   onClick = {modalHandler}/>              
</div>

{/* menulist */}
<div id="menuContainer" 
style={{display: display, 
position: 'fixed', 
top: '0px', 
width: '100%', 
height: '100%', 
backgroundColor: 'rgba(0, 0, 0, 0.7)', 
fontFamily: 'Bold', 
fontSize: '24px',
maxWidth: '520px', 
margin: 'auto',
zIndex : '20000'}}>


<div style={{ backgroundColor: '#ffffff', width: '100%' }}>

       <div className="menuicon1">
       <img src={nametecLogo} class="" style={{display: "inline-block", width: "105px",transform: "translate(28px)",marginTop: "12px"}}/>
           <img src={x_btn} id="xbtn" onClick={modalHandler} style={{ cursor: 'pointer', width: '18px', float: 'right', marginRight: '20px', marginTop: '20px' }} />
       </div>
       <div style={{backgroundColor:'#4a64f5', float:"right",width:"60%",height :"20000px"}}>
       <div className="menuicon1" style={{height : '50px'}}>
                   <Link to='/'>
                   <span style={{cursor: 'pointer', marginRight: '20px', marginTop: '40px',float : "right", display:'inline-block', color: "#fff"}}>메인화면</span>
                   </Link>
                   </div>


                       <Link style={{display:'block',marginTop :"40px",marginRight: '20px'}} to='/costprocess'>
                       <span style={{cursor: 'pointer', color: '#fff', display: 'inline-block',float : "right"}}>비용 및 절차 안내</span>
                       </Link>
                           <Link style={{display:'block',marginTop :"90px",marginRight: '20px'}} to='/center'>
                       <span style={{cursor: 'pointer',color :"#fff",display: 'inline-block',float : "right"}}> 고객센터</span>
                       </Link>


                       <Link style={{display:'block',marginTop :"140px",marginRight: '20px'}} to='/'>
                       <span style={{cursor: 'pointer', color: '#fff', display: 'inline-block',float:"right"}}>로그아웃</span>
                       </Link>

       <div className="Medium" style={{marginLeft :"30px",fontSize:"15px",marginTop: "100px",marginRight: '20px',float:"right",color :"#ffffff"}}>Copyright©2021 티타티티</div>


                   <div style={{height: '33px'}}></div>
   </div>
   </div>
</div>

{/* 기본회원정보 */}
<div style={{fontSize: '18px', color: '#000000', marginLeft: '5%', marginTop: '50px'}} className="Medium">기본 회원 정보</div>
<div style={{marginLeft: '5%', marginRight: '5%', paddingTop: '30px', fontSize: '16px'}}>
<div style={{textAlign: 'left', marginBottom: '24px'}} >

   <div className="noto" style={{display: 'inline-block', width: '35%', float: 'left', fontSize:'16px', color: '#898989'}}>이름</div>
   {namechange ?
                       <div className="noto" id="cusname" style={{display: 'inline-block', fontSize: '16px', color: '#515151'}}>{customername}</div>:
                       <div style={{borderBottom:'1px solid #4a64f5',width:'45%',display:'inline-block'}}>
                       <input type="text" id="" value={nicknametext} onChange={onChange} style={{border:'none', fontSize:'16px',width:'100%'}}/></div>}
                       {namechange ?
                       <div className="Regular" onClick={() => {setNamechange(!namechange)}} style={{display: typeof button === null ?  null : "inline-block" , float: 'right', fontSize:'16px', color: '#4a64f5', cursor : 'pointer',marginTop:'4px'}} >수정</div>
                   :
                   <div className="Regular" onClick={() => {Changenickname()}} style={{display: typeof button === null ?  null : "inline-block" , float: 'right', fontSize:'16px', color: '#4a64f5', cursor : 'pointer',marginTop:'3px'}} >완료</div>}
                   
   
</div>
   <MemberInfoItemWithOutCorrection title='이메일' content={localStorage.getItem('real_email')} button={null}/>
   <div style={{textAlign: 'left', marginBottom: '24px'}} >

   <div className="noto" style={{display: 'inline-block', width: '35%', float: 'left', fontSize:'16px', color: '#898989'}}>수신 이메일</div>
   {emailchange ?
                       <div className="noto" id="cusname" style={{display: 'inline-block', fontSize: '16px', color: '#515151'}}>{realemail}</div>:
                       <div style={{borderBottom:'1px solid #4a64f5',width:'45%',display:'inline-block'}}>
                       <input type="text" id="" value={emailtext} onChange={onChange2} style={{border:'none', fontSize:'16px',width:'100%'}}/></div>}
                       {emailchange ?
                       <div className="Regular" onClick={() => {setEmailchange(!emailchange)}} style={{display: typeof button === null ?  null : "inline-block" , float: 'right', fontSize:'16px', color: '#4a64f5', cursor : 'pointer',marginTop:'4px'}} >수정</div>
                   :
                   <div className="Regular" onClick={() => {Changeemail()}} style={{display: typeof button === null ?  null : "inline-block" , float: 'right', fontSize:'16px', color: '#4a64f5', cursor : 'pointer',marginTop:'3px'}} >완료</div>}
                   
   
</div>
<div style={{textAlign: 'left', marginBottom: '24px'}} >

<div className="noto" style={{display: 'inline-block', width: '35%', float: 'left', fontSize:'16px', color: '#898989'}}>휴대폰 번호</div>
{numberchange ?
               <div className="noto" id="cusname" style={{display: 'inline-block', fontSize: '16px', color: '#515151'}}>{customerPhone}</div>:
               <div style={{borderBottom:'1px solid #4a64f5',width:'45%',display:'inline-block'}}>
               <input type="text" id="" value={numbertext} onChange={onChange3} style={{border:'none', fontSize:'16px',width:'100%'}}/></div>}
               {numberchange ?
               <div className="Regular" onClick={() => {setNumberchange(!numberchange)}} style={{display: typeof button === null ?  null : "inline-block" , float: 'right', fontSize:'16px', color: '#4a64f5', cursor : 'pointer',marginTop:'4px'}} >수정</div>
           :
           <div className="Regular" onClick={() => {Changenumber()}} style={{display: typeof button === null ?  null : "inline-block" , float: 'right', fontSize:'16px', color: '#4a64f5', cursor : 'pointer',marginTop:'3px'}} >완료</div>}
           

</div>
   <MemberInfoItemWithOutCorrection title='SNS 연결' content='카카오 연결' button={null}/>
   <div onClick={deletecustomer} style={{cursor : 'pointer', textAlign: 'center', marginBottom: '24px', fontSize:'16px', color:'#faedef', border:'1px solid #4a64f5', borderRadius: '10px', background: '#4a64f5', paddingTop : '10px', paddingBottom : '10px'}}>
   회원 탈퇴
   </div>
</div>



</div>
    </TabletView>
    <MobileView>
        <div style={{margin:'auto', maxWidth:'520px'}}>

             {/* topbar*/}
            <div style={{ top : "0px",
            Width: "100%",
            backgroundColor : "#FFFFFF",
            zIndex :"100",
            maxWidth: "520px",
            margin: "auto",
            display: 'flex', 
            justifyContent: 'space-between',
            height : "55px" }}>
            
            <Link to='/'>
            <img className="topbar-backbutton" src={backarrow} 
            style={{objectFit: 'contain', 
            width: '14px', 
            height: '23px', 
            margin: '16px 0 0 20px'}} 
            alt='<'/>
            </Link>

            <div className='noto' style={{display: 'inline-block', marginTop : '18px', fontSize: '20px' ,fontWeight : '500'}}>회원정보</div>

            <img src={menu} style={{cursor:"pointer",
                display: 'inline-block',
                float : "right",
                width: '22px',
                height: '17px',
                marginTop : "20px",
                marginRight : "20px"}}
                onClick = {modalHandler}/>              
            </div>

            {/* menulist */}
             <div id="menuContainer" 
    style={{display: display, 
     position: 'fixed', 
     top: '0px', 
     width: '100%', 
     height: '100%', 
     backgroundColor: 'rgba(0, 0, 0, 0.7)', 
     fontFamily: 'Bold', 
     fontSize: '24px',
     maxWidth: '520px', 
     margin: 'auto',
     zIndex : '20000'}}>
    

      <div style={{ backgroundColor: '#ffffff', width: '100%' }}>

                    <div className="menuicon1">
                    <img src={nametecLogo} class="" style={{display: "inline-block", width: "105px",transform: "translate(28px)",marginTop: "12px"}}/>
                        <img src={x_btn} id="xbtn" onClick={modalHandler} style={{ cursor: 'pointer', width: '18px', float: 'right', marginRight: '20px', marginTop: '20px' }} />
                    </div>
                    <div style={{backgroundColor:'#4a64f5', float:"right",width:"60%",height :"20000px"}}>
                    <div className="menuicon1" style={{height : '50px'}}>
                                <Link to='/'>
                                <span style={{cursor: 'pointer', marginRight: '20px', marginTop: '40px',float : "right", display:'inline-block', color: "#fff"}}>메인화면</span>
                                </Link>
                                </div>


                                    <Link style={{display:'block',marginTop :"40px",marginRight: '20px'}} to='/costprocess'>
                                    <span style={{cursor: 'pointer', color: '#fff', display: 'inline-block',float : "right"}}>비용 및 절차 안내</span>
                                    </Link>
                                        <Link style={{display:'block',marginTop :"90px",marginRight: '20px'}} to='/center'>
                                    <span style={{cursor: 'pointer',color :"#fff",display: 'inline-block',float : "right"}}> 고객센터</span>
                                    </Link>


                                    <Link style={{display:'block',marginTop :"140px",marginRight: '20px'}} to='/'>
                                    <span style={{cursor: 'pointer', color: '#fff', display: 'inline-block',float:"right"}}>로그아웃</span>
                                    </Link>

                    <div className="Medium" style={{marginLeft :"30px",fontSize:"15px",marginTop: "100px",marginRight: '20px',float:"right",color :"#ffffff"}}>Copyright©2021 티타티티</div>


                                <div style={{height: '33px'}}></div>
                </div>
                </div>
            </div>

            {/* 기본회원정보 */}
            <div style={{fontSize: '18px', color: '#000000', marginLeft: '5%', marginTop: '50px'}} className="Medium">기본 회원 정보</div>
            <div style={{marginLeft: '5%', marginRight: '5%', paddingTop: '30px', fontSize: '16px'}}>
            <div style={{textAlign: 'left', marginBottom: '24px'}} >
        
                <div className="noto" style={{display: 'inline-block', width: '35%', float: 'left', fontSize:'16px', color: '#898989'}}>이름</div>
                {namechange ?
                                    <div className="noto" id="cusname" style={{display: 'inline-block', fontSize: '16px', color: '#515151'}}>{customername}</div>:
                                    <div style={{borderBottom:'1px solid #4a64f5',width:'45%',display:'inline-block'}}>
                                    <input type="text" id="" value={nicknametext} onChange={onChange} style={{border:'none', fontSize:'16px',width:'100%'}}/></div>}
                                    {namechange ?
                                    <div className="Regular" onClick={() => {setNamechange(!namechange)}} style={{display: typeof button === null ?  null : "inline-block" , float: 'right', fontSize:'16px', color: '#4a64f5', cursor : 'pointer',marginTop:'4px'}} >수정</div>
                                :
                                <div className="Regular" onClick={() => {Changenickname()}} style={{display: typeof button === null ?  null : "inline-block" , float: 'right', fontSize:'16px', color: '#4a64f5', cursor : 'pointer',marginTop:'3px'}} >완료</div>}
                                
                
            </div>
                <MemberInfoItemWithOutCorrection title='이메일' content={localStorage.getItem('real_email')} button={null}/>
                <div style={{textAlign: 'left', marginBottom: '24px'}} >
        
                <div className="noto" style={{display: 'inline-block', width: '35%', float: 'left', fontSize:'16px', color: '#898989'}}>수신 이메일</div>
                {emailchange ?
                                    <div className="noto" id="cusname" style={{display: 'inline-block', fontSize: '16px', color: '#515151'}}>{realemail}</div>:
                                    <div style={{borderBottom:'1px solid #4a64f5',width:'45%',display:'inline-block'}}>
                                    <input type="text" id="" value={emailtext} onChange={onChange2} style={{border:'none', fontSize:'16px',width:'100%'}}/></div>}
                                    {emailchange ?
                                    <div className="Regular" onClick={() => {setEmailchange(!emailchange)}} style={{display: typeof button === null ?  null : "inline-block" , float: 'right', fontSize:'16px', color: '#4a64f5', cursor : 'pointer',marginTop:'4px'}} >수정</div>
                                :
                                <div className="Regular" onClick={() => {Changeemail()}} style={{display: typeof button === null ?  null : "inline-block" , float: 'right', fontSize:'16px', color: '#4a64f5', cursor : 'pointer',marginTop:'3px'}} >완료</div>}
                                
                
            </div>
            <div style={{textAlign: 'left', marginBottom: '24px'}} >
        
        <div className="noto" style={{display: 'inline-block', width: '35%', float: 'left', fontSize:'16px', color: '#898989'}}>휴대폰 번호</div>
        {numberchange ?
                            <div className="noto" id="cusname" style={{display: 'inline-block', fontSize: '16px', color: '#515151'}}>{customerPhone}</div>:
                            <div style={{borderBottom:'1px solid #4a64f5',width:'45%',display:'inline-block'}}>
                            <input type="text" id="" value={numbertext} onChange={onChange3} style={{border:'none', fontSize:'16px',width:'100%'}}/></div>}
                            {numberchange ?
                            <div className="Regular" onClick={() => {setNumberchange(!numberchange)}} style={{display: typeof button === null ?  null : "inline-block" , float: 'right', fontSize:'16px', color: '#4a64f5', cursor : 'pointer',marginTop:'4px'}} >수정</div>
                        :
                        <div className="Regular" onClick={() => {Changenumber()}} style={{display: typeof button === null ?  null : "inline-block" , float: 'right', fontSize:'16px', color: '#4a64f5', cursor : 'pointer',marginTop:'3px'}} >완료</div>}
                        
        
    </div>
                <MemberInfoItemWithOutCorrection title='SNS 연결' content='카카오 연결' button={null}/>
                <div onClick={deletecustomer} style={{cursor : 'pointer', textAlign: 'center', marginBottom: '24px', fontSize:'16px', color:'#faedef', border:'1px solid #4a64f5', borderRadius: '10px', background: '#4a64f5', paddingTop : '10px', paddingBottom : '10px'}}>
                회원 탈퇴
                </div>
            </div>



        </div>
        </MobileView>
        <BrowserView>
        <BrowserHeader/>
                <div>
                <div className="Bold" style={{fontSize:'40px',textAlign:'center',marginTop:'146px'}}>
                마이페이지
                </div>
                <div className="Medium" style={{marginTop:'25px',fontSize:'18px'}}>
                <div style={{marginLeft : 'calc(50% - 515.5px)'}}>
                        회원 정보 수정
                        </div>
                        <div className='askBox' style={{width:'900px',marginLeft : 'calc(50% - 515.5px)'}}>
                        <div style={{border: '1px solid #e0e0e0' ,paddingTop:'30px',width:'980px',height:'400px',marginTop:'50px',borderRadius:'10px',boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.16)'}}>
                        <div style={{marginBottom:'30px'}}>
                                <div style={{display:'inline-block',width:'16%',textAlign:'center'}}>
                                <a className="Medium" style={{fontSize:"16px",marginLeft:'70px',color:"#898989"}}>이름</a>
                                </div>
                                <div className="Medium" style={{display:'inline-block'}}>
                                    {namechange ?
                                    <a className="Regular" style={{fontSize:"16px",color:"#515151",marginLeft:'70px'}}>{customername}</a>:
                                    <div style={{borderBottom:'1px solid #4a64f5',marginLeft:'70px'}}>
                                    <input type="text" id="" value={nicknametext} onChange={onChange} style={{border:'none', fontSize:'16px',width:'100%'}}/></div>}
                                
                                </div>
                                {namechange ?
                                <a className="Regular" onClick={() => {setNamechange(!namechange)}} style={{fontSize:"16px",color:"#4a64f5",marginRight:'72px',float : 'right',textDecoration:'underline',cursor:'pointer'}}>수정</a>
                                :
                                <a className="Regular" onClick={() => {Changenickname()}} style={{fontSize:"16px",color:"#4a64f5",marginRight:'72px',float : 'right',textDecoration:'underline',cursor:'pointer'}}>완료</a>}
                                </div>
                                <div style={{borderBottom: "1px solid #e0e0e0"}}>
                                </div>

                                <div style={{marginBottom:'30px',marginTop:"33px"}}>

                                <div style={{display:'inline-block',width:'16%',textAlign:'center'}}>
                                <a className="Medium" style={{fontSize:"16px",color:"#898989",marginLeft:'70px'}}>가입 이메일</a>
                                </div>
                                <div style={{display:'inline-block'}}>
                                <a className="Regular" style={{fontSize:"16px",color:"#515151",marginLeft:'70px'}}>{localStorage.getItem('real_email')}</a>
                                </div>
                                </div>
                                <div style={{borderBottom: "1px solid #e0e0e0",}}>
                                    </div>



                                    <div style={{marginBottom:'30px',marginTop:"33px"}}>
                                <div style={{display:'inline-block',width:'16%',textAlign:'center'}}>
                                                                <a className="Medium" style={{fontSize:"16px",color:"#898989",marginLeft:'70px'}}>수신 이메일</a>
                                                                </div>
                                                                <div style={{display:'inline-block'}}>
                                                                {emailchange ?
                                    <a className="Regular" style={{fontSize:"16px",color:"#515151",marginLeft:'70px'}}>{realemail}</a>:
                                    <div style={{borderBottom:'1px solid #4a64f5',marginLeft:'70px'}}>
                                    <input type="text" id="" value={emailtext} onChange={onChange2} style={{border:'none', fontSize:'16px',width:'100%'}}/></div>}
                                                                </div>
                                                                {emailchange ?
                                <a className="Regular" onClick={() => {setEmailchange(!emailchange)}} style={{fontSize:"16px",color:"#4a64f5",marginRight:'72px',float : 'right',textDecoration:'underline',cursor:'pointer'}}>수정</a>
                                :
                                <a className="Regular" onClick={() => {Changeemail()}} style={{fontSize:"16px",color:"#4a64f5",marginRight:'72px',float : 'right',textDecoration:'underline',cursor:'pointer'}}>완료</a>}
                                </div>
                                <div style={{borderBottom: "1px solid #e0e0e0",}}>
                                    </div>



                                    <div style={{marginBottom:'30px',marginTop:"33px"}}>
                                    <div style={{display:'inline-block',width:'16%',textAlign:'center'}}>
                                                                    <a className="Medium" style={{fontSize:"16px",color:"#898989",marginLeft:'70px'}}>휴대폰 번호</a>
                                                                    </div>
                                                                    <div style={{display:'inline-block'}}>
                                                                    {numberchange ?
                                    <a className="Regular" style={{fontSize:"16px",color:"#515151",marginLeft:'70px'}}>{customerPhone}</a>:
                                    <div style={{borderBottom:'1px solid #4a64f5',marginLeft:'70px'}}>
                                    <input type="text" id="" value={numbertext} onChange={onChange3} style={{border:'none', fontSize:'16px',width:'100%'}}/></div>}
                                                                    </div>
                                                                    {numberchange ?
                                <a className="Regular" onClick={() => {setNumberchange(!numberchange)}} style={{fontSize:"16px",color:"#4a64f5",marginRight:'72px',float : 'right',textDecoration:'underline',cursor:'pointer'}}>수정</a>
                                :
                                <a className="Regular" onClick={() => {Changenumber()}} style={{fontSize:"16px",color:"#4a64f5",marginRight:'72px',float : 'right',textDecoration:'underline',cursor:'pointer'}}>완료</a>}
                               
                                    </div>
                                    <div style={{borderBottom: "1px solid #e0e0e0",}}>
                                        </div>



                                        <div style={{marginTop:"33px"}}>
                                    <div style={{display:'inline-block',width:'16%',textAlign:'center'}}>
                                                                    <a className="Medium" style={{fontSize:"16px",color:"#898989",marginLeft:'70px'}}>SNS 연결</a>
                                                                    </div>
                                                                    <div style={{display:'inline-block'}}>
                                                                    <a className="Regular" style={{fontSize:"16px",color:"#515151",marginLeft:'70px'}}>네이버 연결</a>
                                                                    </div>
                                    </div>
                                    </div>

                                </div>
                                <img src={dele} onClick={deletecustomer} style={{width : '120px', float : 'right',cursor:'pointer',marginRight:'calc(50% - 473.5px)',marginTop:'24px'}}/>

                        </div>
                        <div style={{height:'200px'}}>
                        </div>
                        <div id="introFooter" style={{float:'right',backgroundColor: '#f5f6ff',height:'116px'}}>


                                                               <div style={{marginTop:'34px'}}><a className="Bold1" style={{fontSize:'20px',marginLeft:'calc(50% - 470px)'}}>상표출원은 <a style={{color:'#4a64f5'}}>365일, 24시간</a> 아무때나! 카톡 상담은 평일 09:00~18:00</a></div>
                                                               <div style={{marginTop:'5px'}}><a className="Medium1" style={{color:'#898989',fontSize:'16px',marginLeft:'calc(50% - 470px)'}}>*상담 시간 안내 : 점심 시간 12:00~13:00, 공휴일 휴무이니 참고해 주세요 :)</a></div>
                                                              
                                                               <div style={{float:'right',marginTop:'-81px',marginRight:'calc(50% - 468px)'}}>
                                                                   <Link to="/brandregister">
                                                                   <img src={new_blue}  style={{cursor:'pointer',height:'61px',width : '223px',marginLeft:'320px',marginTop:'28px',marginRight:'calc(50% - 685px)'}}/>
                                                                   </Link>
                                                                   <a href='http://pf.kakao.com/_lWuhs/chat'>
                                                                            <img src={kakaoaa}  style={{cursor:'pointer',height:'61px',width : '283px ',marginTop:'27px',float:'right'}}/>
                                                                            </a>
                                                                            </div>
                                                                            
                                                                            </div>
                                                               </div>
        </BrowserView>
     </>
     )
     }



export default MemberTopbar;