import x_round from "../image/x_round@3x.png";
import React ,{Component} from "react";
import { useState } from "react";
import Modal from './Modal';
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams} from 'react-router-dom';
import '../text.css'
import $ from 'jquery';






export default function AlertHeader4(){
    const [display, setDisplay] = useState('none');

    const modalHandler = () => {
        if(display === 'none'){
            setDisplay('inline-block');
        }else{
            setDisplay('none');
        }
        return;
    }
    const x_round_erase = () => {
            $('#atHeader').css("display", "none");
            return;
        }

    return(
        <>
        <div id="atHeader" className="Light" style={{ top : "0px",
            Width: "100%",
            backgroundColor : "#fafafa",
            zIndex :"10010",
            maxWidth: "520px",
            margin: "auto",paddingTop:'22px',paddingBottom:'18px',fontSize:'14px',textAlign:'left',paddingLeft : 'calc(50% - 167px)',lineHeight:'1.45'}}>
            <div style={{display:"inline-block"}}>
            <a className="Medium">로고가 있는 경우 함께 등록하는 것이 좋고, 로고가 없는<br/>경우에도 권리범위 등에서 <a style={{color:'#4a64f5'}}>손해보는 부분은 전혀 없습니다.</a><br/></a>
            <Link to='/costprocess'>
            </Link>

            </div>
            <img src={x_round} onClick={x_round_erase} style={{width: '16px',float:'right',marginRight:'20px',marginTop:'12px'}}/>

        </div>
        <Modal display={display} modalHandler={modalHandler}/>

        </>

    );
}