import React , { useState ,useEffect}from 'react';
import TopbarComponent from '../components/Topbar';
import GuidelineComponent from '../components/Guideline';
import Nextbutton from '../components/Nextbutton';
import '../style.css';
import AlertHeader3 from '../components/AlertHeader3';
import x_round from "../image/x_round@3x.png";
import ask1 from '../image/dsakjldasjkl.png'
import kakaoaa from '../image/kakaoaa.png'
import nextbtn_new from '../image/nextbtn_new.png'
import new_blue from '../image/new_blue.png'
import AlertHeaderPc1 from '../components/AlertHeaderPc1'
import '../text.css';
import $ from 'jquery';
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParam,useHistory} from 'react-router-dom';
import{
BrowserView,
MobileView,
isBrowser,
IsMobile,
TabletView
} from "react-device-detect";
import BrowserHeader from '../components/BrowserHeader';
import ReactPixel from 'react-facebook-pixel';

const PIXEL_ID = '266266868791844';
ReactPixel.init(PIXEL_ID);

function BrandRegister(){
const randomDigitCharactersSpecialCharacterslength = (e) => {
    var text = "";
    var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
    for( var i=0; i < e; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
}
    const [text, setText] = useState('');
    const [nextbtn, setNextbtn] = useState(nextbtn_new);
    const [eventcss, setEventcss] = useState('disabled-link');
    const onChange = (e) => {
        setText(e.target.value);
      };
      const history = useHistory();
    const getBrandName = () => {
        ReactPixel.track('Search');
    let brandIdValue = document.getElementById('text').value;
    let token33 = randomDigitCharactersSpecialCharacterslength(25)
    sessionStorage.setItem('brandname',brandIdValue );
    sessionStorage.setItem('token',token33);
    let customername = localStorage.getItem("customername");
    let customerClass = localStorage.getItem("customerClass");
     let customeremail = localStorage.getItem("customeremail");
    $.ajax({
        type: "POST",
        url: "https://nametecadmin.kr/makeApplication",
        data: JSON.stringify({
                "brandname" :brandIdValue,
                "token": token33,
                "customerClass" : customerClass,
                "customername" : customername,
                "customeremail" : customeremail
            }),
        processData: false,
        contentType: "application/json",
        async: false,
        success: function (data) {
            if (data.status == 200) {
            } else {
                alert('다시 로그인 해 주세요.')
                history.push("/")
            }
        }
        ,
        err: function (err) {
            alert(err.status);
        }
    });
    }
    const NaverLoginStart = () => {
        
        var utmRoute = sessionStorage.getItem('utmRoute');
        if (window.location.href.split('=').length == 2){
            $.ajax({
                url: "https://nametecadmin.kr/getKakaoProfile",
                type: "post",
                data: JSON.stringify({
                "code" : window.location.href.split('=')[1],
                "utmRoute" : utmRoute
                }) ,
                contentType:"application/json; charset=utf-8",
                dataType: "JSON",
                crossDomain: true,
                success: function (data1) {
                    if (data1.status == 200) {
                    var profileData = data1
                    localStorage.setItem('customeremail', profileData['email']);
                    localStorage.setItem('customerPhone', profileData['phonenumber']);
                    localStorage.setItem('customername', profileData['nickname']);
                    localStorage.setItem('customerClass', profileData['class']);
                    localStorage.setItem('real_email', profileData['real_email']);
                    window.location.replace("/brandregister")
                    }
                    if (data1.status == 401) {
                    }
                },
                error: function (request, status, error) {
    // fail handle
    
                }
    
    
            });

        }else if(window.location.href.split('=').length ==1) {

        }else{
        let naver_access_token = window.location.href.split('=')[1].split('&')[0]
        var utmRoute = sessionStorage.getItem('utmRoute');
                    $.ajax({
                        url: 'https://nametecadmin.kr/getNaverProfile',
                        type: "post",
                        data: JSON.stringify({
                            "token": naver_access_token,
                            "utmRoute" : utmRoute
                        }),
                        contentType: "application/json; charset=utf-8",
                        dataType: "JSON",
                        crossDomain: true,
                        success: function (data1) {
                            if (data1.status == 200) {
                                var profileData = data1
                                let timestampSecond = Math.floor(+ new Date() / 1000);
                                localStorage.setItem('timestamp', timestampSecond);
                                localStorage.setItem('customeremail', profileData['email']);
                                localStorage.setItem('customerPhone', profileData['mobile']);
                                localStorage.setItem('customername', profileData['name']);
                                localStorage.setItem('customerToken', naver_access_token);
                                localStorage.setItem('customerClass', profileData['class']);
                                localStorage.setItem('real_email', profileData['real_email']);
                                window.location.replace("/brandregister")
                            }
                            if (data1.status == 400) {
                                alert('ssd');
                            }
                        },
                        error: function (request, status, error) {
    
    
                        }
    
    
                    });
                    }
    
    
            }
    
    useEffect(() => {
            NaverLoginStart()
        }, [])
        const onKeyUP = (event) => {
            if(event.target.value.length>0){
                $('#next_button').css('background-color', '#4a64f5');
                $('#dam').css('color', '#ffffff');
                $('#textcon').css("border-bottom", "solid 2px #4a64f5");
                $('#text').css("opacity", "1");
                $('#text').css("color", "#000");
                setEventcss("");
                setNextbtn(new_blue);
            } else {
                $('#next_button').css('background-color', '#efefef');
                $('#dam').css('color', '#b7b7b7');
                $('#text').css("opacity", "1");
                setEventcss("disabled-link");
                $('#text').css("color", "#b7b7b7");
                $('#textcon').css("border-bottom", "solid 2px #b7b7b7");
                setNextbtn(nextbtn_new);
            }
            if (event.key === 'Enter') {
                getBrandName();
                history.push("/brandlogoregister");
            }
        };
      const onReset = () => {
          setText('');
          $('#textcon').css("border-bottom", "solid 2px #b7b7b7");
          $('#text').css("opacity", "1");
           $('#next_button').css('background-color', '#efefef');
            $('#dam').css('color', '#b7b7b7');
            setEventcss("disabled-link")
            setNextbtn(nextbtn_new)
        };

    return (
    <div>
       
    <MobileView>
        <div style={{margin : 'auto',maxWidth : '520px'}}>
            <TopbarComponent page={'01'} path={'/'}/>
            <AlertHeader3/>
            <div style={{width : '342px',margin : 'auto'}}>
            <GuidelineComponent title1={"등록하실 상표명이"} title2={"무엇인가요"} help2={'?'}/>
            </div>
            
            <div id="textcon" style={{width: 'calc(100% - 40px)', paddingLeft: '3px', margin: '-25px 0px 20% 20px', borderBottom: '2px solid #b7b7b7'}}>
            <input className='brandregister-input' onChange ={onChange} onKeyUp={onKeyUP} type="text" id="text" value={text} placeholder="등록하실 상표명을 입력해주세요" style={{border: 'none', color: '#b7b7b7', opacity: '1'}}/>
            <img onClick={onReset}src={x_round} className="x_btn"  style={{cursor : 'pointer',width: '16px', height: '16px', float:'right', margin: '15px 3px 0px 0px'}}/>
            </div>

            <Link to="/brandlogoregister" className={eventcss} onClick={getBrandName}>
            <div id="next_button" className="noto"
                     style={{cursor:'pointer', position: 'fixed',bottom:'0px',display: 'flex', alignItems: 'center',justifyContent: 'center', backgroundColor: '#efefef'}}>
                    <div id="dam" style={{color:'#b7b7b7'}}> 다음</div>
                </div>
            </Link>
        </div>
    </MobileView>
    <BrowserView>
    <div>
            <BrowserHeader/>
            <AlertHeaderPc1/>
            <div style={{minWidth:'1400px'}}>
       <img src={ask1} style={{marginTop : '123px',marginLeft : 'calc(50% - 602px)',width:'715px'}}/>
       <div className='askBox' style={{width:'900px',marginLeft:'calc(50% - 491px)'}}>

       <div style={{border: '1px solid #e0e0e0' ,width:'980px',height:'180px',marginTop:'50px',borderRadius:'10px',boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.16)'}}>

       <div id="textcon" style={{width: 'calc(85% - 40px)', paddingLeft: '3px',marginTop:'60px', borderBottom: '2px solid #b7b7b7',marginLeft:'10%'}}>

                   <input className='brandregister-input' onChange ={onChange} onKeyUp={onKeyUP} type="text" id="text" value={text} placeholder="등록하실 상표명을 입력해주세요" style={{border: 'none', color: '#b7b7b7', opacity: '1'}}/>
                   <img onClick={onReset}src={x_round} className="x_btn"  style={{cursor : 'pointer',width: '20px', height: '20px', float:'right', margin: '15px 3px 0px 0px'}}/>
                   </div>
       </div>
       </div>
       </div>
       <div style={{height:'200px'}}>

       </div>
       <div id="introFooter" style={{float:'right',backgroundColor: '#f5f6ff',height:'116px',lineHeight:'110px'}}>
        <div style={{width:'982px',marginLeft:'calc(50% - 491px)'}}>
        <Link to="/brandlogoregister" className={eventcss} onClick={getBrandName}>
       <img src={nextbtn} style={{cursor:'pointer',width : '200px',marginLeft:'20px',marginTop:'27px',float:'right'}}/>
       </Link>
       <a href='http://pf.kakao.com/_lWuhs/chat'>
                <img src={kakaoaa} onClick="" style={{cursor:'pointer',width : '283px ',marginTop:'27px',float:'right'}}/>
                </a>
        </div>
       
                </div>
     </div>
    </BrowserView>
        </div>
    )

}

export default BrandRegister;

