import x_round from "../image/x_round@3x.png";
import React ,{Component} from "react";
import { useState } from "react";
import Modal from './Modal';
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams} from 'react-router-dom';
import '../text.css'
import $ from 'jquery';






export default function AlertHeader3(){
    const [display, setDisplay] = useState('none');

    const modalHandler = () => {
        if(display === 'none'){
            setDisplay('inline-block');
        }else{
            setDisplay('none');
        }
        return;
    }
    const x_round_erase = () => {
            $('#atHeader').css("display", "none");
            return;
        }

    return(
        <>
        <div id="atHeader" className="Light" style={{ top : "0px",
            Width: "100%",
            backgroundColor : "#fafafa",
            zIndex :"10010",
            maxWidth: "520px",
            margin: "auto",paddingTop:'22px',paddingBottom:'18px',fontSize:'14px',textAlign:'left',paddingLeft : 'calc(50% - 167px)',lineHeight:'1.45'}}>
            <div style={{display:"inline-block"}}>
            <a className="Medium">지금 입력하시는 내용은 진단을 위한 참고 입력 값이며,<br/> <a style={{color:'#4a64f5'}}>출원 접수 후 변리사 상담을 통해 변경 가능</a>합니다 :)<br/></a>
            <Link to='/costprocess'>
            </Link>

            </div>
            <img src={x_round} onClick={x_round_erase} style={{width: '16px',float:'right',marginRight:'20px',marginTop:'12px'}}/>

        </div>
        <Modal display={display} modalHandler={modalHandler}/>

        </>

    );
}