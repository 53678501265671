import React, {useState, useEffect} from 'react';
import TopbarComponentWithoutPage from '../components/Topbarwithoutpage'
import GuidelineComponent from '../components/Guideline';
import Nextbutton from '../components/Nextbutton';
import GuidelineComponent1 from '../components/Guideline5';
import '../style.css';
import x_round from '../image/x_round.png'
import ask12 from '../image/zqaq.png'
import $ from 'jquery';
import kakaoaa from '../image/kakaoaa.png'
import nextbtn_new from '../image/nextbtn_new.png'
import new_blue from '../image/new_blue.png'
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams} from 'react-router-dom';
import{
BrowserView,
MobileView,
isBrowser,
IsMobile,
TabletView
} from "react-device-detect";

import BrowserHeader from '../components/BrowserHeader';

function Test6(){
const [text, setText] = useState('');
const [text2, setText2] = useState('');
const [phone, setPhone] = useState(0);
const [phone2, setPhone2] = useState();
const [email, setEmail] = useState(0);
const [email2, setEmail2] = useState('');

const [eventcss, setEventcss] = useState('disabled-link');
const [nextbtn, setNextbtn] = useState(nextbtn_new);
useEffect(()=> {
btnCheck()
    })
    var replaceNotInt = /[^0-9]/gi;

        $(document).ready(function(){

            $("#tela").on("focusout", function() {
                var x = $(this).val();
                if (x.length > 0) {
                    if (x.match(replaceNotInt)) {
                       x = x.replace(replaceNotInt, "");
                    }
                    $(this).val(x);
                }
            }).on("keyup", function() {
                $(this).val($(this).val().replace(replaceNotInt, ""));
            });

        });

const numberCheckFun = (e) => {
 var text1 = e.target.value;
setText(e.target.value);
var regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
if (regPhone.test(text1) === true) {
setPhone(1)
$('#textcoco').css("border-bottom", "solid 2px #4a64f5");
}else{
$('#textcoco').css("border-bottom", "solid 2px red");
setPhone(0)
}
}
const emailCheckFun = (e) => {
var emailVal = e.target.value;
setText2(e.target.value);
var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
if (re.test(emailVal)===false) {
setEmail(0)
$('#textcoco2').css("border-bottom", "solid 2px red");
}else {
setEmail(1)
$('#textcoco2').css("border-bottom", "solid 2px #4a64f5");
 }
}
const onReset1 = () => {
setText('');
          $('#textcoco').css("border-bottom", "solid 2px #b7b7b7");
          $('#tela').css("opacity", "0.4");
           $('#next_button').css('background-color', '#efefef');
            $('#dam').css('color', '#b7b7b7');
            setEventcss("disabled-link")
            setNextbtn(nextbtn_new)
            setPhone(0)
        };
const onReset2 = () => {
setText2('');
          $('#textcoco2').css("border-bottom", "solid 2px #b7b7b7");
          $('#tela2').css("opacity", "0.4");
           $('#next_button').css('background-color', '#efefef');
            $('#dam').css('color', '#b7b7b7');
            setEventcss("disabled-link")
            setNextbtn(nextbtn_new)
            setEmail(0)
        };
const btnCheck = () => {
            if(phone===1 && email===1){
                          $('#next_button').css('background-color', '#4a64f5');
                          setEventcss("")
                           $('#dam').css('color', '#ffffff');
                           setNextbtn(new_blue)
            }
            else {
                    $('#next_button').css('background-color', '#efefef');
                        $('#dam').css('color', '#b7b7b7');
                        setEventcss("disabled-link")
                        setNextbtn(nextbtn_new)

            }
    }
    useEffect(() => {
          setText(localStorage.getItem('customerPhone'));
          setText2(localStorage.getItem('real_email'));
          setEmail(1)
          $('#textcoco2').css("border-bottom", "solid 2px #4a64f5");
          setPhone(1)
          $('#textcoco').css("border-bottom", "solid 2px #4a64f5");
          $('#tela').css("opacity", "1");
          $('#tela2').css("opacity", "1");
        },[]);


        const success3 = () => {
            var checkemail = text2
            var checknumber = text
            var email123 = localStorage.getItem("customeremail");
            $.ajax({
                type: "POST",
                url: "https://nametecadmin.kr/informupdate",
                data: JSON.stringify({
                        "real_email": checkemail,
                        "customer_phone_number" : checknumber,
                        "email" :email123
                    }),
                processData: false,
                contentType: "application/json",
                async: false,
                success: function (data) {
                    if (data.status == 200) {
                        localStorage.setItem("real_email" , checkemail);
                        localStorage.setItem("customerPhone" , checknumber);
                    } else {


                    }
                }
                ,
                err: function (err) {
                    alert(err.status);
                }
            });
              };
    return (
    <div>
        <TabletView>
        <div style={{margin : 'auto',maxWidth : '520px'}}>
            <TopbarComponentWithoutPage path={'/success'}/>
            <div style={{width : '342px',margin : 'auto'}}>
            <GuidelineComponent1 title1="연락처를 알려주세요" help={"결제 후, 상표 출원 진행사항을 알려드리기 위해"} help2={'정확한 전화번호 및 이메일이 꼭 필요합니다.'}>
                    </GuidelineComponent1>
                    </div>
            <div id="textcoco" className=""  style={{width: 'calc(100% - 40px)', paddingLeft:'3px',margin: '24px 0 0 20px',  borderBottom: 'solid 2px #b7b7b7' }}>
                    <input id="tela" onChange={numberCheckFun} type="number" maxLength="11" name="" className="informchecktext14 Medium" placeholder="전화번호" style={{border: 'none', color : '#000000',opacity : "0.4" }} value={text}/>

                </div>
            <div id="textcoco2" className=""  style={{width: 'calc(100% - 40px)', paddingLeft:'3px',margin: '24px 0 0 20px',  borderBottom: 'solid 2px #b7b7b7' }}>
                                <input onChange={emailCheckFun} id="tela2" type="text"  name="" className="informchecktext14 Medium" placeholder="이메일" style={{border: 'none', color : '#000000',opacity : "0.4"}} value={text2}/>
                            </div>
            <Link to="/test7" className={eventcss} onClick={() => success3()}>
            <div id="next_button" className="noto"
                                 style={{cursor:'pointer', position: 'fixed',bottom:'0px',display: 'flex', alignItems: 'center',justifyContent: 'center', backgroundColor: '#efefef'}}>
                                <div id="dam" style={{color:'#b7b7b7'}}> 다음</div>
                            </div>
            </Link>
        </div>
        </TabletView>
    <MobileView>
        <div style={{margin : 'auto',maxWidth : '520px'}}>
            <TopbarComponentWithoutPage path={'/success'}/>
            <div style={{width : '342px',margin : 'auto'}}>
            <GuidelineComponent1 title1="연락처를 알려주세요" help={"결제 후, 상표 출원 진행사항을 알려드리기 위해"} help2={'정확한 전화번호 및 이메일이 꼭 필요합니다.'}>
                    </GuidelineComponent1>
                    </div>
            <div id="textcoco" className=""  style={{width: 'calc(100% - 40px)', paddingLeft:'3px',margin: '24px 0 0 20px',  borderBottom: 'solid 2px #b7b7b7' }}>
                    <input id="tela" onChange={numberCheckFun} type="number" maxLength="11" name="" className="informchecktext14 Medium" placeholder="전화번호" style={{border: 'none', color : '#000000',opacity : "0.4" }} value={text}/>

                </div>
            <div id="textcoco2" className=""  style={{width: 'calc(100% - 40px)', paddingLeft:'3px',margin: '24px 0 0 20px',  borderBottom: 'solid 2px #b7b7b7' }}>
                                <input onChange={emailCheckFun} id="tela2" type="text"  name="" className="informchecktext14 Medium" placeholder="이메일" style={{border: 'none', color : '#000000',opacity : "0.4"}} value={text2}/>
                            </div>
            <Link to="/test7" className={eventcss} onClick={() => success3()}>
            <div id="next_button" className="noto"
                                 style={{cursor:'pointer', position: 'fixed',bottom:'0px',display: 'flex', alignItems: 'center',justifyContent: 'center', backgroundColor: '#efefef'}}>
                                <div id="dam" style={{color:'#b7b7b7'}}> 다음</div>
                            </div>
            </Link>
        </div>
        </MobileView>
        <BrowserView>
        <div style={{margin : "0 auto"}}>
                    <BrowserHeader/>
                    <div style={{width:'1400px',paddingLeft:"calc(50% - 700px)"}}>
               <img src={ask12} style={{marginTop : '123px',marginLeft : 'calc(50% - 602px)',width:'850px'}}/>
               <div className='askBox' style={{minWidth:'900px',border: '1px solid #e0e0e0' ,width:'980px',height:'200px',marginLeft : 'calc(50% - 490px)',marginTop:'50px',borderRadius:'10px',boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.16)'}}>
               <div id="textcoco" className=""  style={{width: 'calc(100% - 120px)', paddingLeft:'3px',marginTop:'30px',  borderBottom: 'solid 2px #b7b7b7',marginLeft:'60px' }}>
                                   <input id="tela" onChange={numberCheckFun} type="tel" maxLength="11" name="a" value={text} className="informchecktext Medium" placeholder="전화번호" style={{border: 'none', color : '#000000',opacity : "0.4" }} value={text}/>
                                   <img onClick={onReset1} src={x_round} className="x_btn"  style={{cursor : 'pointer',width: '20px', height: '20px', float:'right', margin: '15px 3px 0px 0px'}}/>
                               </div>
                           <div id="textcoco2" className=""  style={{width: 'calc(100% - 120px)', paddingLeft:'3px',marginTop:'30px',  borderBottom: 'solid 2px #b7b7b7',marginLeft:'60px' }}>
                                               <input onChange={emailCheckFun} id="tela2" type="text"  name="q" value={text2} className="informchecktext Medium" placeholder="이메일" style={{border: 'none', color : '#000000',opacity : "0.4",}} value={text2}/>
                                           <img onClick={onReset2} src={x_round} className="x_btn"  style={{cursor : 'pointer',width: '20px', height: '20px', float:'right', margin: '15px 3px 0px 0px'}}/>
                                           </div>
               </div>

</div>
               <div id="introFooter" style={{float:'right',backgroundColor: '#f5f6ff',height:'116px',lineHeight:'110px'}}>
               <Link to="/test7" className={eventcss} onClick={() => success3()}>
               <img src={nextbtn} style={{cursor:'pointer',width : '200px',marginLeft:'20px',marginTop:'27px',float:'right',marginRight:'calc(50% - 485px)'}}/>
               </Link>
               <a href='http://pf.kakao.com/_lWuhs/chat'>
                        <img src={kakaoaa} onClick="" style={{cursor:'pointer',width : '283px ',marginTop:'27px',float:'right'}}/>
                        </a>
                        </div>
             </div>
        </BrowserView>
        </div>
    )

}

export default Test6;


$(document).ready(function () {

    $('#tela').focus(function () {
        $('#textcoco').css("border-bottom", "solid 2px #4a64f5");
        $('#tela').css("color", "#000000");
        $('#tela').css("opacity", "1");
          $('html,body').animate({
        scrollTop: 0
   });
    });
    $('#tela').blur(function () {
        $('#inputdiv1').css("border-bottom", "solid 2px #b7b7b7");
        $('#tela').css("color", "#000000");
        $('#tela').css("opacity", "1");
    });
        $('#tela').blur(function () {
            var flag = true;
            flag = $(this).val().length > 0 ? false : true;
            if (flag) {
                $('#textcoco').css("border-bottom", "solid 2px #b7b7b7");
                $('#tela').css("opacity", "0.4");
            } else {
            }
        });



    $('#tela2').focus(function () {
            $('#textcoco2').css("border-bottom", "solid 2px #4a64f5");
            $('#tela2').css("color", "#000000");
            $('#tela2').css("opacity", "1");
              $('html,body').animate({
            scrollTop: 0
       });
        });
        $('#tela2').blur(function () {
            $('#inputdiv1').css("border-bottom", "solid 2px #b7b7b7");
            $('#tela2').css("color", "#000000");
            $('#tela2').css("opacity", "1");
        });
            $('#tela2').blur(function () {
                var flag = true;
                flag = $(this).val().length > 0 ? false : true;
                if (flag) {
                    $('#textcoco2').css("border-bottom", "solid 2px #b7b7b7");
                    $('#tela2').css("opacity", "0.4");
                } else {
                }
            });

    });