import React , { useState ,useEffect}from 'react';
import TopbarComponent from '../components/Topbar';
import GuidelineComponent from '../components/Guideline6';
import Nextbutton from '../components/Nextbutton';
import '../style.css';
import AlertHeader3 from '../components/AlertHeader3';
import x_round from '../image/pc_x_round.png'
import loadingdot from '../image/loadingdot.gif'
import ask1 from '../image/brandsearchlogo.png'
import mypagearrow from '../image/mypagearrow.png';
import kakaoaa from '../image/kakaoaa.png'
import nextbtn_new from '../image/pc_start_button.jpg'
import load_mo from '../image/load_mo.png'
import new_blue from '../image/new_blue.png'
import PC_categorization from '../image/PC_categorization.png'
import search_PC from '../image/search_PC.png'
import '../text.css';
import $ from 'jquery';
import mo_x_round from '../image/mo_x_round.png'
import mo_q1_1 from '../image/mo_q&a1-1.png'
import mo_q1_2 from '../image/mo_q&a1-2.png'
import mo_q2_1 from '../image/mo_q&a2-1.png'
import mo_q2_2 from '../image/mo_q&a2-2.png'
import mo_q3_1 from '../image/mo_q&a3-1.png'
import mo_q3_2 from '../image/mo_q&a3-2.png'
import L from '../image/L.png'
import R from '../image/R.png'

import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParam,useHistory} from 'react-router-dom';
import{
BrowserView,
MobileView,
isBrowser,
IsMobile,
TabletView
} from "react-device-detect";
import BrowserHeader from '../components/BrowserHeader';
import BrowserHeader2 from '../components/BrowserHeader2';
import ReactPixel from 'react-facebook-pixel';

const PIXEL_ID = '266266868791844';
ReactPixel.init(PIXEL_ID);

function BrandSearch(){
  let customeremail1 = localStorage.getItem('customeremail');
    const [noData, setNoData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);
    const [data1, setData1] = useState([]);
    const [brandName, setBrandName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
  const [pageCount ,setPageCount] = useState(1);
  const [image1Src, setImage1Src] = useState(mo_q1_1);
  const [image2Src, setImage2Src] = useState(mo_q2_1);
  const [image3Src, setImage3Src] = useState(mo_q3_1);

  const toggleImage = (currentSrc, originalSrc, newSrc) => {
    return currentSrc === originalSrc ? newSrc : originalSrc;
  };
  const handleSearch = async (page) => {
    setIsLoading(true);
    setNoData(false);
    setHasSearched(false)
    $.ajax({
        
        type: "POST",
        url: "https://nametecadmin.kr/brandsearch",
        data: JSON.stringify({
                "brandname" :brandName,
                "page" : page
            }),
        processData: false,
        contentType: "application/json",
        async: true,
        success: function (data) {
            if (data.status == 200) {
                setNoData(true);
                setIsLoading(false); 
                
            } else{
                const items = Array.isArray(data.body.items.item) ? data.body.items.item : [data.body.items.item];
                setData1(items);
                const pagecal = parseInt(data.count.totalCount, 10);
                let pageresult  = 0;
                
                pageresult = pagecal / 6;
                setPageCount( Math.ceil(pageresult));
                setHasSearched(true);
                setIsLoading(false); 
            }
                
            
        }
        ,
        err: function (err) {
            alert(err.status);
            setIsLoading(false); 
        }
    });
  };
  const handleSearch2 = async () => {
    setNoData(false);
    setIsLoading(true);
    setHasSearched(false)
    setCurrentPage(1);
    $.ajax({
        type: "POST",
        url: "https://nametecadmin.kr/brandsearch",
        data: JSON.stringify({
                "brandname" :brandName,
                "page" : 1
            }),
        processData: false,
        contentType: "application/json",
        async: true,
        success: function (data) {
            if (data.status == 200) {
                setNoData(true);
                setIsLoading(false); 
            } else{
                const items = Array.isArray(data.body.items.item) ? data.body.items.item : [data.body.items.item];
                setData1(items);
            const pagecal = parseInt(data.count.totalCount, 10);
            let pageresult  = 0;
            
            pageresult = pagecal / 6;
            setPageCount( Math.ceil(pageresult));
            renderPagination()
            setHasSearched(true);
            setIsLoading(false); 


            }
            
        }
        ,
        err: function (err) {
            setIsLoading(false); 
            alert(err.status);
        }
    });
  };
  const handlePageClick = (page) => {
    setCurrentPage(page);
    handleSearch(page)
  };
  const renderPagination = () => {
    const pages = [];
    const totalPageNumbers = 9; // 화면에 표시할 페이지 번호 수
    const leftSiblingIndex = Math.max(currentPage - 3, 1);
    const rightSiblingIndex = Math.min(currentPage + 3, pageCount);
  
    if (pageCount <= totalPageNumbers) {
        // 페이지 수가 totalPageNumbers 이하일 경우 모든 페이지 표시
        for (let i = 1; i <= pageCount; i++) {
            pages.push(
                <span
                    key={i}
                    onClick={() => handlePageClick(i)}
                    style={{ margin: '0 5px', cursor: 'pointer', color: i === currentPage ? '#4a64f5' : 'black' }}
                >
                    {i}
                </span>
            );
        }
    } else {
        // 현재 페이지가 처음 5페이지 이내일 경우
        if (currentPage <= 5) {
            for (let i = 1; i <= 9; i++) {
                pages.push(
                    <span
                        key={i}
                        onClick={() => handlePageClick(i)}
                        style={{ margin: '0 5px', cursor: 'pointer', color: i === currentPage ? '#4a64f5' : 'black' }}
                    >
                        {i}
                    </span>
                );
            }
            pages.push(<span key="right-dots">...</span>);
            pages.push(
                <span
                    key={pageCount}
                    onClick={() => handlePageClick(pageCount)}
                    style={{ margin: '0 5px', cursor: 'pointer' }}
                >
                    {pageCount}
                </span>
            );
        } 
        // 현재 페이지가 끝에서 5페이지 이내일 경우
        else if (currentPage > pageCount - 5) {
            pages.push(
                <span
                    key={1}
                    onClick={() => handlePageClick(1)}
                    style={{ margin: '0 5px', cursor: 'pointer' }}
                >
                    1
                </span>
            );
            pages.push(<span key="left-dots">...</span>);
            for (let i = pageCount - 8; i <= pageCount; i++) {
                pages.push(
                    <span
                        key={i}
                        onClick={() => handlePageClick(i)}
                        style={{ margin: '0 5px', cursor: 'pointer', color: i === currentPage ? '#4a64f5' : 'black' }}
                    >
                        {i}
                    </span>
                );
            }
        } 
        // 현재 페이지가 중간에 위치할 경우
        else {
            pages.push(
                <span
                    key={1}
                    onClick={() => handlePageClick(1)}
                    style={{ margin: '0 5px', cursor: 'pointer' }}
                >
                    1
                </span>
            );
            pages.push(<span key="left-dots">...</span>);
            for (let i = leftSiblingIndex; i <= rightSiblingIndex; i++) {
                pages.push(
                    <span
                        key={i}
                        onClick={() => handlePageClick(i)}
                        style={{ margin: '0 5px', cursor: 'pointer', color: i === currentPage ? '#4a64f5' : 'black' }}
                    >
                        {i}
                    </span>
                );
            }
            pages.push(<span key="right-dots">...</span>);
            pages.push(
                <span
                    key={pageCount}
                    onClick={() => handlePageClick(pageCount)}
                    style={{ margin: '0 5px', cursor: 'pointer' }}
                >
                    {pageCount}
                </span>
            );
        }
    }
    return pages;
};
const randomDigitCharactersSpecialCharacterslength = (e) => {
    var text = "";
    var possible = "abcdefghijklmnopqrstuvwxyz0123456789";
    for( var i=0; i < e; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
}
    const [text, setText] = useState('');
    const [nextbtn, setNextbtn] = useState(nextbtn_new);
    const [eventcss, setEventcss] = useState('');
    const onChange = (e) => {
        setText(e.target.value);
      };
      const history = useHistory();
    
   
    
   
    const onKeyUP = (event) => {
            if(event.target.value.length>0){
                          $('#next_button').css('background-color', '#4a64f5');
                           $('#dam').css('color', '#ffffff');
                           $('#textcon').css("border-bottom", "solid 2px #4a64f5");
                                   $('#text1').css("color", "#000000");
                                   $('#text1').css("opacity", "1");
                                   setEventcss("")
            }
            else {
                    $('#next_button').css('background-color', '#4a64f5');
                    $('#textcon').css("border-bottom", "solid 2px #b7b7b7");
                        $('#dam').css('color', '#ffffff');
                        $('#text1').css("opacity", "1");
                        setEventcss("")
            }
            if (event.key === 'Enter') {
              handleSearch2();
            }
    }
    const onKeyUP2 = (event) => {
                    $('#next_button').css('background-color', '#4a64f5');
                     $('#dam').css('color', '#ffffff');
                     $('#textcon').css("border-bottom", "solid 2px #4a64f5");
                             $('#text1').css("color", "#000000");
                             $('#text1').css("opacity", "1");
                             setEventcss("")
}
      const onReset = () => {
        setData1([]);
          setBrandName('');
          $('#textcon').css("border-bottom", "solid 2px #b7b7b7");
          $('#text1').css("opacity", "1");
           $('#next_button').css('background-color', '#4a64f5');
            $('#dam').css('color', '#ffffff');
            setEventcss("")
            setHasSearched(false);
            setNoData(false);
        };

    return (
    <div>
        <TabletView>
        <div style={{margin : 'auto',maxWidth : '520px'}}>
            <TopbarComponent page={'01'} path={'/'}/>
            <AlertHeader3/>
            <div style={{width : '342px',margin : 'auto'}}>
            <GuidelineComponent title1={"등록하실 상표명이"} title2={"무엇인가요?"}/>
            </div>
            
            <div id="textcon" style={{width: 'calc(100% - 40px)', paddingLeft: '3px', margin: '-25px 0px 20% 20px', borderBottom: '2px solid #b7b7b7'}}>
            <input className='brandregister-input' onChange ={onChange} onKeyUp={onKeyUP} onClick={onKeyUP} type="text" id="text1" value={text} placeholder="등록하실 상표명을 입력해주세요" style={{border: 'none', color: 'transparent', opacity: '1'}}/>
            <img onClick={onReset}src={x_round} className="x_btn"  style={{cursor : 'pointer',width: '16px', height: '16px', float:'right', margin: '15px 3px 0px 0px'}}/>
            </div>

            <Link to="/test2" >
            <div id="next_button" className="noto"
                     style={{cursor:'pointer', position: 'fixed',bottom:'0px',display: 'flex', alignItems: 'center',justifyContent: 'center', backgroundColor: '#efefef'}}>
                    <div id="dam" style={{color:'#b7b7b7'}}> 다음</div>
                </div>
            </Link>
        </div>
        </TabletView>
    <MobileView>
        <div style={{margin : 'auto',maxWidth : '520px'}}>
            <div style={{width : '342px',margin : 'auto'}}>
            <GuidelineComponent title1={"유사/동일 상표명을"} title2={"무료로 조회해보세요"} help={"비슷한 상표가 있어도 일단 신청해주시면"} help2={"등록 가능성을 별도로 검토해드려요 :)"}/>
            </div>

            
            <div className='askBox'>

    <div style={{ margin: '0 auto', border: '1px solid #e0e0e0', width: '335px', height: '59px', marginTop: '10px', borderRadius: '137px', boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.16)', display: 'flex', alignItems: 'center' }}>

        <div id="textcon" style={{ flex: 1, paddingLeft: '3px', borderBottom: '1.5px solid #b7b7b7', marginLeft: '7%' ,height:"20px",marginTop:'7px',marginRight:'14px',height:'24px'}}>

            <input className='brandregister-input' onChange={(e) => setBrandName(e.target.value)} onKeyUp={onKeyUP} type="text" id="text1" value={brandName} placeholder="등록하실 상표명을 입력해주세요." style={{width: '100%', border: 'none', opacity: '1', fontSize: '16px', height: '17px' }} />
           
        </div>
        <img onClick={onReset} src={mo_x_round} className="x_btn" style={{ cursor: 'pointer', width: '16px', height: '16px',marginLeft:'249px',position:"absolute" }} />
        <img onClick={handleSearch2} src={search_PC} className="x_btn" style={{ cursor: 'pointer', width: '44px', height: '44px', margin: '0 5px' }} />
    </div>
</div>
       <div style={{ display: 'flex', flexWrap: 'wrap',width:'335px',marginLeft:'calc(50% - 167.5px)',marginTop:'25px',marginBottom:'20px' }}>
       {isLoading ? (
    <div
        id="loadingDiv"
        style={{ margin: 'auto', maxWidth: '520px', fontFamily: "'Noto Sans KR', sans-serif" }}
    >
        <img
            src={load_mo}
            style={{ width: '132px', marginLeft: 'calc(50% - 66px)' }}
        />
        <div>
            <img
                src={loadingdot}
                style={{ width: '50px', marginTop: '16px', marginLeft: 'calc(50% - 25px)' }}
            />
        </div>
    </div>
) : noData ? (
    <div className='Bold' style={{ textAlign: 'center', margin :'0 auto', fontSize: '16px',lineHeight:'1.7' }}>
        <a style={{fontSize:'18px'}}>유사 / 동일 상표명이 검색되지 않습니다 :)<br/><a style={{color : '#4a64f5'}}>지금 바로 등록</a>해서 
        <a style={{color : '#4a64f5'}}>상표를 선점</a>하세요!</a>
    </div>
) : (
  data1.map(item => {
      const isLongTitle = item.title.length > 17;
      
      // 스타일 및 텍스트 조건 설정
      let statusStyle = { backgroundColor: '#e0e0e0', fontSize: '12px', color: '#515151', textAlign: 'center', paddingTop: '4px', paddingBottom: '4px' };
      let statusText = item.applicationStatus;

      if (item.applicationStatus === '등록') {
          statusStyle = { backgroundColor: '#4a64f5', fontSize: '12px', color: '#fff', textAlign: 'center', paddingTop: '4px', paddingBottom: '4px' };
          statusText = '등록 완료';
      } else if (item.applicationStatus === '출원') {
          statusStyle = { backgroundColor: '#edeffe', fontSize: '12px', color: '#515151', textAlign: 'center', paddingTop: '4px', paddingBottom: '4px'};
          statusText = '출원 진행중';
      } else if (item.applicationStatus === '공고') {
          statusStyle = { backgroundColor: '#8496fc', fontSize: '12px', color: '#fff', textAlign: 'center', paddingTop: '4px', paddingBottom: '4px' };
          statusText = '출원 공고중';
      }

      return (
          <div key={item.applicationNumber} style={{ boxShadow: '1px 1px 8px 0 rgba(0, 0, 0, 0.14)', backgroundColor: '#fafafa', borderRadius: '10px', marginTop:'10px',marginBottom:'10px', padding: '10px', width: '335px', minHeight: '120px', display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', border: '1px solid #515151', height: '102.7px' }}>
                  <img src={item.drawing} alt={item.title} style={{ width: '100px', height: '80px' }} />
                  <p className='Regular' style={statusStyle}>{statusText}</p>
              </div>
              <div style={{ marginLeft: '20px', marginTop: isLongTitle ? '0' : '0' }}>
                  <p className='Regular' style={{ fontSize: '12px', color: '#4a64f5' }}>상표명</p>
                  <p className='Medium' style={{ 
                      fontSize: '16px', 
                      marginTop: '5px', 
                      // overflow: 'hidden', 
                      // textOverflow: 'ellipsis', 
                      // display: '-webkit-box', 
                      WebkitLineClamp: 2, 
                      WebkitBoxOrient: 'vertical', 
                      width: '191px' 
                  }}>
                      {item.title}
                  </p>
                  <p className='Regular' style={{ fontSize: '12px', color: '#4a64f5', marginTop: '9.2px' }}>상품분류</p>
                  <p className='Medium' style={{ fontSize: '16px', marginTop: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '191px' }}>
  {item.classificationCode.split('|').map(code => `${code}류`).join(', ')}
</p>
              </div>
          </div>
      );
  })
)
}
       

      </div>
      {hasSearched && (
  <div className="pagination Bold" style={{ textAlign: 'center',  marginBottom: '25px', fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <img
      src={L}
      onClick={() => currentPage > 1 && handlePageClick(currentPage - 1)}
      style={{ width: '16px', height: '16px', margin: '0 10px', cursor: currentPage === 1 ? 'default' : 'pointer', filter: currentPage === 1 ? 'grayscale(100%)' : 'none', verticalAlign: 'middle' }}
      alt="Previous"
    />
    {renderPagination()}
    <img
      src={R}
      onClick={() => currentPage < pageCount && handlePageClick(currentPage + 1)}
      style={{ width: '16px', height: '16px', margin: '0 10px', cursor: currentPage === pageCount ? 'default' : 'pointer', filter: currentPage === pageCount ? 'grayscale(100%)' : 'none', verticalAlign: 'middle' }}
      alt="Next"
    />
  </div>
)}



<img 
        src={image1Src} 
        style={{ width: '335px', marginLeft: 'calc(50% - 167.5px)'}} 
        onClick={() => setImage1Src(toggleImage(image1Src, mo_q1_1, mo_q1_2))}
        alt="Image 1"
      />
      <img 
        src={image2Src} 
        style={{ width: '335px', marginLeft: 'calc(50% - 167.5px)', marginTop: '10px' }} 
        onClick={() => setImage2Src(toggleImage(image2Src, mo_q2_1, mo_q2_2))}
        alt="Image 2"
      />
      <img 
        src={image3Src} 
        style={{ width: '335px', marginLeft: 'calc(50% - 167.5px)', marginTop: '10px' }} 
        onClick={() => setImage3Src(toggleImage(image3Src, mo_q3_1, mo_q3_2))}
        alt="Image 3"
      />
       <div style={{height:'100px'}}>

       </div>

       {
        customeremail1 === null
        ?<Link to="/testlogin">
            <div id="next_button" className="noto"
                     style={{cursor:'pointer', position: 'fixed',bottom:'0px',display: 'flex', alignItems: 'center',justifyContent: 'center', backgroundColor: '#4a64f5'}}>
                    <div id="dam" style={{color:'#ffffff'}}> 바로 상표출원 하러가기</div>
                </div>
                             </Link>
        :<Link to="/test2" id="brandtag">
            <div id="next_button" className="noto"
                     style={{cursor:'pointer', position: 'fixed',bottom:'0px',display: 'flex', alignItems: 'center',justifyContent: 'center', backgroundColor: '#4a64f5'}}>
                    <div id="dam" style={{color:'#ffffff'}}> 바로 상표출원 하러가기</div>
                </div>
           
                             </Link>
        }
            
        </div>
    </MobileView>
    <BrowserView>
    <div style={{margin:'0px auto',marginBottom:'200px'}}>
            <BrowserHeader/>
            <div style={{minWidth:'1400px'}}>
       <img src={ask1} style={{marginTop : '123px',marginLeft : 'calc(50% - 602px)',width:'791px'}}/>
       <div className='askBox' style={{width:'900px',marginLeft:'calc(50% - 491px)'}}>

       <div style={{border: '1px solid #e0e0e0' ,width:'980px',height:'98px',marginTop:'50px',borderRadius:'137px',boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.16)'}}>

       <div id="textcon" style={{width: 'calc(85% - 40px)', paddingLeft: '3px',marginTop:'10px', borderBottom: '2px solid #b7b7b7',marginLeft:'7%',height:'58px'}}>

                   
                   
                   <input className='brandregister-input1 Regular' onChange={(e) => setBrandName(e.target.value)} onKeyUp={onKeyUP} onFocus={onKeyUP2} onBlur={onKeyUP} type="text" id="text1" value={brandName} placeholder="등록하실 상표명을 입력해주세요" style={{border: 'none', color: 'transparent',marginTop:'24px',fontSize:'22px',backgroundColor:'rgba(0, 0, 0, 0); ',opacity:'1'}}/>
                   
                   <img onClick={onReset}src={x_round} className="x_btn"  style={{cursor : 'pointer',width: '30px', height: '30px', float:'right', margin: '22px 3px 0px 0px'}}/>
                   
                   
                   
                   </div>
                   <img onClick={handleSearch2}src={search_PC} className="x_btn"  style={{cursor : 'pointer',width: '56px', height: '56px', float:'right',marginRight:'38px',marginTop:'-50px'}}/>
       </div>
       
       </div>
       </div>
       <div style={{ display: 'flex', flexWrap: 'wrap',width:'1200px',marginLeft:'calc(50% - 600px)',marginTop:'25px'}}>
       {isLoading ? (
    <div
        id="loadingDiv"
        style={{ margin: 'auto', maxWidth: '520px', fontFamily: "'Noto Sans KR', sans-serif" }}
    >
        <img
            src={load_mo}
            style={{ width: '132px', marginLeft: 'calc(50% - 66px)' }}
        />
        <div>
            <img
                src={loadingdot}
                style={{ width: '50px', marginTop: '16px', marginLeft: 'calc(50% - 25px)' }}
            />
        </div>
    </div>
) : noData ? (
    <div className='Bold' style={{ textAlign: 'center', margin :'0 auto', fontSize: '16px',paddingTop:'45px',lineHeight:'1.7' }}>
        <a style={{fontSize:'30px'}}>유사 / 동일 상표명이 검색되지 않습니다 :)<br/><a style={{color : '#4a64f5'}}>지금 바로 등록</a>해서 
        <a style={{color : '#4a64f5'}}> 상표를 선점</a>하세요!</a>
    </div>
) : (
  data1.map(item => {
      const isLongTitle = item.title.length > 35;
      
      // 스타일 및 텍스트 조건 설정
      let statusStyle = { backgroundColor: '#e0e0e0', fontSize: '12px', color: '#515151', textAlign: 'center', padding: '4px' };
      let statusText = item.applicationStatus;

      if (item.applicationStatus === '등록') {
          statusStyle = { backgroundColor: '#4a64f5', fontSize: '12px', color: '#fff', textAlign: 'center', padding: '4px' };
          statusText = '등록 완료';
      } else if (item.applicationStatus === '출원') {
          statusStyle = { backgroundColor: '#edeffe', fontSize: '12px', color: '#515151', textAlign: 'center', padding: '4px' };
          statusText = '출원 진행중';
      } else if (item.applicationStatus === '공고') {
          statusStyle = { backgroundColor: '#8496fc', fontSize: '12px', color: '#fff', textAlign: 'center', padding: '4px' };
          statusText = '출원 공고중';
      }

      return (
          <div key={item.applicationNumber} style={{ boxShadow: '1px 1px 8px 0 rgba(0, 0, 0, 0.14)', backgroundColor: '#fafafa', borderRadius: '10px', margin: '10px', padding: '10px', width: '560px', height: '120px', display: 'flex', alignItems: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', border: '0.5px solid #515151', height: '102.7px',marginLeft:'12px' }}>
                  <img src={item.drawing} alt={item.title} style={{ width: '100px', height: '80px' }} />
                  <p className='Regular' style={statusStyle}>{statusText}</p>
              </div>
              <div style={{ marginLeft: '20px', marginTop: isLongTitle ? '0' : '0' }}>
                  <p className='Regular' style={{ fontSize: '12px', color: '#4a64f5' }}>상표명</p>
                  <p className='Medium' style={{ 
                      fontSize: '16px', 
                      marginTop: '5px', 
                      overflow: 'hidden', 
                      textOverflow: 'ellipsis', 
                      display: '-webkit-box', 
                      WebkitLineClamp: 2, 
                      WebkitBoxOrient: 'vertical', 
                      width: '413px' 
                  }}>
                      {item.title}
                  </p>
                  <p className='Regular' style={{ fontSize: '12px', color: '#4a64f5', marginTop: '9.2px' }}>상품분류</p>
                  <p className='Medium' style={{ fontSize: '16px', marginTop: '5px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '191px' }}>
  {item.classificationCode.split('|').map(code => `${code}류`).join(', ')}
</p>
              </div>
          </div>
      );
  })
)}
       

      </div>
      {hasSearched && (
  <div className="pagination Bold" style={{ textAlign: 'center', marginTop: '40px', fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <img
      src={L}
      onClick={() => currentPage > 1 && handlePageClick(currentPage - 1)}
      style={{ width: '16px', height: '16px', margin: '0 10px', cursor: currentPage === 1 ? 'default' : 'pointer', filter: currentPage === 1 ? 'grayscale(100%)' : 'none', verticalAlign: 'middle' }}
      alt="Previous"
    />
    {renderPagination()}
    <img
      src={R}
      onClick={() => currentPage < pageCount && handlePageClick(currentPage + 1)}
      style={{ width: '16px', height: '16px', margin: '0 10px', cursor: currentPage === pageCount ? 'default' : 'pointer', filter: currentPage === pageCount ? 'grayscale(100%)' : 'none', verticalAlign: 'middle' }}
      alt="Next"
    />
  </div>
)}
<div style={{paddingLeft : 'calc(50% - 600px)'}}>
<div style={{width: '1200px',borderRadius: '10px',borderTop: 'solid 1px #e0e0e0',borderLeft: 'solid 1px #e0e0e0',borderRight: 'solid 1px #e0e0e0',boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.14)',marginTop : '40px', lineHeight : '1.2'}}>


                            <div id="25" style={{paddingLeft : '5%',paddingRight : '5%',  paddingTop : '30px',borderRadius : '10px'}}>
                                <div id="25k" style={{cursor:'pointer'}} onClick={() => onclickClass11(25)}>
                            <a id="25a" class="Medium" style={{color : '#515151',fontSize : '16px'}}>유사 / 동일 상표명이 있어도 등록이 가능한가요?</a>
                                <img id="imgdiv25" src={mypagearrow} style={{float: 'right', display: 'inline-block', marginTop: '11px', width: '13px'}}/></div>
                                <div style={{width: '111%', height: '1px', marginLeft: '-5.5%', backgroundColor: 'rgb(224, 224, 224)', marginTop: '27px'}}></div>
                                <div id="div25" class="backGR"

                                     style={{backgroundColor: 'rgb(250, 250, 250)', width: '111%', marginLeft: '-5.5%', display: 'none', borderBottom : 'solid 1px #e0e0e0'}}>
                                    <div style={{paddingTop : '20px', paddingLeft : '5%',paddingRight : '20px',paddingBottom : '20px',lineHeight:'1.7'}}>
                                        <a class="Medium" style={{fontSize : '15px', color :' #898989'}}>비슷한 상표가 있다고 나오더라도 분야가 겹치지 않는 등, 상세한 조건에 따라 등록이 가능하므로 일단 신청해주시면 전문 변리사의 검토를 거쳐 등록 가능성을
                                          <br/>별도로 안내 해드리고 있어요.</a>

                                    </div>
                                </div>
                            </div>
                            
                        <div id="45" style={{paddingLeft : '5%',paddingRight : '5%',  paddingTop : '30px',borderRadius : '10px'}}>
                            <div id="45k" style={{cursor:'pointer'}} onClick={() => onclickClass11(45)}>
                        <a id="45a" class="Medium" style={{color : '#515151',fontSize : '16px'}}>심사 상태(등록 완료 등)에 대해 자세히 알려주세요</a>
                            <img id="imgdiv45" src={mypagearrow} style={{float: 'right', display: 'inline-block', marginTop: '11px', width: '13px'}}/></div>
                            <div style={{width: '111%', height: '1px', marginLeft: '-5.5%', backgroundColor: 'rgb(224, 224, 224)', marginTop: '27px'}}></div>
                            <div id="div45" class="backGR"

                                 style={{backgroundColor: 'rgb(250, 250, 250)', width: '111%', marginLeft: '-5.5%', display: 'none', borderBottom : 'solid 1px #e0e0e0'}}>
                                <div style={{paddingTop : '20px', paddingLeft : '5%',paddingRight : '20px',paddingBottom : '20px',lineHeight:'1.7'}}>
                                <a className="Medium" style={{ fontSize: '15px', color: '#898989' }}>
    • 출원 진행중 : 상표를 등록받기 위해 특허청에 관련 서류를 제출한 상태. (심사 이전, 혹은 심사 진행 중인 상태)<br />
    • 출원 공고중 : 출원 후 특허청 심사가 종료된 상태. (공고 기간에 타인의 이의신청이 없으면 등록)<br />
    • 등록 완료 : 심사관이 심사한 결과 등록요건에 적합하며, 공고후 2개월간 이의신청이 없어 등록 완료된 상태.<br />
    • 취하 : 출원한 상표가 등록되기전 여러 사유로 인하여 출원이 취소된 상태.<br />
    • 소멸 : 상표등록 후 존속기간이 만료되어 권리가 소멸된 상태.<br />
    • 포기 : 출원인의 포기서 제출, 등록료 불납 등으로 등록결정이나 권리를 포기한 상태.<br />
    • 무효 : 출원 또는 등록된 상태에서 특정 사유로 인해 그 권리나 행위가 무효화 된 상태.<br />
    • 거절 : 출원 후 상표 심사과정에서 실체적인 상표 등록요건을 만족하지 못해 심사관의 거절을 받은 상태.
  </a>

                                </div>
                            </div>
                        </div>
                        <div id="55" style={{paddingLeft : '5%',paddingRight : '5%',  paddingTop : '30px',borderRadius : '10px'}}>
                            <div id="55k" style={{cursor:'pointer'}} onClick={() => onclickClass11(55)}>
                        <a id="55a" class="Medium" style={{color : '#515151',fontSize : '16px'}}>상품 분류의 상세한 내용이 궁금해요</a>
                            <img id="imgdiv55" src={mypagearrow} style={{float: 'right', display: 'inline-block', marginTop: '11px', width: '13px'}}/></div>
                            <div style={{width: '111%', height: '1px', marginLeft: '-5.5%', backgroundColor: 'rgb(224, 224, 224)', marginTop: '27px'}}></div>
                            <div id="div55" class="backGR"

                                 style={{backgroundColor: 'rgb(250, 250, 250)', width: '111%', marginLeft: '-5.5%', display: 'none', borderBottom : 'solid 1px #e0e0e0'}}>
                                <div style={{paddingTop : '20px', paddingLeft : '5%',paddingRight : '20px',paddingBottom : '20px',lineHeight:'1.4'}}>
                                    <a class="Medium" style={{fontSize : '15px', color :' #898989'}}>상품 분류 01류~45류의 상세한 내용은 아래의 내용을 참고해주세요 :)
</a>
<img src={PC_categorization} style={{height : '600px',marginTop:'22px'}}/>

                                </div>
                            </div>
                        </div>

                            </div>
                            </div>
       <div style={{height:'50px'}}>

       </div>
       <div id="introFooter" style={{float:'right',backgroundColor: '#f5f6ff',height:'116px',lineHeight:'110px'}}>
        <div style={{width:'982px',marginLeft:'calc(50% - 491px)'}}>
        



       {
        customeremail1 === null
        ?<Link to="/testlogin">
           <img src={nextbtn} style={{cursor:'pointer',height : '61px',marginLeft:'20px',marginTop:'27px',float:'right'}}/>
                             </Link>
        :<Link to="/test2" id="brandtag">
            <img src={nextbtn} style={{cursor:'pointer',height : '61px',marginLeft:'20px',marginTop:'27px',float:'right'}}/>
           
                             </Link>
        }
       <a href='http://pf.kakao.com/_lWuhs/chat'>
                <img src={kakaoaa} onClick="" style={{cursor:'pointer',width : '283px ',marginTop:'27px',float:'right'}}/>
                </a>
        </div>
       
                </div>
     </div>
    </BrowserView>
        </div>
    )

}

export default BrandSearch;

$('#textcon').ready(function () {

    $('#text1').focus(function () {
        $('#textcon').css("border-bottom", "solid 2px #4a64f5");
        $('#text1').css("color", "#000000");
        $('#text1').css("opacity", "1");
          $('html,body').animate({
        scrollTop: 0
   });
    });
    $('#text1').blur(function () {
        $('#inputdiv1').css("border-bottom", "solid 2px #b7b7b7");
        $('#text1').css("color", "#000000");
        $('#text1').css("opacity", "1");
    });
        $('#text1').blur(function () {
            var flag = true;
            flag = $(this).val().length > 0 ? false : true;
            if (flag) {
                $('#next_button').css('background-color', '#4a64f5');
                $('#dam').css('color', '#ffffff');
                $('#textcon').css("border-bottom", "solid 2px #b7b7b7");
                $('#text1').css("opacity", "1");
            } else {

                $('#next_button').css('background-color', '#4a64f5');
                $('#dam').css('color', '#ffffff');
                $('#text1').css("opacity", "1");
            }
        });


    });

    function onclickClass11(i){
      let  div = document.getElementById(i);
      let divk = document.getElementById(i+"k");
      let div2 = document.getElementById("div" +i);
      if (div2.style.display == ""){
      div2.style.display = "none";
      }else{
      div2.style.display = "";
      }

       }