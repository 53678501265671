import React ,{Component} from "react";
import { useState } from "react";
import Modal from './Modal';
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams} from 'react-router-dom';
import '../text.css'







export default function AlertHeaderPc3(){


    return(
        <>
        <div className="Light" style={{ top : "0px",
            Width: "100%",
            backgroundColor : "#fafafa",
            zIndex :"10010",
            

            margin: "auto",marginTop:'10px',paddingTop:'40px',paddingBottom:'40px',fontSize:'18px',textAlign:'center',minWidth:'1400px',width:'100%'}}>
            <a className="Medium">로고가 없으면 <a style={{color:'#4a64f5'}}>'문자'</a>로, 로고가 있으면 <a style={{color:'#4a64f5'}}>'로고'</a>로 상표 출원을 진행합니다. 문자 로고를 등록해도 권리범위 등에서 <a style={{color:'#4a64f5'}}>손해보는 부분은 전혀 없습니다.</a></a>
            
        </div>
        </>

    );
}