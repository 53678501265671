import React from "react";
import menu from "../image/menu@3x.png";
import '../text.css'
import {useState, useRef} from 'react';
import x_btn from '../image/x_menuicon@3x.png';
import BrowserHeader from '../components/BrowserHeader';
import backarrow from '../data/backarrow@3x.png';
import arrow from "../image/arrow@3x.png";
import kakaopng from "../image/kakaopng.png";
import mailpng from "../image/mailpng.png";
import customer_service_email from '../image/customer_service_email@3x.png';
import customer_service_kakao from '../image/customer_service_kakao@3x.png';
import mypagearrow from '../image/mypagearrow.png';
import customer_service_tel from '../image/telimg.png';
import x_round from '../image/x_round.png';
import sea from '../image/sea.png';
import pass_arrow from '../image/pass_arrow.png';
import nametecLogo from "../image/nametecLogo.png";
import callpng from "../image/callpng.png";
import q1_up from '../image/q1_up.png';
import q2_up from '../image/q2_up.png';
import q3_up from '../image/q3_up.png';
import q1 from '../image/q1.png';
import q2 from '../image/q2.png';
import q3 from '../image/q3.png';
import q4 from '../image/q4.png';
import q4_up from '../image/q4_up.png';
import new_blue from '../image/zx2.png'
import kakaoaa from '../image/kakaoaa.png'
import { BrowserRouter, Route , Switch as Routes , Link , NavLink, useParams} from 'react-router-dom';
import{
BrowserView,
MobileView,
isBrowser,
IsMobile,
TabletView
} from "react-device-detect";


const CenterItems = ({question,question2 ,answer}) => { 
    const [click, setClick] = useState(false)

    const clickHandler = () => { 
        setClick(!click);
    }

    return ( 
    <div onClick={clickHandler}>
        <div style={{position: 'relative', zIndex: '-999'}}>
        <img src={click ? question2 : question} style={{width: '90%', marginTop: '10px', marginLeft: '5%', marginBottom: '10px', cursor : 'pointer', zIndex: '-999'}}/>
        <div style={{position: 'absolute', top:'35%', right: '10%',float: 'right', zIndex: '999'}}>
        
        </div>
        </div>
        
        <div style={{backgroundColor:'#fafafa', minHeight: '110px', width: '90%', marginLeft: '5%', display: click ? 'block' : 'none'}}>
            <div style={{marginTop : '-15px', fontSize : '14px', color : '#898989', padding : '22px'}}>출원은 '권리화' 하기 위해 특허청에 신청 서류를 제출하는 것으로 이해하시면 쉽습니다.<br/>
출원과 등록 사이에 특허청에서 신청 서류를 접수하고 권리로 인정해 줘도 괜찮을지 판단하는 심사 단계를 거칩니다.<br/>
등록은 특허청에서 심사 단계를 통과하고 최종적으로 권리를 확보한 상태를 의미합니다.</div>
        </div>
</div>

    )

}

const CenterItems3 = ({question,question2 ,answer}) => { 
    const [click, setClick] = useState(false)

    const clickHandler = () => { 
        setClick(!click);
    }

    return ( 
    <div onClick={clickHandler}>
        <div style={{position: 'relative', zIndex: '-999'}}>
        <img src={click ? question2 : question} style={{width: '90%', marginTop: '10px', marginLeft: '5%', marginBottom: '10px', cursor : 'pointer', zIndex: '-999'}}/>
        <div style={{position: 'absolute', top:'35%', right: '10%',float: 'right', zIndex: '999'}}>
        
        </div>
        </div>
        
        <div style={{backgroundColor:'#fafafa', minHeight: '110px', width: '90%', marginLeft: '5%', display: click ? 'block' : 'none'}}>
            <div style={{marginTop : '-15px', fontSize : '14px', color : '#898989', padding : '22px'}}>· 일반 심사 시(기본 비용)<br/>
            <br/>
→ 네임텍 수수료 110,000원<br/>
→ 특허청 관납료 52,000원<br/>
▶ 총 162,000원 납부 <br/><br/>

 · 우선 심사 신청 시(기본 비용에 추가금)<br/>
→ 네임텍 수수료 추가 200,000원<br/>
→ 특허청 관납료 추가 160,000원<br/>
▶ 총 522,000원 납부(기본 비용 포함)<br/><br/>

 · 상표 등록 완료 시<br/>
→ 네임텍 수수료 없음<br/>
→ 특허청 관납료 210,120원(10년 등록 기준)<br/>
▶ 총 210,120원 납부<br/><br/>

*일반 심사 시, 기본 비용과 등록 완료 시 특허청에 내는 관납료 까지만 발생하고 추가 수수료는 없습니다.<br/><br/>

상표 등록 완료 후, 특허청에 내는 관납료를 반드시 납부해야만 최종적으로 상표등록증이 나옵니다.</div>
        </div>
</div>

    )

}


const CenterItems4 = ({question,question2 ,answer}) => { 
    const [click, setClick] = useState(false)

    const clickHandler = () => { 
        setClick(!click);
    }

    return ( 
    <div onClick={clickHandler}>
        <div style={{position: 'relative', zIndex: '-999'}}>
        <img src={click ? question2 : question} style={{width: '90%', marginTop: '10px', marginLeft: '5%', marginBottom: '10px', cursor : 'pointer', zIndex: '-999'}}/>
        <div style={{position: 'absolute', top:'35%', right: '10%',float: 'right', zIndex: '999'}}>
        
        </div>
        </div>
        
        <div style={{backgroundColor:'#fafafa', minHeight: '110px', width: '90%', marginLeft: '5%', display: click ? 'block' : 'none'}}>
            <div style={{marginTop : '-15px', fontSize : '14px', color : '#898989', padding : '22px'}}>우선심사를 신청하는 경우, 10개월 내지 12개월 걸리는 1차 심사를 1~3개월로 단축 할 수 있습니다.<br/>
             심사를 통과하는 경우 2개월의 출원 공고 기간을 거쳐 등록 할 수 있습니다. 단, 추가비용이 발생하는 점을 감안하셔야 합니다.</div>
        </div>
</div>

    )

}
const CenterItems2 = ({question,question2 ,answer}) => { 
    const [click, setClick] = useState(false)

    const clickHandler = () => { 
        setClick(!click);
    }

    return ( 
    <div onClick={clickHandler}>
        <div style={{position: 'relative', zIndex: '-999'}}>
        <img src={click ? question2 : question} style={{width: '90%', marginTop: '10px', marginLeft: '5%', marginBottom: '10px', cursor : 'pointer', zIndex: '-999'}}/>
        <div style={{position: 'absolute', top:'35%', right: '10%',float: 'right', zIndex: '999'}}>
        
        </div>
        </div>
        
        <div style={{backgroundColor:'#fafafa', minHeight: '110px', width: '90%', marginLeft: '5%', display: click ? 'block' : 'none'}}>
            <div style={{marginTop : '-15px', fontSize : '14px', color : '#898989', padding : '22px'}}>상표 출원 후 특허청에서 약 15개월 정도 심사를 진행합니다.<br/>
빠른 상표출원 옵션을 선택하게 될 경우, 약 2~3개월 수준으로 단축됩니다.</div>
        </div>
</div>

    )

}

const Center = () => {
    const [q1state , setQ1state] = useState(q1) 
    const [display, setDisplay] = useState('none');
    const [color, setColor] = useState('#e0e0e0')
    const input = useRef(null)

    const colorHandler = () => { 
        setColor('rgb(74 100 245)')
    }


    const modalHandler = () => {
        if (display === 'none') {
            setDisplay('inline-block');
        } else {
            setDisplay('none');
        }
    };

    const eraseInput = () => { 
        input.current.value = '';
    }


    return (
    
        // 마이페이지 헤더
        <div>
            <TabletView>
            <div style={{ margin: 'auto', maxWidth: '520px', marginBottom: '50px' }}>

{/* topbar*/}
<div style={{
    top: "0px",
    Width: "100%",
    backgroundColor: "#FFFFFF",
    zIndex: "100",
    maxWidth: "520px",
    margin: "auto",
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: "1px solid #e0e0e0",
    height: "55px"
}}>

    <Link to='/'>
        <img className="topbar-backbutton" src={backarrow}
            style={{
                objectFit: 'contain',
                width: '14px',
                height: '23px',
                margin: '16px 0 0 20px'
            }}
            alt='<' />
    </Link>

    <Link to='/center'>
        <div className='Bold' style={{ display: 'inline-block', marginTop: '12px', fontSize: '20px', color: "black" }}>고객센터</div>
    </Link>

    <img src={menu} style={{
        cursor: "pointer",
        display: 'inline-block',
        float: "right",
        width: '22px',
        height: '17px',
        marginTop: "20px",
        marginRight: "20px"
    }}
        onClick={modalHandler} />
</div>

{/* modal */}
<div id="menuContainer"
    style={{
        display: display,
        position: 'fixed',
        top: '0px',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        fontFamily: 'Bold',
        fontSize: '24px',
        maxWidth: '520px',
        margin: 'auto',
        zIndex: '20000'
    }}>


    <div style={{ backgroundColor: '#ffffff', width: '100%' }}>

                                    <div className="menuicon1">
                                    <img src={nametecLogo} class="" style={{display: "inline-block",
                                                                                                                width: "105px",
                                                                                                                transform: "translate(28px)",
                                                                                                                marginTop: "12px"}}/>
                                        <img src={x_btn} id="xbtn" onClick={modalHandler} style={{ cursor: 'pointer', width: '18px', float: 'right', marginRight: '20px', marginTop: '20px' }} />
                                    </div>
                                    <div style={{backgroundColor:'#4a64f5', float:"right",width:"60%",height :"20000px"}}>
                                    <div className="menuicon1" style={{height : '50px'}}>
                                                <Link to='/'>
                                                <span style={{cursor: 'pointer', marginRight: '20px', marginTop: '40px',float : "right", display:'inline-block', color: "#fff"}}>메인화면</span>
                                                </Link>
                                                </div>


                                                    <Link style={{display:'block',marginTop :"40px",marginRight: '20px'}} to='/costprocess'>
                                                    <span style={{cursor: 'pointer', color: '#fff', display: 'inline-block',float : "right"}}>비용 및 절차 안내</span>
                                                    </Link>
                                                        <Link style={{display:'block',marginTop :"90px",marginRight: '20px'}} to='/center'>
                                                     <span style={{cursor: 'pointer',color :"#fff",display: 'inline-block',float : "right"}}> 고객센터</span>
                                                     </Link>


                                                    <Link style={{display:'block',marginTop :"140px",marginRight: '20px'}} to='/'>
                                                     <span style={{cursor: 'pointer', color: '#fff', display: 'inline-block',float:"right"}}>로그아웃</span>
                                                     </Link>

                                    <div className="Medium" style={{marginLeft :"30px",fontSize:"15px",marginTop: "100px",marginRight: '20px',float:"right",color :"#ffffff"}}>Copyright©2021 네임텍</div>


                                                <div style={{height: '33px'}}></div>
                                </div>
                                </div>
                            </div>

{/* center */}

<div className="test" style={{maxWidth : '520px', margin:'auto'}}>



<div style={{backgroundColor: '#4a64f5', height: '184px', paddingTop: '30px'}}>
<div style={{textAlign:'center', fontSize:'18px', color: '#ffffff', marginTop: '29px'}}>도움이 필요하신가요?</div>
<div style={{textAlign:'center', fontSize:'12px', color:'#ffffff', marginTop: '6px'}}>평일 09:00 - 18:00</div>

<div style={{textAlign: 'center', marginTop: '26px'}}>

<div style={{display: 'inline-block'}}><a href={{mailto: 'admin@nametec.kr'}}>
<img src={customer_service_email} style={{width: '35px', height: 'auto'}}/></a></div>

<div style={{display: 'inline-block', marginLeft: '71px', marginRight: '71px'}}><a href="#">
<img src={customer_service_kakao} style={{width: '45px', height: 'auto'}}/></a></div>

<div style={{display: 'inline-block'}}><a href="tel:02-922-5710">
<img style={{width:"24px",height :"51px"}}src={customer_service_tel}/></a></div>
</div>
</div>




<div className='Medium' style={{ fontSize: '18px', color: '#000000', marginLeft: '5%', marginBottom: '25px',marginTop:'50px' }}> 자주 묻는 질문 / FAQ</div>
<div style={{ fontSize: '16px', color: '#898989' }}>

<CenterItems question={q1} question2={q1_up}  answer="출원은 '권리화' 하기 위해 특허청에 신청 서류를 제출하는 것으로 이해하시면 쉽습니다.
출원과 등록 사이에 특허청에서 신청 서류를 접수하고 권리로 인정해 줘도 괜찮을지 판단하는 심사 단계를 거칩니다.
등록은 특허청에서 심사 단계를 통과하고 최종적으로 권리를 확보한 상태를 의미합니다." />
<CenterItems question={q2} question2={q2_up}
answer='상표 출원 후 특허청에서 약 15개월 정도 심사를 진행합니다.<br/>
빠른 상표출원 옵션을 선택하게 될 경우, 약 2~3개월 수준으로 단축됩니다.'/>
<CenterItems question={q3} question2={q3_up} answer='우선심사를 신청하는 경우, 10개월 내지 12개월 걸리는 1차 심사를 1~3개월로 단축 할 수 있습니다. 심사
를 통과하는 경우 2개월의 출원 공고 기간을 거쳐 등록 할 수 있습니다. 단, 추가비용이 발생하는 점을 감안하셔야 합니다.' />
<CenterItems question={q4} question2={q4_up} answer='우선심사를 신청하는 경우, 10개월 내지 12개월 걸리는 1차 심사를 1~3개월로 단축 할 수 있습니다. 심사
를 통과하는 경우 2개월의 출원 공고 기간을 거쳐 등록 할 수 있습니다. 단, 추가비용이 발생하는 점을 감안하셔야 합니다.' />


</div>
</div>

</div>
            </TabletView>
        <MobileView>
            <div style={{ margin: 'auto', maxWidth: '520px', marginBottom: '50px' }}>

                {/* topbar*/}
                <div style={{
                    top: "0px",
                    Width: "100%",
                    backgroundColor: "#FFFFFF",
                    zIndex: "100",
                    maxWidth: "520px",
                    margin: "auto",
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: "1px solid #e0e0e0",
                    height: "55px"
                }}>

                    <Link to='/'>
                        <img className="topbar-backbutton" src={backarrow}
                            style={{
                                objectFit: 'contain',
                                width: '14px',
                                height: '23px',
                                margin: '16px 0 0 20px'
                            }}
                            alt='<' />
                    </Link>

                    <Link to='/center'>
                        <div className='Bold' style={{ display: 'inline-block', marginTop: '12px', fontSize: '20px', color: "black" }}>고객센터</div>
                    </Link>

                    <img src={menu} style={{
                        cursor: "pointer",
                        display: 'inline-block',
                        float: "right",
                        width: '22px',
                        height: '17px',
                        marginTop: "20px",
                        marginRight: "20px"
                    }}
                        onClick={modalHandler} />
                </div>

                {/* modal */}
                <div id="menuContainer"
                    style={{
                        display: display,
                        position: 'fixed',
                        top: '0px',
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        fontFamily: 'Bold',
                        fontSize: '24px',
                        maxWidth: '520px',
                        margin: 'auto',
                        zIndex: '20000'
                    }}>


                    <div style={{ backgroundColor: '#ffffff', width: '100%' }}>

                                                    <div className="menuicon1">
                                                    <img src={nametecLogo} class="" style={{display: "inline-block",
                                                                                                                                width: "105px",
                                                                                                                                transform: "translate(28px)",
                                                                                                                                marginTop: "12px"}}/>
                                                        <img src={x_btn} id="xbtn" onClick={modalHandler} style={{ cursor: 'pointer', width: '18px', float: 'right', marginRight: '20px', marginTop: '20px' }} />
                                                    </div>
                                                    <div style={{backgroundColor:'#4a64f5', float:"right",width:"60%",height :"20000px"}}>
                                                    <div className="menuicon1" style={{height : '50px'}}>
                                                                <Link to='/'>
                                                                <span style={{cursor: 'pointer', marginRight: '20px', marginTop: '40px',float : "right", display:'inline-block', color: "#fff"}}>메인화면</span>
                                                                </Link>
                                                                </div>


                                                                    <Link style={{display:'block',marginTop :"40px",marginRight: '20px'}} to='/costprocess'>
                                                                    <span style={{cursor: 'pointer', color: '#fff', display: 'inline-block',float : "right"}}>비용 및 절차 안내</span>
                                                                    </Link>
                                                                        <Link style={{display:'block',marginTop :"90px",marginRight: '20px'}} to='/center'>
                                                                     <span style={{cursor: 'pointer',color :"#fff",display: 'inline-block',float : "right"}}> 고객센터</span>
                                                                     </Link>


                                                                    <Link style={{display:'block',marginTop :"140px",marginRight: '20px'}} to='/'>
                                                                     <span style={{cursor: 'pointer', color: '#fff', display: 'inline-block',float:"right"}}>로그아웃</span>
                                                                     </Link>

                                                    <div className="Medium" style={{marginLeft :"30px",fontSize:"15px",marginTop: "100px",marginRight: '20px',float:"right",color :"#ffffff"}}>Copyright©2021 네임텍</div>


                                                                <div style={{height: '33px'}}></div>
                                                </div>
                                                </div>
                                            </div>

                {/* center */}

<div className="test" style={{maxWidth : '520px', margin:'auto'}}>



    <div style={{backgroundColor: '#4a64f5', height: '184px', paddingTop: '30px'}}>
    <div style={{textAlign:'center', fontSize:'18px', color: '#ffffff', marginTop: '29px'}}>도움이 필요하신가요?</div>
    <div style={{textAlign:'center', fontSize:'12px', color:'#ffffff', marginTop: '6px'}}>평일 09:00 - 18:00</div>

    <div style={{textAlign: 'center', marginTop: '26px'}}>

        <div style={{display: 'inline-block'}}><a href={{mailto: 'admin@nametec.kr'}}>
            <img src={customer_service_email} style={{width: '35px', height: 'auto'}}/></a></div>

        <div style={{display: 'inline-block', marginLeft: '71px', marginRight: '71px'}}><a href="#">
            <img src={customer_service_kakao} style={{width: '45px', height: 'auto'}}/></a></div>

        <div style={{display: 'inline-block'}}><a href="tel:02-922-5710">
            <img style={{width:"24px",height :"51px"}}src={customer_service_tel}/></a></div>
    </div>
    </div>



            
            <div className='Medium' style={{ fontSize: '18px', color: '#000000', marginLeft: '5%', marginBottom: '25px',marginTop:'50px' }}> 자주 묻는 질문 / FAQ</div>
        <div style={{ fontSize: '16px', color: '#898989' }}>

        <CenterItems question={q1} question2={q1_up}  answer="출원은 '권리화' 하기 위해 특허청에 신청 서류를 제출하는 것으로 이해하시면 쉽습니다.
출원과 등록 사이에 특허청에서 신청 서류를 접수하고 권리로 인정해 줘도 괜찮을지 판단하는 심사 단계를 거칩니다.
등록은 특허청에서 심사 단계를 통과하고 최종적으로 권리를 확보한 상태를 의미합니다." />
<CenterItems2 question={q2} question2={q2_up}
answer='상표 출원 후 특허청에서 약 15개월 정도 심사를 진행합니다.
빠른 상표출원 옵션을 선택하게 될 경우, 약 2~3개월 수준으로 단축됩니다.'/>
<CenterItems3 question={q3} question2={q3_up} answer='· 일반 심사 시(기본 비용)

→ 네임텍 수수료 110,000원
→ 특허청 관납료 52,000원
▶ 총 162,000원 납부 

 · 우선 심사 신청 시(기본 비용에 추가금)
→ 네임텍 수수료 추가 200,000원
→ 특허청 관납료 추가 160,000원
▶ 총 522,000원 납부(기본 비용 포함)

 · 상표 등록 완료 시
→ 네임텍 수수료 없음
→ 특허청 관납료 210,120원(10년 등록 기준)
▶ 총 210,120원 납부

*일반 심사 시, 기본 비용과 등록 완료 시 특허청에 내는 관납료 까지만 발생하고 추가 수수료는 없습니다.

상표 등록 완료 후, 특허청에 내는 관납료를 반드시 납부해야만 최종적으로 상표등록증이 나옵니다.' />
<CenterItems4 question={q4} question2={q4_up} answer='우선심사를 신청하는 경우, 10개월 내지 12개월 걸리는 1차 심사를 1~3개월로 단축 할 수 있습니다. 심사
를 통과하는 경우 2개월의 출원 공고 기간을 거쳐 등록 할 수 있습니다. 단, 추가비용이 발생하는 점을 감안하셔야 합니다.' />


               
            </div>
    </div>

    </div>
    </MobileView>
    <BrowserView>
    <BrowserHeader/>
                    <div style={{minWidth:'1000px'}}>
                    <div className="Bold" style={{fontSize:'40px',textAlign:'center',marginTop:'146px'}}>
                    고객센터
                    </div>
                    <div style={{backgroundColor: '#4a64f5', height: '354px', paddingTop: '30px',marginTop:'24px'}}>
                        <div className="Medium" style={{textAlign:'center', fontSize:'22px', color: '#ffffff', marginTop: '29px'}}>도움이 필요하신가요?</div>
                        <div className="Regular" style={{textAlign:'center', fontSize:'16px', color:'#ffffff', marginTop: '6px'}}>평일 09:00 - 18:00</div>

                        <div style={{ marginTop: '26px',width:'350px',marginLeft:'calc(50% - 175px)'}}>

                                <img src={mailpng} style={{width: '248px',display:'block', height: 'auto',marginLeft:'7px'}}/>

                                <img src={kakaopng} style={{width: '321px', height: 'auto',display:'block',marginTop:'30px'}}/>

                                <img style={{width:"209px",height :"auto",display:'block',marginTop:'25px'}}src={callpng}/>
                        </div>
                        </div>
{/*                         <div className='askBox343' style={{width:'820px',marginTop:'40px',backgroundColor:"#fafafa",height:'100px',borderRadius:'10px',marginLeft:'calc(50% - 410px)',paddingTop:'30px'}}> */}
{/*                                <div id="textcon" style={{width: 'calc(85% - 40px)', paddingLeft: '3px', borderBottom: '2px solid #b7b7b7',marginLeft:'10%'}}> */}
{/*                                            <input className='brandregister-input'  type="text" id="text" placeholder="검색으로 궁금증을 빠르게 해결해보세요" style={{border: 'none', color: 'transparent',textShadow: '0 0 0 black'}}/> */}
{/*                                            <img src={sea} className="x_btn"  style={{cursor : 'pointer',width: '23px', height: '25px', float:'right', margin: '9px 3px 0px 0px'}}/> */}
{/*                                            </div> */}
{/*                                </div> */}
                        <div className="Medium" style={{marginLeft:'calc(50% - 410px)',marginTop:'55px',fontSize:'18px'}}>
                                        자주 묻는 질문
                                        </div>


                        <div style={{width: '820px', marginLeft: '5%',borderRadius: '10px',borderTop: 'solid 1px #e0e0e0',borderLeft: 'solid 1px #e0e0e0',borderRight: 'solid 1px #e0e0e0',boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.14)',marginTop : '40px',marginLeft:'calc(50% - 410px)', lineHeight : '1.2'}}>


                            <div id="25" style={{paddingLeft : '5%',paddingRight : '5%',  paddingTop : '30px',borderRadius : '10px'}}>
                                <div id="25k" style={{cursor:'pointer'}} onClick={() => onclickClass11(25)}>
                            <a id="25a" class="Regular" style={{color : '#515151',fontSize : '18px'}}>출원과 등록의 차이가 뭔가요?</a>
                                <img id="imgdiv25" src={mypagearrow} style={{float: 'right', display: 'inline-block', marginTop: '11px', width: '13px'}}/></div>
                                <div style={{width: '111%', height: '1px', marginLeft: '-5.5%', backgroundColor: 'rgb(224, 224, 224)', marginTop: '27px'}}></div>
                                <div id="div25" class="backGR"

                                     style={{backgroundColor: 'rgb(250, 250, 250)', width: '111%', marginLeft: '-5.5%', display: 'none', borderBottom : 'solid 1px #e0e0e0'}}>
                                    <div style={{paddingTop : '20px', paddingLeft : '5%',paddingRight : '20px',paddingBottom : '20px',lineHeight:'1.4'}}>
                                        <a class="Light" style={{fontSize : '18px', color :' #898989'}}>출원은 '권리화' 하기 위해 특허청에 신청 서류를 제출하는 것으로 이해하시면 쉽습니다.<br/>출원과 등록 사이에 특허청에서 신청 서류를 접수하고 권리로 인정해 줘도 괜찮을지 판단하는 심사 단계를 거칩니다.
                                        <br/>등록은 특허청에서 심사 단계를 통과하고 최종적으로 권리를 확보한 상태를 의미합니다.</a>

                                    </div>
                                </div>
                            </div>
                            <div id="35" style={{paddingLeft : '5%',paddingRight : '5%',  paddingTop : '30px',borderRadius : '10px'}}>
                            <div id="35k" style={{cursor:'pointer'}} onClick={() => onclickClass11(35)}>
                        <a id="35a" class="Regular" style={{color : '#515151',fontSize : '18px'}}>최종 등록까지 시간이 얼마나 걸리나요?</a>
                            <img id="imgdiv35" src={mypagearrow} style={{float: 'right', display: 'inline-block', marginTop: '11px', width: '13px'}}/></div>
                            <div style={{width: '111%', height: '1px', marginLeft: '-5.5%', backgroundColor: 'rgb(224, 224, 224)', marginTop: '27px'}}></div>
                            <div id="div35" class="backGR"

                                 style={{backgroundColor: 'rgb(250, 250, 250)', width: '111%', marginLeft: '-5.5%', display: 'none', borderBottom : 'solid 1px #e0e0e0'}}>
                                <div style={{paddingTop : '20px', paddingLeft : '5%',paddingRight : '20px',paddingBottom : '20px',lineHeight:'1.4'}}>
                                    <a class="Light" style={{fontSize : '18px', color :' #898989'}}>상표 출원 후 특허청에서 약 15개월 정도 심사를 진행합니다.<br/>빠른 상표출원 옵션을 선택하게 될 경우, 약 2~3개월 수준으로 단축됩니다.</a>

                                </div>
                            </div>
                        </div>
                        <div id="45" style={{paddingLeft : '5%',paddingRight : '5%',  paddingTop : '30px',borderRadius : '10px'}}>
                            <div id="45k" style={{cursor:'pointer'}} onClick={() => onclickClass11(45)}>
                        <a id="45a" class="Regular" style={{color : '#515151',fontSize : '18px'}}>비용이 궁금해요</a>
                            <img id="imgdiv45" src={mypagearrow} style={{float: 'right', display: 'inline-block', marginTop: '11px', width: '13px'}}/></div>
                            <div style={{width: '111%', height: '1px', marginLeft: '-5.5%', backgroundColor: 'rgb(224, 224, 224)', marginTop: '27px'}}></div>
                            <div id="div45" class="backGR"

                                 style={{backgroundColor: 'rgb(250, 250, 250)', width: '111%', marginLeft: '-5.5%', display: 'none', borderBottom : 'solid 1px #e0e0e0'}}>
                                <div style={{paddingTop : '20px', paddingLeft : '5%',paddingRight : '20px',paddingBottom : '20px',lineHeight:'1.4'}}>
                                    <a class="Light" style={{fontSize : '18px', color :' #898989'}}> · 일반 심사 시(기본 비용)<br/>→ 네임텍 수수료 110,000원<br/>→ 특허청 관납료 52,000원<br/>▶ 총 162,000원 납부<br/><br/>
 · 우선 심사 신청 시(기본 비용에 추가금)
 <br/>→ 네임텍 수수료 추가 200,000원
 <br/>→ 특허청 관납료 추가 160,000원
 <br/>▶ 총 522,000원 납부(기본 비용 포함)
 <br/><br/>
 <br/>· 상표 등록 완료 시
 <br/>→ 네임텍 수수료 없음
 <br/>→ 특허청 관납료 210,120원(10년 등록 기준)
 <br/>▶ 총 210,120원 납부
 <br/><br/>*일반 심사 시, 기본 비용과 등록 완료 시 특허청에 내는 관납료 까지만 발생하고 추가 수수료는 없습니다.
 <br/><br/>상표 등록 완료 후, 특허청에 내는 관납료를 반드시 납부해야만 최종적으로 상표등록증이 나옵니다. </a>

                                </div>
                            </div>
                        </div>
                        <div id="55" style={{paddingLeft : '5%',paddingRight : '5%',  paddingTop : '30px',borderRadius : '10px'}}>
                            <div id="55k" style={{cursor:'pointer'}} onClick={() => onclickClass11(55)}>
                        <a id="55a" class="Regular" style={{color : '#515151',fontSize : '18px'}}>상담 가능한 시간이 언제인가요?</a>
                            <img id="imgdiv55" src={mypagearrow} style={{float: 'right', display: 'inline-block', marginTop: '11px', width: '13px'}}/></div>
                            <div style={{width: '111%', height: '1px', marginLeft: '-5.5%', backgroundColor: 'rgb(224, 224, 224)', marginTop: '27px'}}></div>
                            <div id="div55" class="backGR"

                                 style={{backgroundColor: 'rgb(250, 250, 250)', width: '111%', marginLeft: '-5.5%', display: 'none', borderBottom : 'solid 1px #e0e0e0'}}>
                                <div style={{paddingTop : '20px', paddingLeft : '5%',paddingRight : '20px',paddingBottom : '20px',lineHeight:'1.4'}}>
                                    <a class="Light" style={{fontSize : '18px', color :' #898989'}}>평일(월~금) 9:00~18:00 이내에 상담이 가능합니다.<br/>(공휴일 및 주말 휴무)
</a>

                                </div>
                            </div>
                        </div>

                            </div>
                                     <div style={{height:'200px'}}>
                                     </div>
                                     <div style={{height:'200px'}}>
                                                               </div>
                                                               <div id="introFooter" style={{float:'right',backgroundColor: '#f5f6ff',height:'116px'}}>


                                                               <div style={{marginTop:'34px'}}><a className="Bold1" style={{fontSize:'20px',marginLeft:'calc(50% - 470px)'}}>상표출원은 <a style={{color:'#4a64f5'}}>365일, 24시간</a> 아무때나! 카톡 상담은 평일 09:00~18:00</a></div>
                                                               <div style={{marginTop:'5px'}}><a className="Medium1" style={{color:'#898989',fontSize:'16px',marginLeft:'calc(50% - 470px)'}}>*상담 시간 안내 : 점심 시간 12:00~13:00, 공휴일 휴무이니 참고해 주세요 :)</a></div>
                                                              
                                                               <div style={{float:'right',marginTop:'-81px',marginRight:'calc(50% - 468px)'}}>
                                                                   <Link to="/brandregister">
                                                                   <img src={new_blue}  style={{cursor:'pointer',height:'61px',width : '223px',marginLeft:'320px',marginTop:'28px',marginRight:'calc(50% - 685px)'}}/>
                                                                   </Link>
                                                                   <a href='http://pf.kakao.com/_lWuhs/chat'>
                                                                            <img src={kakaoaa}  style={{cursor:'pointer',height:'61px',width : '283px ',marginTop:'27px',float:'right'}}/>
                                                                            </a>
                                                                            </div>

                                                                            </div>
                    </div>
    </BrowserView>
    </div>
   
                
    )};

    export default Center;
        

   function onclickClass11(i){
      let  div = document.getElementById(i);
      let divk = document.getElementById(i+"k");
      let div2 = document.getElementById("div" +i);
      if (div2.style.display == ""){
      div2.style.display = "none";
      }else{
      div2.style.display = "";
      }

       }






